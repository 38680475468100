import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { Link, useParams } from 'react-router-dom';
import logo from './../../img/lento_logo.jpg';
import { api_url } from "../../authorize";

import MaterialTable from 'material-table';
import Input from '@material-ui/core/Input';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    Permissions: forwardRef((props, ref) => <InfoIcon {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48
    },
  });

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

const ManufacturerList = () => {
  const { token, authBody, setAuthenticated, setAuthBody } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const [loading, setLoading] = useState(true)
  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }
  const [data, setData] = useState({})
  let { surgeonid } = useParams()
  const [open, setOpen] = useState(false)
  const handleOpen =() => {
    setOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/internal_user_management?Role=manufacturer`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(res=> {
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
          setLoading(false)
          setState((prevState) => ({
            ...prevState, data: res.data
          }))
        }
      });
    };

    fetchData();
  }, []);
  console.log(state)
  //
  //     const res = await axios({
  //       url: `https://${api_url}/api/case_list?surgeon=${surgeonid}&status=active`,
  //       method: 'get',
  //       headers: {
  //         'x-access-token' : token
  //       }
  //     });
  //     if(role.includes("admin")){
  //       setState((prevState) => ({
  //         ...prevState, data: admin.data
  //       }))
  //     }
  //     else{
  //       setState((prevState) => ({
  //         ...prevState, data: res.data
  //       }))
  //     }
  //   }
  //   fetchData();
  // }, [setState]);

  const [state, setState] = useState({
    columns: [
      { title: 'First Name', field:'First_Name', filtering: false, width: '1%',
          cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'Last Name', field:'Last_Name', filtering: false, width: '1%',
          cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'User Name', field:'login', filtering: false, width: '1%',
        cellStyle:{
          fontSize: '.7em'
        }
      },
      {
       title: 'Password', field: 'password', filtering: false , width: '1%', editable: "onAdd", render:rowData => {
         return (
           <div>
             ******
           </div>
         )
       },
       cellStyle:{
         fontSize: '.7em'
       }
     },
     {
       title: 'Continent', field:'continent_code', filtering: 'false',
       lookup: {
                 "NA": 'NA',
                 "EU": 'EU',
                 "AS": 'AS',
           }
     },
      { title: 'CNC', field:'CNC', filtering: 'false', type: 'boolean',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Final QC', field:'Final_QC', filtering: 'false', type: 'boolean',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Shipping', field:'Shipped', filtering: 'false', type: 'boolean',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Available', field: 'available', type: "boolean"},
    ]
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = e => {
    setState({...state, dialogOpen: false})
  }


  const onChange = (e) => {
    const { name, value } = e.target
    setState({...state,
      [name] : value
    })
  }

  const handleSubmit = () => {
    console.log(state)
    axios({
      url: `https://${api_url}/api/internal_user_management?login=${state.rowData.login}`,
      method: 'put',
      headers: {
        'x-access-token' : token
      },
      data: {
        password: state.password
      }
    }).then(
      res => {
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
          setState({...state, dialogOpen: false})
        }

      }
    );
  }

  const { gilad, jason, antoine } = state;

  const classes = useStyles();
  console.log(state)
  if(loading){
    return(
      <div id="p5_loading" style={{position:"relative", width: '100%', zIndex: '3',
              height: "100%", left: 0, top: 0, backgroundColor: "white"
            }}>
          <img src={logo} alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
          />
      </div>

    )
  }

    return(
      <>
      <div className="w-75 mx-auto mt-5" >
      {
      hasRole(profile, ['super_admin']) ?
      <MaterialTable
        title="Manufacturers"
        columns={state.columns}
        data={state.data}
        icons={tableIcons}
        actions={
          hasRole(profile, ['super_admin']) ?

          [{
          icon: tableIcons.Add,
          tooltip: 'Reset Password',
          onClick: (event, rowData) => {
            setState({...state, dialogOpen: true, rowData})
          }
          }]
          :
          null
        }
        editable={{
          onRowUpdate: (newData, oldData) =>
             new Promise((resolve, reject) => {
               setTimeout(() => {
                 {
                   const data = [...state.data];
                   const index = data.indexOf(oldData);
                   data[index] = newData;
                     axios({
                       url: `https://${api_url}/api/internal_user_management?login=${oldData.login}`,
                       method: 'put',
                       data:
                         {
                           "Login" : newData.login,
                           "Password": newData.password,
                           "Role": "manufacturer",
                           "available": newData.available,
                           "First_Name": newData.First_Name,
                           "Last_Name": newData.Last_Name,
                           "Permissions": {
                             "Final_QC": newData.Final_QC,
                             "CNC": newData.CNC,
                             "Shipped": newData.Shipped,
                           },
                           "continent_code" : newData.continent_code
                         },
                       headers: {
                         'x-access-token' : token
                       }
                     }).then(res=> {
                       if(res.data.message){
                         alert(res.data.message)
                       }
                       else if(res.data.validation){
                         alert(res.data.validation.replace("_", " "))
                       }
                       else if(res.data.notLoggedIn){
                         setAuthenticated(false)
                         setAuthBody({role: 'visitor'})
                         alert("You have been logged out due to inactivity. Please log in again.")
                       }
                       else{
                           setState({ ...state, data });
                       }
                     });

                   resolve()
                 }
               }, 1000);
             }),
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {

                  const data = [...state.data];
                  // data.push(newData)
                  // setState({...state, data})
                  axios({

                    url:`https://${api_url}/api/internal_user_management`,
                    method: 'post',
                    data:
                      {
                        "Login" : newData.login,
                        "Password": newData.password,
                        "Role": "manufacturer",
                        "available": newData.available,
                        "First_Name": newData.First_Name,
                        "Last_Name": newData.Last_Name,
                        "Permissions": {
                          "Final_QC": newData.Final_QC,
                          "CNC": newData.CNC,
                          "Shipped": newData.Shipped,
                        },
                        "continent_code" : newData.continent_code
                      },
                    headers: {
                       'x-access-token' : token
                    }
                  }).then(
                    res => {
                      if(res.data.message){
                        alert(res.data.message)
                      }
                      else if(res.data.validation){
                        alert(res.data.validation.replace("_", " "))
                      }
                      else if(res.data.notLoggedIn){
                        setAuthenticated(false)
                        setAuthBody({role: 'visitor'})
                        alert("You have been logged out due to inactivity. Please log in again.")
                      }
                      else{
                        data.push(newData)
                        setState({...state, data})
                      }
                    }
                  )
                resolve()
              }, 1000)

            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  let data = [...state.data]
                  const index = data.indexOf(oldData);
                  data.splice(index, 1)
                  axios({
                    url: `https://${api_url}/api/internal_user_management?login=${oldData.login}`,
                    method: 'delete',
                    headers: {
                      'x-access-token' : token
                    }
                  }).then(res=> {
                    if(res.data.message){
                      alert(res.data.message)
                    }
                    else if(res.data.validation){
                      alert(res.data.validation.replace("_", " "))
                    }
                    else if(res.data.notLoggedIn){
                      setAuthenticated(false)
                      setAuthBody({role: 'visitor'})
                      alert("You have been logged out due to inactivity. Please log in again.")
                    }
                    else{
                      setState({...state, data})
                    }
                  })
                }
                resolve()
              }, 1000)
            })
        }}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        options={{
          actionsColumnIndex: 9,
          sorting: true,
          headerStyle: {
            fontSize: '.8em'
          }
        }}
        style={{
          fontSize: '20px'
        }}
        />
        :

        hasRole(profile, ['write_admin']) ?
        <MaterialTable
          title="Manufacturers"
          columns={state.columns}
          data={state.data}
          icons={tableIcons}
          editable={{
            onRowUpdate: (newData, oldData) =>
               new Promise((resolve, reject) => {
                 setTimeout(() => {
                   {
                     const data = [...state.data];
                     const index = data.indexOf(oldData);
                     data[index] = newData;

                       axios({
                         url: `https://${api_url}/api/internal_user_management?login=${oldData.login}`,
                         method: 'put',
                         data:
                           {
                             "Login" : newData.login,
                             "Role": "manufacturer",
                             "available": newData.available,
                             "First_Name": newData.First_Name,
                             "Last_Name": newData.Last_Name,
                             "Permissions": {
                               "Final_QC": newData.Final_QC,
                               "CNC": newData.CNC,
                               "Shipped": newData.Shipped,
                             }
                           },
                         headers: {
                           'x-access-token' : token
                         }
                       }).then(res=> {
                         if(res.data.message){
                           alert(res.data.message)
                         }
                         else if(res.data.validation){
                           alert(res.data.validation.replace("_", " "))
                         }
                         else if(res.data.notLoggedIn){
                           setAuthenticated(false)
                           setAuthBody({role: 'visitor'})
                           alert("You have been logged out due to inactivity. Please log in again.")
                         }
                         else{
                           setState({ ...state, data });
                         }
                       })

                     resolve()
                   }
                 }, 1000);
               }),
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {

                    const data = [...state.data];
                    // data.push(newData)
                    // setState({...state, data})
                    axios({

                      url:`https://${api_url}/api/internal_user_management`,
                      method: 'post',
                      data:
                        {
                          "Login" : newData.login,
                          "Password": newData.password,
                          "Role": "manufacturer",
                          "available": newData.available,
                          "First_Name": newData.First_Name,
                          "Last_Name": newData.Last_Name,
                          "Permissions": {
                            "Final_QC": newData.Final_QC,
                            "CNC": newData.CNC,
                            "Shipped": newData.Shipped,
                          }
                        },
                      headers: {
                         'x-access-token' : token
                      }
                    }).then(
                      res => {

                        if(res.data.message){
                          alert(res.data.message)
                        }
                        else if(res.data.validation){
                          alert(res.data.validation.replace("_", " "))
                        }
                        else if(res.data.notLoggedIn){
                          setAuthenticated(false)
                          setAuthBody({role: 'visitor'})
                          alert("You have been logged out due to inactivity. Please log in again.")
                        }
                        else{
                          data.push(newData)
                          setState({...state, data})
                        }
                      }
                    )
                  resolve()
                }, 1000)

              })
          }}
          localization={{
              pagination: {
                  labelDisplayedRows: '{from}-{to} of {count}'
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected'
              },
              header: {
                  actions: 'Actions'
              },
              body: {
                  emptyDataSourceMessage: 'No records to display',
                  filterRow: {
                      filterTooltip: 'Filter'
                  }
              }
          }}
          options={{
            actionsColumnIndex: 9,
            sorting: true,
            headerStyle: {
              fontSize: '.8em'
            }
          }}
          style={{
            fontSize: '20px'
          }}
          />
        :

        <MaterialTable
          title="Manufacturers"
          columns={state.columns}
          data={state.data}
          icons={tableIcons}
          localization={{
              pagination: {
                  labelDisplayedRows: '{from}-{to} of {count}'
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected'
              },
              header: {
                  actions: 'Actions'
              },
              body: {
                  emptyDataSourceMessage: 'No records to display',
                  filterRow: {
                      filterTooltip: 'Filter'
                  }
              }
          }}
          options={{
            actionsColumnIndex: 9,
            sorting: true,
            headerStyle: {
              fontSize: '.8em'
            }
          }}
          style={{
            fontSize: '20px'
          }}
          />
      }
        </div>
        <Dialog open={state.dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change PASSWORD</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Enter Password:
            </DialogContentText>
            <input name="password" type="password" onChange={onChange} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
        </>
    );
};

export default ManufacturerList;
