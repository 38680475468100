import React, {useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import Sketch from 'react-p5';
import './styles.css';
var img, img1, img2, jmg, pg , y, w, h, originY, xScale, corImg,
apLength, mlLength, newOriginX, newOriginY;
var x = 0;
var urls = [];


const CoronalFemurP5 = (p) => {
  const { formData, setState, step, femStep }= useContext(ActiveFormDataContext);
  //const [selected_polygon, setPolygon] = useState({selected_polygon: formData.images.femur.cor.polygons[formData.selected_implants.femur_size[0]]})
  const corfemSpacing = Math.abs(formData.images.femur.cor.spacing[0]);
  const centerPhysicalX = Math.abs(formData.images.femur.cor.center_physical[0]);
  const centerPhysicalY = Math.abs(formData.images.femur.cor.center_physical[1]);
  const originX = -(formData.images.femur.cor.origin[0]) + formData.images.femur.cor.center_physical[0]
  const originY = -(formData.images.femur.cor.center_physical[1]) + formData.images.femur.cor.origin[1]
  const centerCanvasX = Math.abs(formData.images.femur.cor.origin[0] - formData.images.femur.cor.center_physical[0]);
  const centerCanvasY = Math.abs(formData.images.femur.cor.origin[1] - formData.images.femur.cor.center_physical[1]);
  let [corFemAngle, setCorFemAngle] = useState(formData.corFemAngle);
  //let [url, setUrl] = useState(formData.images.femur.cor.urls[0])
  let initial_polygon2 = formData.images.femur.cor.polygons[formData.selected_implants.femur_size[0]]
  let selected_polygon2 = []
  let images = []
  let tibAngle = 20


  // var img, pg;
  // img = p.loadImage(formData.images.femur.cor.urls[0])
  // console.log("originX :" + originX)
  // console.log("originY :" + originY)
  // console.log("CanvasX :" + centerCanvasX)
  // console.log("CanvasY :" + centerCanvasY)

  if(formData.corFemurDimensions){
    mlLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][0]
    apLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][1]
    xScale = Math.abs((1 / corfemSpacing) / 1.5 )

    let bottomTibia = (15 * corfemSpacing)
  }

  const preload = (p) => {

   //img = p.loadImage(formData.images && formData.images.femur.cor.urls[1])

     img = p.loadImage(formData.images.femur.cor.urls[0])

     img1 =p.loadImage(formData.images.femur.cor.urls[1])

     img2 =p.loadImage(formData.images.femur.cor.urls[2])

     urls.push(img)
     urls.push(img1)
     urls.push(img2)
   console.log(urls)

   // const imageInstances = [];
   // _.forEach(imageConfig.urls, v => {
   //     if (process.env.NODE_ENV === 'development') {
   //         v = 'https://localhost:8000' + v;
   //     }
   //     const img = p.loadImage(v);
   //     imageInstances.push(img)
   // })
  }


  const setup = (p, corFemurModal) => {
      console.log(urls[0])

      setState({corFemurDimensions: {height: urls[0].height * 2, width: urls[0].width * 2}});
      setState({correctionFemAngle: Number((formData.knee_overview_data.femur_angles[femStep]).toFixed(1))})

      p.createCanvas((urls[formData.corFemSlide ? formData.corFemSlide : 0].width * corfemSpacing) * 2, (urls[formData.corFemSlide ? formData.corFemSlide : 0].height * corfemSpacing) *2).parent(corFemurModal);

      p.image(urls[formData.corFemSlide ? formData.corFemSlide : 0], -(centerCanvasX * xScale) + originX,
      (-(centerCanvasY * xScale) + (originY)),
      urls[0].width * corfemSpacing * xScale,
      urls[0].height * corfemSpacing * xScale
    )


    p.noLoop()


  }

  function drawPolygon(p){
      console.log(formData.corFemAngle)

      const size = 10
      tibAngle = corFemAngle;
      const radians = (Math.PI / 180) * tibAngle;
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      let nx, ny;
      newOriginX = originX - (originX - 0.75 * mlLength);
      newOriginY = originY - (originY - 1.5 * mlLength + 18)
      for(let i = 0; i < initial_polygon2.length; i++ ){
        if(initial_polygon2[i] == null) {
             selected_polygon2.push(null)
          }
        else if(initial_polygon2[i]){
          nx =  (cos * (initial_polygon2[i][0])) + (sin * (initial_polygon2[i][1]));
          ny = (cos * (initial_polygon2[i][1])) - (sin * (initial_polygon2[i][0]));
          //copied_polygon1[i][0] = nx;
          //copied_polygon1[i][1] = ny;
          selected_polygon2.push([nx, ny])

        }

      }

      let prev = null;
      let first = null;
      selected_polygon2.forEach(point => {
          if (prev && point) {
              // p.line((prev[0] * xScale) + originX + (formData.horz ? formData.horz : 0),
              //       -(prev[1] * xScale) + (originY) + (formData.vert ? formData.vert: 0),
              //        (point[0] * xScale) + originX + (formData.horz ? formData.horz : 0),
              //        -(point[1] * xScale) + (originY) + (formData.vert ? formData.vert : 0)
              //      )

              p.line(
                  (prev[0] * xScale) + newOriginX * xScale + (formData.corFemHorz ? formData.corFemHorz : 0),
                  -(prev[1] * xScale) + (newOriginY * xScale ) + (formData.corFemVert ? formData.corFemVert : 0),
                  (point[0] * xScale) + newOriginX * xScale + (formData.corFemHorz ? formData.corFemHorz : 0),
                  -(point[1] * xScale) + (newOriginY * xScale) + (formData.corFemVert ? formData.corFemVert : 0)
              ) ;
          } else if (point) {
              first = point;
          } else if (prev) {
            p.line(
                (prev[0] * xScale) + newOriginX * xScale  + (formData.corFemHorz ? formData.corFemHorz : 0),
                -(prev[1] * xScale) + (newOriginY) * xScale + (formData.corFemVert ? formData.corFemVert : 0),
                (first[0] * xScale) + newOriginX * xScale  + (formData.corFemHorz ? formData.corFemHorz : 0),
                -(first[1] * xScale) + (newOriginY) * xScale + (formData.corFemVert ? formData.corFemVert : 0)
            );

          }
          prev = point;
      });
      selected_polygon2 = []

      //p.rect(-26, -26, 52, 52);
  }

  const draw = (p, corFemurModal) => {
    //console.log(formData.corTibSlide);
    if(formData.corFemOpen){
      p.stroke('#FFFF00');
      let c;
      let resized= false;
      if(formData.corFemSlide == 0){
          // img.resize(400, 700)
          // p.image(img, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)

          // p.image(img, 0, 0, img.width, img.height)
          p.resizeCanvas(img.width/1.5, img.height/1.5)
          p.image(img, 0, 0, img.width/1.5, img.height/1.5)

          //let scaleAP = ((4/3) * apLength)/ corfemSpacing
          c = p.get(((originX - 0.75 * mlLength)/ corfemSpacing) / 1.5,
                        ((originY + 18 - 1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5
                       )

          // p.scale(350/ scaleAP)

      }
      else if(formData.corFemSlide == 1){
          // img1.resize(400, 700)
          // p.image(img1, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img1.width * corfemSpacing * xScale, img1.height * corfemSpacing * xScale)
          // p.image(img1, 0, 0, img1.width, img1.height)
          p.resizeCanvas(img.width/1.5, img.height/1.5)
          p.image(img1, 0, 0, img1.width/1.5, img1.height/1.5)

          //let scaleAP = ((4/3) * mlLength)/ corfemSpacing
          c = p.get(((originX - 0.75 * mlLength)/ corfemSpacing) / 1.5,
                        ((originY + 18 - 1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5
                       )


          // p.resizeCanvas(((4/3) * mlLength)/ corfemSpacing,
          //                ((4/3) * mlLength)/ corfemSpacing)
          // p.scale(1.3)
          // p.scale(350/ scaleAP)

      }
      else if (formData.corFemSlide == 2){
          // img2.resize(400,700)
          // p.image(img2, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img2.width * corfemSpacing * xScale, img2.height * corfemSpacing * xScale)
          // p.image(img2, 0, 0, img.width, img.height)
          p.resizeCanvas(img.width/1.5, img.height/1.5)
          p.image(img2, 0, 0, img2.width/1.5, img2.height/1.5)

          //let scaleAP = ((4/3) * mlLength)/ corfemSpacing
          c = p.get(((originX - 0.75 * mlLength)/ corfemSpacing) / 1.5,
                        ((originY + 18 - 1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5
                       )
          // p.scale(350/ scaleAP)

      }
      else {
          // img1.resize(400, 700)
          // p.image(img1, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)

          // p.image(img1, 0, 0, img1.width , img1.height)
          p.resizeCanvas(img.width/1.5, img.height/1.5)
          p.image(img1, 0, 0, img1.width/1.5, img1.height/1.5)

          //let scaleAP = ((4/3) * mlLength)/ corfemSpacing
          c = p.get(((originX - 0.75 * mlLength)/ corfemSpacing) / 1.5,
                        ((originY  + 18 - 1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5,
                        ((1.5 * mlLength)/ corfemSpacing) /1.5
                       )




      }
      // p.scale(3)


      p.background('black')
      p.resizeCanvas(300, 300)
      p.background('black')
      p.scale(300/(((1.5 * mlLength)/ corfemSpacing) /1.5))

      p.image(c, 0 , 0)

      drawPolygon(p, corFemurModal)
      p.noFill()
    }

    }
  //
  // const auto_change_polygon = (p) => {
  //   while (1){
  //   let i = 1;
  //   const angle = 15 * i;
  //   i++;
  //   const radians = (Math.PI / 180) * angle;
  //   const cos = Math.cos(radians);
  //   const sin = Math.sin(radians);
  //   let nx, ny;
  //   for(let i = 0; i < selected_polygon.length; i++ ){
  //     if(selected_polygon[i]){
  //       nx =  (cos * (selected_polygon[i][0])) + (sin * (selected_polygon[i][1]));
  //       ny = (cos * (selected_polygon[i][1])) - (sin * (selected_polygon[i][0]));
  //       selected_polygon[i][0] = nx;
  //       selected_polygon[i][1] = ny;}}
  //     }
  // }
  //
  const keyPressed = (p) => {
    if(formData.corFemOpen){
      if(p._setupDone){
        setCorFemAngle(formData.corFemAngle || 0)
        p.redraw()
      }
    }

    //setUrl(formData.images.femur.cor.urls[2])

  }
  // console.log(`${formData.corTibAngle}`+ ` ${corTibAngle}`)

  console.log(`${formData.corFemAngle}`+ ` ${corFemAngle}`)

    return(
      <Sketch className="corFemurModal" preload={preload} setup={setup} draw={draw} mouseMoved={keyPressed}
      mouseClicked={keyPressed} />
    )


}

export default CoronalFemurP5;
