import React, { useState, useContext} from 'react';
import clsx from "clsx";

import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import axiImg  from "./imgs/FEMUR_AXIAL.jpg";
import AxialFemurP5 from './AxialFemurP5';
import AxialFemurP5Modal from './AxialFemurP5Modal';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%',
  },
  ripple: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 25,
    height: 25,
    marginBottom: 1,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#158ac7",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 25,
      height: 25,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#232ab3"
    }
  }
}));


const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      color="primary"
      className="d-flex"
      style={{
              postion: "absolute",
              bottom: "0px",
              left: "195px",
              fontSize: '1em'
             }}
      onClick={onClick}
    >Next</Button>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div style={{position:"relative"}}>
      <Button
        color="primary"
        style={{
          position: "absolute",
          top: "130px",
          left: "16px",
          fontSize: '1em'
        }}
        onClick={onClick}
      >Prev</Button>
    </div>
  );
}


const AxialFemurImage = () => {
  const [open, setOpen] = React.useState(false);
  const context = useContext(ActiveFormDataContext);
  const { authBody } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody)

  const { setState, formData } = context;
  const [count, setCount] = useState({
    activeSlide: 0
  });
  let [axiFemY, setAxiFemY] = useState(0);
  let [axiFemX, setAxiFemX] = useState(0)


  let height, ratio;
  const classes = useStyles();

  const handleClickOpen = () => {
    setState({axiOpen: true})
    setOpen(true);
  };

  const handleClose = () => {
    setState({axiOpen: false, axiFemSlide: 1})
    setOpen(false);
  };

  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  if (formData.axiFemDimensions) {
    if(formData.axiFemDimensions.width > formData.axiFemDimensions.height){
      ratio = formData.axiFemDimensions.height / formData.axiFemDimensions.height
      height = Math.trunc(150 * ratio)
    }
    ratio = formData.axiFemDimensions.height / formData.axiFemDimensions.width
    height= Math.trunc(150 * ratio);

  }
  console.log(`Ratio: ${ratio}, Height: ${height}`)

  const settings = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    fade: true,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setState({ axiFemSlide: current })

  };

  const translateUp = () => {
    if(axiFemY >= -2){
      setAxiFemY(axiFemY - 0.5)
      setState({axiFemVert: axiFemY - 0.5})
      console.log("axiFemY" + axiFemY)
    }
  }

  const translateDown = () => {
    if(axiFemY <= 2){
      setAxiFemY(axiFemY + 1)
      console.log(axiFemY)
      setState({axiFemVert: axiFemY + 0.5})
    }

  }

  const translateLeft = () =>{
    if(axiFemX >= -2){
      setAxiFemX(axiFemX - 0.5)
      setState({axiFemHorz: axiFemX - 0.5})
    }
  }

  const translateRight = () => {
    if(axiFemX <= 2){
      setAxiFemX(axiFemX + 0.5)
      setState({axiFemHorz: axiFemX + 0.5})
    }
  }

  const resetAxi = () => {
    setAxiFemX(0)
    setAxiFemY(0)
    setState({axiFemHorz: 0, axiFemVert: 0})

  }

  return (
    <>
    <div
    style={{height:`180px !important`, width:`180px !important`, marginBottom: '2em',
    marginLeft: ".5em", marginRight: ".5em"}}
    className="axiFemur"
    onClick={handleClickOpen}>
      { formData.images && <AxialFemurP5 /> }
    </div>

    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
        fullWidth={true}
        maxWidth={'lg'}
        style={{display: "block", paddingLeft:"17px"}}
      >
        <DialogTitle className="text-center" id="success-title">
          <span style={{ fontSize: '2.6em'}}>{"Axial Femur"}</span>
        </DialogTitle>
        <div>
          <div className="col-md-7 bg-dark p-1 mx-auto" style={{flexGrow: 1, color:"white"}}>
            <div className=" d-flex justify-content-center">
            <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>M E D I A L</span>
              <div className="d-flex flex-column w-100" >
                <div className="labelbox text-center">
                  <span >S U P E R I O R</span>
                </div>
                <div className="labelbox text-center">
                  <div className="mx-auto mt-4 mb-5">
                    <div className="axiFemurModal">
                      <AxialFemurP5Modal />
                    </div>
                  </div>
                  <span>I N F E R I O R</span>
                </div>
              </div>
              <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>L A T E R A L</span>
            </div>
          </div>
          <div className="col-md-4 ml-2">
            <div className="ml-4 ">
              <img className="col-md-11" src={axiImg} alt="axi" />
              <div className="col-md-1" style={{marginTop: '8.99em'}}>
              <RadioGroup>
                  <Radio
                    value={"0"}
                    className={classes.ripple}
                    color="default"
                    disableRipple
                    checked={formData.axiFemSlide === "0"}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => setState({axiFemSlide: e.target.value})}
                  />
                  <Radio
                  value={"1"}
                  color="default"
                  disableRipple

                  className={classes.ripple}
                  checked={(formData.axiFemSlide === 1) || formData.axiFemSlide === undefined ||
                    (formData.axiFemSlide === "1")
                  }
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({axiFemSlide: e.target.value})}
                  />

                  <Radio
                  value={"2"}
                  disableRipple
                  className={classes.ripple}
                  checked={formData.axiFemSlide === "2"}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({axiFemSlide: e.target.value})}
                  />
              </RadioGroup>
              </div>
              {/*<Slider {...settings}
              >
                {formData.images && formData.images.femur.sag.urls.map(img => {
                  return(
                    <div key={img}>
                      <img src={img} style={{height: "175px", width: "85%", maxWidth: "350px", marginLeft: "auto", marginRight:"auto"}}/>
                    </div>
                  )
                })}
              </Slider>*/}
            </div>
            <div className="text-nowrap pt-3">
            </div>
          </div>
        </div>
        <DialogContent>
          <div className="col-md-6">

            <div className="row justify-content-center">
              <Button
                bsStyle="primary"
                onClick={translateUp}
                disabled={hasRole(profile, ['write_admin', 'read_admin'])}

              >
                <span className="glyphicon glyphicon-chevron-up"/>
              </Button>
            </div>
            <div className="row justify-content-center">
              <Button
                bsStyle="primary"
                onClick={translateLeft}
                disabled={hasRole(profile, ['write_admin', 'read_admin'])}

              >
                <span className="glyphicon glyphicon-chevron-left"/>
              </Button>
              <Button
                bsStyle="primary"
                onClick={translateDown}
                disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-down"/>
              </Button>
              <Button bsStyle="primary"
                      onClick={translateRight}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-right"/>
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-around" style={{paddingLeft: '9em'}}>
            <Button onClick={handleClose} variant="contained" color="primary">Accept</Button>
            <Button onClick={handleClose} variant="contained" >Close</Button>
            <Button onClick={resetAxi} variant="contained" color="secondary">Reset</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
}

export default AxialFemurImage;
