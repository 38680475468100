import React, {useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";

import Slider from 'react-slick';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import corImg  from "./imgs/TIBIA_CORONAL.jpg";

import CoronalTibiaP5 from './CoronalTibiaP5';
import CoronalTibiaP5Modal from './CoronalTibiaP5Modal';
import P5Wrapper from 'react-p5-wrapper';
import sketch from './sketches/CorTibP5';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%',
  },
  ripple: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 30,
    height: 30,
    marginBottom: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#158ac7",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#232ab3"
    }
  }
}));

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className="d-flex"
      color="primary"
      style={{
              postion: "absolute",
              bottom: "0px",
              left: "195px",
              fontSize: '1em'
             }}
      onClick={onClick}

    >Next</Button>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  const context = useContext(ActiveFormDataContext);
  const { setState, formData } = context;

  return (
    <div style={{position:"relative"}}>
      <Button
        color="primary"
        style={{
          position: "absolute",
          top: "142px",
          left: "16px",
          fontSize: '1em'
        }}
        onClick={(e) => {
          onClick();

        }}
      >Prev</Button>
    </div>
  );
}


var initialTibStep;

const CoronalTibiaImage = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(ActiveFormDataContext);
  const { authBody } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody)

  let { setState, formData, tibStep, femStep,
        nextTibStep, prevTibStep, setTibStep,
        resetFemTibStep, toggleRotate, customRotate
       } = context;
  const [color, setColor] = useState([Math.random()*255, Math.random()*255, Math.random()*255])
  let ratio, height, femur_decision_angle, tibia_decision_angle, patient_tib_angle, var_or_val, alignment;
  let [corTibY, setCorTibY] = useState(0);
  let [corTibX, setCorTibX] = useState(0)
  const classes = useStyles();

  if(!open){
    initialTibStep = tibStep
  }


  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }



  const handleClickOpen = () => {
    setState({corTibOpen: true, corTibSlide: 1})
    setOpen(true);
  };


  const handleClose = () => {
    setTibStep(initialTibStep)
    setState({correctionTibAngle: Number((formData.knee_overview_data.tibia_angles[initialTibStep]).toFixed(1)),
      corTibSlide: 1,
      corTibOpen: false,
      tibStep: tibStep
    })
    toggleRotate()

    setOpen(false);
  };

  const handleAccept = () => {
    setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep],
      corTibOpen: false,
      tibRotate: true,
      rotate: true
    })
    setOpen(false);
  };

  if(formData.decision_json){
    tibia_decision_angle = Number(formData.decision_json['Vv:Tibia:decision.angle.degree'])
    femur_decision_angle = Number(formData.decision_json['Vv:Femur:decision.angle.degree'])
    patient_tib_angle = Math.abs(Number(tibia_decision_angle).toFixed(1))
    alignment = formData.knee_overview_data.default_alignment
    var_or_val = formData.knee_overview_data.var_or_val

  }

  if (formData.corTibiaDimensions) {
   ratio = formData.corTibiaDimensions.height / formData.corTibiaDimensions.width
   height = Math.trunc(124 * ratio)
  }

  const handleColor = () => {
    setColor([Math.random()*255, Math.random()*255, Math.random()*255])
  }

  const rotateRight = () => {
    if(formData.slug.includes("R") && var_or_val.includes("Varus")||
       formData.slug.includes("L") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Valgus") && alignment == "N"){
        if(tibStep > 0){
          prevTibStep(tibStep-1);
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }

      }
      else if(var_or_val.includes("Varus") && alignment == "N"){
        if(tibStep > 0){
          prevTibStep(tibStep-1);
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
      else{
        if(tibStep < formData.knee_overview_data.divide_count + 1){
          nextTibStep();
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})
        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


          setState({correctionAngle: 0})
        }
      }

    }
    else if(formData.slug.includes("L") && var_or_val.includes("Varus") ||
            formData.slug.includes("R") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Varus") && alignment === "N" ||
        var_or_val.includes("Valgus") && alignment === "N"
      ){
        if(tibStep < formData.knee_overview_data.divide_count + 1){
          nextTibStep();
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})
        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


          setState({correctionAngle: 0})
        }
      }
      else {
        if(tibStep > 0){
          prevTibStep(tibStep-1);
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


        }
      }
    }
  }

  const rotateLeft = () => {
    if(formData.slug.includes("R") && var_or_val.includes("Varus")||
       formData.slug.includes("L") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Valgus") && alignment == "N"){
        if(tibStep < formData.knee_overview_data.divide_count + 1){

          nextTibStep();
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})
        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
      else if(var_or_val.includes("Varus") && alignment == "N"){
        if(tibStep < formData.knee_overview_data.divide_count + 1){
          nextTibStep();
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})
        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
      else{
        if(tibStep > 0){
          prevTibStep(tibStep - 1);
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


        }
      }

    }
    else if(formData.slug.includes("L") && var_or_val.includes("Varus") ||
            formData.slug.includes("R") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Varus") && alignment === "N" ||
        var_or_val.includes("Valgus") && alignment === "N"
      ){
        if(tibStep > 0){
          prevTibStep(tibStep-1);
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


        }
      }
      else{
        if(tibStep < formData.knee_overview_data.divide_count + 1){
          nextTibStep();
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})
        }
        else{
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
    }

  }

  const translateUp = () => {
    if(corTibY >= -2){
      setCorTibY(corTibY - 0.5)
      setState({tibVert: corTibY - 0.5})
      console.log("corTibY" + corTibY)
    }
  }

  const translateDown = () => {
    if(corTibY <= 2){
      setCorTibY(corTibY + 1)
      console.log(corTibY)
      setState({tibVert: corTibY + 0.5})
    }

  }

  const translateLeft = () =>{
    if(corTibX >= -2){
      setCorTibX(corTibX - 0.5)
      setState({tibHorz: corTibX - 0.5})
    }
  }

  const translateRight = () => {
    if(corTibX <= 2){
      setCorTibX(corTibX + 0.5)
      setState({tibHorz: corTibX + 0.5})
    }
  }

  const handleReset = () => {
    setTibStep(initialTibStep)
    setState({correctionTibAngle: Number((formData.knee_overview_data.tibia_angles[initialTibStep]).toFixed(1))})
    setCorTibX(0)
    setCorTibY(0)
    setState({tibStep: tibStep, tibHorz: 0, tibVert: 0})
    toggleRotate()
  }

  const settings = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    fade: true,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setState({ corTibSlide: current })
  };

  return (
    <>
    <div
        style={{height:`180px !important`, width:"180px !important", marginBottom: "1em",
        marginLeft: ".5em", marginRight: ".5em"}}
        className="corTibia bg-dark"
        onClick={handleClickOpen}
        >
         <CoronalTibiaP5 />
    </div>
    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
        fullWidth={true}
        maxWidth={'md'}
        style={{display: "block", paddingLeft:"17px"}}
      >
        <DialogTitle className="text-center" id="success-title">
        <span style={{ fontSize: '2.6em'}}>{"Coronal Tibia"}</span>

        </DialogTitle>
        <div>
          <div className="col-md-7 bg-dark p-1 mx-auto" style={{flexGrow: 1, color: 'white'}}>
            <div className=" d-flex justify-content-center">
            <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>M E D I A L</span>
              <div className="d-flex flex-column w-100" >
                <div className="labelbox text-center">
                  <span >S U P E R I O R</span>
                </div>
                <div className="labelbox text-center">
                  <div className="mx-auto mt-4 mb-5">
                    <div className="corTibiaModal">
                      <CoronalTibiaP5Modal />
                    </div>
                  </div>
                  <span>I N F E R I O R</span>
                </div>
              </div>
              <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>L A T E R A L</span>
            </div>
          </div>
          <div className="col-md-4 ml-2">
            <div className="ml-4">
              <img src={corImg} alt="sag-Left" />
              <RadioGroup>
                <div
                     style={{marginLeft: '3.85em'}}
                >
                  <Radio
                    value={"0"}
                    className={classes.ripple}
                    color="default"
                    disableRipple
                    checked={formData.corTibSlide === "0"}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => setState({corTibSlide: e.target.value})}
                  />
                  <Radio
                  value={"1"}
                  color="default"
                  disableRipple

                  className={classes.ripple}
                  checked={(formData.corTibSlide === 1) || formData.corTibSlide === undefined ||
                    (formData.corTibSlide === "1")
                  }
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({corTibSlide: e.target.value})}
                  />

                  <Radio
                  value={"2"}
                  color="default"
                  disableRipple
                  className={classes.ripple}
                  checked={formData.corTibSlide === "2"}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({corTibSlide: e.target.value})}
                  />
                </div>
              </RadioGroup>
              {/*<Slider {...settings}
              >
                {formData.images && formData.images.femur.sag.urls.map(img => {
                  return(
                    <div key={img}>
                      <img src={img} style={{height: "175px", width: "85%", maxWidth: "350px", marginLeft: "auto", marginRight:"auto"}}/>
                    </div>
                  )
                })}
              </Slider>*/}
            </div>
            <div className="text-nowrap pt-3" style={{fontSize: '1.5em', marginTop: '.5em'}}>
              <div>Patient Angle:
              <span className="font-weight-bold"
                style={{color: '#005a9b'}}>
              {` ${patient_tib_angle}`} degrees {var_or_val}
              </span>
              </div>
              <div>
              Correction Angle:
              <span className="font-weight-bold"
                style={{color: '#f08902'}}>
              {` ${Math.abs(Number((formData.knee_overview_data.tibia_angles[tibStep])).toFixed(1))}`} degrees
              {var_or_val == "Varus" ? " Valgus" : " Varus"}
              </span>
              </div>
              <div>Tibia Angle:
              <span className="font-weight-bold"
                style={{color: '#2e8b57'}}>
              {`
                ${Number(patient_tib_angle -
                  Math.abs(Number((formData.knee_overview_data.tibia_angles[tibStep])).toFixed(1))
                ).toFixed(1)}`
              } degrees {var_or_val}
              </span>
              </div>
            </div>
          </div>
        </div>
        <DialogContent>
          <div className="col-md-4">
            <div className="row justify-content-center">
              <Button bsStyle="primary"
                      onClick={translateUp}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-up"/>
              </Button>
            </div>
            <div className="row justify-content-center">
              <Button bsStyle="primary"
                      onClick={translateLeft}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-left"/>
              </Button>
              <Button bsStyle="primary"
                      onClick={translateDown}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-down" />
              </Button>
              <Button bsStyle="primary"
                      onClick={translateRight}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}

              >
                <span className="glyphicon glyphicon-chevron-right"/>
              </Button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="col-md-4">
            <Button className="float-left"
                    bsStyle="primary"
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
                    onClick={rotateLeft}
            >
              <span>
                 <RotateLeftIcon fontSize="large" />
              </span>
            </Button>
            </div>
            <p className="col-md-4">Rotate</p>
            <div className="col-md-4">
            <Button className="float-right"
                    bsStyle="primary"
                    onClick={rotateRight}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span>
                 <RotateRightIcon fontSize="large" />
              </span>
            </Button>
            </div>
          </div>
          <div className="col-md-5 d-flex justify-content-around">
            <Button onClick={handleAccept}
                    variant="contained"
                    color="primary"
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >Accept</Button>
            <Button onClick={handleClose} variant="contained" >Close</Button>
            <Button onClick={handleReset}
                    variant="contained"
                    color="secondary"
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >Reset</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
}

export default CoronalTibiaImage;
