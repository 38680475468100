import React, {useMemo, useCallback, useState, useContext } from 'react';
import {useDropzone} from 'react-dropzone';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";


import { useParams } from "react-router-dom";
import Success from './Success';
import LoadingSpinner from './LoadingSpinner';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'Center',
  padding: '25px',
  borderWidth: 2,
  borderRadius: 20,
  height: '65vh',
  borderColor: '2px dashed #C7C7C',
  borderStyle: 'dashed',
  backgroundColor: '#E1E1E1',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const  UploadZone = () => {
  const { token, setAuthenticated, setAuthBody } = useContext(AuthorizeContext);
  const [loading, setLoading]  = useState(false)
  const [success, setSuccess] = useState(false)
  let { caseId } = useParams()

  const onDrop = (files) => {
  // const uploaders = files.map(file => {
  //     const formData = new FormData();
  //     formData.append("dicoms", file);
  //     console.log(file)
  //     console.log(formData)
  //     return axios.post("https://34.222.155.138/api/up_mri", formData, {
  //       headers: { "content-type" : "multipart/form-data",
  //       'Access-Control-Allow-Origin': true }
  //     }).then( res => {
  //       const data = res.data;
  //       console.log(data)
  //     })
  //  });
   // axios.all(uploaders).then(() => {
   //   console.log("Success!")
   // })
   let formData = new FormData();
    let thing =[]
    for (let file of files){
      thing.push(file)
    }
   // const uploaders = (files) => {
        for (let i = 0; i < thing.length; i++){
          formData.append('dicoms',thing[i]);
        }
        console.log(files)
        console.log(thing)
        console.log({"dicoms":files})

        console.log(...formData)
     // };
     // {[{"dicoms"}:files]}
     setLoading(true)
     axios.post(`https://${api_url}/api/up_mri?id=${caseId}`, formData, {
       headers: { 'x-access-token' : token }
     }).then( res => {
       const data = res.data;
       if(res.data.message){
         alert(res.data.message);
         setSuccess(false);
       }
       else if(res.data.validation){
         alert(res.data.validation.replace("_", " "))
       }
       else if(res.data.notLoggedIn){
         setAuthenticated(false)
         setAuthBody({role: 'visitor'})
         alert("You have been logged out due to inactivity. Please log in again.")
       }
       else{
        setSuccess(true) // When you put right file, pop up completed message
       }
     }).then(res => {
      console.log(success)
      setLoading(false) //when you put wrong files or empty folder, you can see Alert message from server and go back to up load page
     }).catch(
       err => {
         setLoading(false) //when you put wrong files or empty folder, you can see Alert message from server and go back to up load page
         alert(err)
         console.log(err)
       }
     )

       // axios.all(uploaders).then(() => {
       //   console.log("Success!")
       // })

  }


  const {
    getRootProps,
    acceptedFiles,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop,
    accept: "",
    multiple: true
  });

  const acceptedFilesItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));



  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject
  ]);
  if (loading){
    return(
      <LoadingSpinner />
    )
  }
  else if (success){
    return(
      <Success />
    )
  }
  return (
    <div>
    <div className="col-md-8" style={{ float: "right" }}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div style={{paddingTop: "17vh"}}>
          <h1 style={{color:"black", align: "center", paddingLeft: "40px"}}>Drop Zone</h1>
          <p style={{color:"black", alight: "center"}}>Click and Drag Patient MRI Files Here</p>
        </div>
      </div>
      <aside>
       <h4>Accepted files</h4>
         <ul>
           {acceptedFilesItems}
         </ul>
       </aside>
    </div>
    </div>
  );
}

export default UploadZone;
