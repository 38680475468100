import React, {useMemo, useCallback, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import { Redirect} from 'react-router-dom';



const useStyles = makeStyles({
  root: {fontSize: '2em'},
  spacing: {justifyContent: 'space-evenly'},
  button:{
    fontSize: "1.5em",
    float: "right",
    margin: "10px 30px 10px 10px"
  }});

const theme = createMuiTheme({
  spacing: {justifyContent: 'space-evenly'},
  h2: {typography: { h6: {fontSize: '3em'}}},
});


const Success = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(true);
  const [redirect, setRedirect] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRedirect(true)
  };
  if (redirect){
    return(
      <Redirect
        to='/'/>
    )
  }
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
      >
        <DialogTitle
          disableTypography
          classes={{root: classes.root}}
          className="text-center" id="success-title">{"Patient MRI Uploaded"}</DialogTitle>
        <CheckCircleOutline color="primary" style={{ fontSize: 100, margin: 'auto'}} />
        <DialogContent>
          <DialogContentText id="case-description" style={{fontSize: '1.5em'}}>
            Patient MRI Files Uploaded. Please click OK to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" variant="contained" onClick={handleClose} style={{fontSize: '2em'}}>
              <span className="glyphicon glyphicon-ok"/>
              &nbsp;OK
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Success;
