import React, { useState, useEffect, useContext } from 'react';
import {
  Link
} from "react-router-dom";
import jwt_decode from "jwt-decode";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import decode from 'jwt-decode';
import { Redirect } from 'react-router-dom';
import { AuthorizeContext } from "../RootContext";
import { api_url } from "../authorize";

import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

import bg from '../img/background.jpg';
import logo from '../img/lento_logo.jpg'
import celogo from '../img/ce.png'

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    position: "absolute",
    left: "50%",
    top: "50%",
    webkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  media: {
    height: 150,
    marginTop: 10,
    paddingTop: 15,
    width: 400,
    // backgroundColor: '#eeeeee'
    backgroundColor: 'white',
    borderBottom: '1px black'
  },
  cemedia: {
    height: 30,
    width: 75,
    margin: '1em'
  },
  title: {
    fontSize: 14,
  },
  textField: {
    fontSize: 15,
    marginBottom:'.8em',
    marginTop: '1em',
    margin: 'auto'
  },
  //style for font size
  resize:{
    padding: '3em',
    paddingBottom: '2em',
    fontSize:35
  },
  pos: {
    marginBottom: 12,
  },
});

const PackagingLogin = ({ profile }) => {
  const classes = useStyles()

  console.log(bg)
  let {setAuthBody, authBody, authenticated, setAuthenticated} = useContext(AuthorizeContext);
  const [redirect, setRedirect] = useState(false)
  const onChange = (option) => {
    const { name, value } = option.target
    setAuthBody({...authBody,
      [name] : value
    })
  }

  const handleSubmit =(e) => {
    e.preventDefault()

    axios({
      method: 'POST',
      url: `https://${api_url}/api/login_manufacturing?process_code=799`,
      auth: authBody
    }).then(
      res =>{
        if (res.data.token){
          const token = res.data
          const decoded = jwt_decode(token.token, {header: true})
          // sessionStorage.setItem('authBody', decoded);
          sessionStorage.setItem('token', token.token);
          sessionStorage.setItem('packaging', true);
          // setCookie('name', res.headers, { path: '/' });
          // authBody = decoded
          setAuthenticated(true)
          console.log(res.data)
          sessionStorage.setItem('authenticated', "true")
          window.location.reload()
        }
        if (res.data.message){
          alert(res.data.message)
        }
    })
  }

  if(authenticated == true) {
    return <Redirect to="/shippingCheck" />
  }



  return(

    <div className="center" style={{width: '100%', height: '100%',
      backgroundImage: `url(${bg})`,
      backgroundRepeat: 'no-repeat',
      backgrounPosition: 'center',
      backgroundSize: 'cover'
    }} >
      <div>
        <Card className={classes.root} variant="outlined">
          <CardMedia className={classes.media} image={logo} />
          <CardHeader
            image="Lento Logo"
            subheader="PtoleMedic Surgical Planning Software Ver. 1.3.1"
          />
          <CardContent>
          <Typography variant="h3">
            PACKAGING LOGIN
          </Typography>
          <div>
            <form onSubmit={handleSubmit}>
              <Input
                className={classes.textField}
                placeholder="Enter Username"
                name="username"
                type="text"
                size="medium"
                onChange={onChange}
                InputProps={{
                  classes: {
                    input: classes.resize
                  }
                }}
                fullWidth
                required
              />
              <Input
                className={classes.textField}
                placeholder="Enter Password"
                name="password"
                type="password"
                onChange={onChange}
                fullWidth
                required
                />
                <Button type="submit" className="float-right" style={{marginTop: '2em', marginBottom: '2em'}} variant="contained" color="primary" size="large" onClick={handleSubmit} >
                Log In
                </Button>
            </form>
           </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
  }

export default PackagingLogin;
