import React, { useState, useContext } from 'react';
import clsx from "clsx";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import axiImg  from "./imgs/TIBIA_AXIAL.jpg";
import AxialTibiaP5 from './AxialTibiaP5';
import AxialTibiaP5Modal from './AxialTibiaP5Modal';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%',
  },
  ripple: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 25,
    height: 25,
    marginBottom: 1,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#158ac7",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 25,
      height: 25,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#232ab3"
    }
  }
}));

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className="d-flex"
      color="primary"
      style={{
              postion: "absolute",
              bottom: "0px",
              left: "195px",
              fontSize: '1em'
             }}
      onClick={onClick}
    >Next</Button>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div style={{position:"relative"}}>
      <Button
        color="primary"
        style={{
          position: "absolute",
          top: "130px",
          left: "16px",
          fontSize: '1em'
        }}
        onClick={onClick}
      >Prev</Button>
    </div>
  );
}


const AxialTibiaImage = () => {
  const [open, setOpen] = React.useState(false);
  const context = useContext(ActiveFormDataContext);
  const { authBody } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody)

  const { setState, formData } = context;
  const [count, setCount] = useState({
    activeSlide: 0
  });
  let ratio, height;
  const [axiTibY, setAxiTibY] = useState(0)
  const [axiTibX, setAxiTibX] = useState(0)
  const classes = useStyles();


  const handleClickOpen = () => {
    setState({axiTibOpen: true})
    setOpen(true);
  };

  const handleClose = () => {
    setState({axiTibOpen: false, axiTibSlide: 1})
    setOpen(false);
  };

  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  if (formData.axiTibDimensions) {
   ratio = formData.axiTibDimensions.width / formData.axiTibDimensions.height
   height = Math.trunc(124 * ratio)
  }

  const settings = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    fade: true,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setState({ axiTibSlide: current })
  };


  const translateUp = () => {
    if(axiTibY >= -2){
      setAxiTibY(axiTibY - 0.5)
      setState({axiTibVert: axiTibY - 0.5})
      console.log("axiTibY" + axiTibY)
    }
  }

  const translateDown = () => {
    if(axiTibY <= 2){
      setAxiTibY(axiTibY + 1)
      console.log(axiTibY)
      setState({axiTibVert: axiTibY + 0.5})
    }

  }

  const translateLeft = () =>{
    if(axiTibX >= -2){
      setAxiTibX(axiTibX - 0.5)
      setState({axiTibHorz: axiTibX - 0.5})
    }
  }

  const translateRight = () => {
    if(axiTibX <= 2){
      setAxiTibX(axiTibX + 0.5)
      setState({axiTibHorz: axiTibX + 0.5})
    }
  }

  const resetAxi = () => {
    setAxiTibX(0)
    setAxiTibY(0)
    setState({axiTibHorz: 0, axiTibVert: 0})

  }



  return (
    <>
    {

      <div
      className="axiTibia" style={{height: '180px !important', width:'180px !important',
      marginLeft: ".5em", marginRight: ".5em", marginBottom: "1em", backgroundColor: "black"}}
      onClick={handleClickOpen}>
      { formData.images && <AxialTibiaP5 /> }
      </div>
    }
    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
        fullWidth={true}
        maxWidth={'lg'}
        style={{display: "block", paddingLeft:"17px"}}
      >
        <DialogTitle className="text-center" id="success-title">
          <span style={{ fontSize: '2.6em'}}>{"Axial Tibia"}</span>

        </DialogTitle>
        <div>
          <div className="col-md-7 bg-dark p-1 mx-auto" style={{flexGrow: 1, color:"white"}}>
            <div className=" d-flex justify-content-center">
            <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>M E D I A L</span>
              <div className="d-flex flex-column w-100" >
                <div className="labelbox text-center">
                  <span >S U P E R I O R</span>
                </div>
                <div className="labelbox text-center">
                  <div className="mx-auto mt-4 mb-5">
                    <div className="axiTibiaModal">
                      <AxialTibiaP5Modal />
                    </div>
                  </div>
                  <span>I N F E R I O R</span>
                </div>
              </div>
              <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>L A T E R A L</span>
            </div>
          </div>
          <div className="col-md-4 ml-2">
              <div className="ml-4 ">
                <img className="col-md-11" src={axiImg} alt="axi" />
                <div className="col-md-1" style={{marginTop: "5.5em"}}>
                <RadioGroup>
                    <Radio
                      value={"0"}
                      className={classes.ripple}
                      color="default"
                      disableRipple
                      checked={formData.axiTibSlide === "0"}
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      onChange={(e) => setState({axiTibSlide: e.target.value})}
                    />
                    <Radio
                    value={"1"}
                    color="default"
                    disableRipple

                    className={classes.ripple}
                    checked={(formData.axiTibSlide === 1) || formData.axiTibSlide === undefined ||
                      (formData.axiTibSlide === "1")
                    }
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => setState({axiTibSlide: e.target.value})}
                    />

                    <Radio
                    value={"2"}
                    color="default"
                    disableRipple
                    className={classes.ripple}
                    checked={formData.axiTibSlide === "2"}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => setState({axiTibSlide: e.target.value})}
                    />
                </RadioGroup>
                </div>
                {/*<Slider {...settings}
                >
                  {formData.images && formData.images.femur.sag.urls.map(img => {
                    return(
                      <div key={img}>
                        <img src={img} style={{height: "175px", width: "85%", maxWidth: "350px", marginLeft: "auto", marginRight:"auto"}}/>
                      </div>
                    )
                  })}
                </Slider>*/}
              </div>
            <div className="text-nowrap pt-3">
            </div>
          </div>
        </div>
        <DialogContent>
        <div className="col-md-6">
          <div className="row justify-content-center">
            <Button bsStyle="primary"
                    onClick={translateUp}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span className="glyphicon glyphicon-chevron-up"/>
            </Button>
          </div>
          <div className="row justify-content-center">
            <Button bsStyle="primary"
                    onClick={translateLeft}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}

            >
              <span className="glyphicon glyphicon-chevron-left"/>
            </Button>
            <Button bsStyle="primary"
                    onClick={translateDown}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span className="glyphicon glyphicon-chevron-down"/>
            </Button>
            <Button bsStyle="primary"
                    onClick={translateRight}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span className="glyphicon glyphicon-chevron-right"/>
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-around" style={{paddingLeft: '9em'}}>
          <Button onClick={handleClose} variant="contained" color="primary">Accept</Button>
          <Button onClick={handleClose} variant="contained" >Close</Button>
          <Button onClick={resetAxi} variant="contained" color="secondary">Reset</Button>
        </div>
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
}

export default AxialTibiaImage;
