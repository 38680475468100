import React, { useState, useContext } from 'react';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import Sketch from 'react-p5';
import './styles.css';
var img, img1, img2, xScale, 
mlLength, apLength, newOriginX, newOriginY;

var x = 0;

const AxialTibiaP5 = (p) => {
    const { formData, setState }= useContext(ActiveFormDataContext);
    const corfemSpacing = Math.abs(formData.images.tibia.axi.spacing[0]);
    const axifemSpacing = Math.abs(formData.images.tibia.axi.spacing[0]);
    const centerPhysicalX = Math.abs(formData.images.tibia.axi.center_physical[0]);
    const centerPhysicalY = Math.abs(formData.images.tibia.axi.center_physical[1]);
    const originX = -(formData.images.tibia.axi.origin[0]) + formData.images.tibia.axi.center_physical[0]
    const originY = (formData.images.tibia.axi.center_physical[1]) - formData.images.tibia.axi.origin[1]
    const centerCanvasX = Math.abs(formData.images.tibia.axi.origin[0] - formData.images.tibia.axi.center_physical[0]);
    const centerCanvasY = Math.abs(formData.images.tibia.axi.origin[1] - formData.images.tibia.axi.center_physical[1]);
    // var img, pg;
    // img = p.loadImage(formData.images.femur.axi.urls[0])
    // console.log(img)
    let [axiFemAngle, setAxiFemAngle] = useState(formData.axiTibAngle || 0);
    let initial_polygon2 = formData.images.tibia.axi.polygons[formData.selected_implants.tibia_size[0]]
    let selected_polygon2 = []
    let urls = []
    let angle;

    if(formData.axiTibDimensions){
        mlLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][0]
        apLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][1]
        xScale = Math.abs((1 / corfemSpacing))
        console.log(xScale)
        let bottomFemur = (15 * axifemSpacing)

    }

    const preload = (p) => {
        if(formData.images){
            urls.push(formData.images.tibia.axi.urls[0])
            urls.push(formData.images.tibia.axi.urls[1])
            urls.push(formData.images.tibia.axi.urls[2])
        }

        img1 = p.loadImage(formData.images.tibia.axi.urls[0])

        img =p.loadImage(formData.images.tibia.axi.urls[1])

        img2 =p.loadImage(formData.images.tibia.axi.urls[2])
    }



    const setup = (p, axiTibia) => {
        setState({axiTibDimensions: {height: img.height, width: img.width},
          axiTibImgLoaded: true})
        p.createCanvas((img.width * axifemSpacing), (img.width * axifemSpacing)).parent(axiTibia);
        //p.createCanvas((img.width), (img.height)).parent(axiTibia);
        p.image(img1, -(centerCanvasX * xScale) + originX,
            (-(centerCanvasY * xScale) + (originY)),
            img.width * axifemSpacing * xScale,
            img.height * axifemSpacing * xScale
        )
        p.noLoop()
    }

    function drawPolygon(p){
        const size = 10
        angle = axiFemAngle;
        const radians = (Math.PI / 180) * angle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        let nx, ny;
        newOriginX = originX - (originX - 0.75 * mlLength);
        newOriginY = originY - (originY - 0.75 * mlLength )
        for(let i = 0; i < initial_polygon2.length; i++ ){
            if(initial_polygon2[i] == null) {
                selected_polygon2.push(null)
            }
            else if(initial_polygon2[i]){
                nx =  (cos * (initial_polygon2[i][0])) + (sin * (initial_polygon2[i][1]));
                ny = -((cos * (initial_polygon2[i][1])) - (sin * (initial_polygon2[i][0])));
                //copied_polygon1[i][0] = nx;
                //copied_polygon1[i][1] = ny;
                selected_polygon2.push([nx, ny])
            }

        }


        let prev = null;
        let first = null;
        selected_polygon2.forEach(point => {
            if (prev && point) {
              p.line(
                  (prev[0] * xScale) + newOriginX * xScale + (formData.axiTibHorz ? formData.axiTibHorz : 0),
                  -(prev[1] * xScale) + (newOriginY * xScale ) + (formData.axiTibVert ? formData.axiTibVert : 0),
                  (point[0] * xScale) + newOriginX * xScale + (formData.axiTibHorz ? formData.axiTibHorz : 0),
                  -(point[1] * xScale) + (newOriginY * xScale) + (formData.axiTibVert ? formData.axiTibVert : 0)
              ) ;
            } else if (point) {
                first = point;
            } else if (prev) {
              p.line(
                  (prev[0] * xScale) + newOriginX * xScale  + (formData.axiTibHorz ? formData.axiTibHorz : 0),
                  -(prev[1] * xScale) + (newOriginY) * xScale + (formData.axiTibVert ? formData.axiTibVert : 0),
                  (first[0] * xScale) + newOriginX * xScale  + (formData.axiTibHorz ? formData.axiTibHorz : 0),
                  -(first[1] * xScale) + (newOriginY) * xScale + (formData.axiTibVert ? formData.axiTibVert : 0)
              );
            }
            prev = point;
        });
        selected_polygon2 = []
        //p.rect(-26, -26, 52, 52);
    }

    const draw = (p, axiTibia) => {
        if(!formData.axiTibOpen){
            let c;
            p.pixelDensity(.5)
            p.strokeWeight(2.5)
            p.stroke('#FFFF00');
            p.resizeCanvas(img.width, img.height)
            p.image(img, 0, 0, img.width, img.height)

            //let scaleAP = ((4/3) * mlLength)/ axiFemSpacing
            c = p.get(((originX -0.75 * mlLength)/ corfemSpacing),
                          ((originY -0.75* mlLength)/ corfemSpacing),
                          ((1.5 * mlLength)/ corfemSpacing),
                          ((1.5 * mlLength)/ corfemSpacing)
                         )

            p.resizeCanvas(300, 300)
            p.background('black')
            p.scale(300/(((1.5 * mlLength)/ corfemSpacing)))
            p.image(c, 0 , 0)
            p.noFill()
            drawPolygon(p, axiTibia)
        }
    }
    //
    // const auto_change_polygon = (p) => {
    //   while (1){
    //   let i = 1;
    //   const angle = 15 * i;
    //   i++;
    //   const radians = (Math.PI / 180) * angle;
    //   const cos = Math.cos(radians);
    //   const sin = Math.sin(radians);
    //   let nx, ny;
    //   for(let i = 0; i < selected_axi_polygon.length; i++ ){
    //     if(selected_axi_polygon[i]){
    //       nx =  (cos * (selected_axi_polygon[i][0])) + (sin * (selected_axi_polygon[i][1]));
    //       ny = (cos * (selected_axi_polygon[i][1])) - (sin * (selected_axi_polygon[i][0]));
    //       selected_axi_polygon[i][0] = nx;
    //       selected_axi_polygon[i][1] = ny;}}
    //     }
    // }
    //
    const mouseClicked = (p) => {
        if(!formData.axiTibOpen){
            if(p._setupDone){
                p.background('black')
                p.redraw()
            }
        }
        //setUrl(formData.images.femur.axi.urls[2])

    }

    return(
        <>
        <div id="p5_loading" className="axiTibia"
        style={{height:`180px`, width:`180px`, marginBottom: '2em',
        marginLeft: ".5em", marginRight: ".5em" , backgroundColor: 'black'}}
        >
        </div>

        <Sketch className="axiTibia" preload={preload} setup={setup} draw={draw}
        mouseClicked={mouseClicked}
        />
        </>
    )

}

export default AxialTibiaP5;
