import React, { useContext } from 'react';
import {Table, Button } from 'react-bootstrap';
import { Link, Switch, Route, Redirect } from "react-router-dom";
import './styles/adminmain.css'
import MRISurgeonList from './MRI/mriSurgeonList';
import SurgeonCaseList from './Surgeon/surgeonCaseList';
import MRIList from './MRI/mriList';
import MRICasePage from './mricenter';
import { AuthorizeContext } from "../RootContext";

const MRISurgeonPage = () => {
  return(
    <div>

      <MRISurgeonList />

    </div>


  )
}

export default MRISurgeonPage;
