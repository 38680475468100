import React, { memo, useContext } from 'react';
import { MRIContext } from './MRIFormContext';



const MRICaseId = (props) => {
  const context = useContext(MRIContext)
  const { formData } = context
  let side
  if(formData.case_id) {
    if(formData.case_id.substring(formData.case_id.length - 1, formData.case_id.length) == "R"){
      side = "Right"
    }
    else{
      side = "Left"
    }
  }

  return(
    <div>
      <h2>{formData.case_id}</h2>
      <h4>Surgery Side: {formData.case_id ? side : null} </h4>
    </div>
  )
}

export default MRICaseId;
