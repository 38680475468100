import React, {useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import Sketch from 'react-p5';
import './styles.css';
var img, img1, img2, jmg, pg , y, w, h, originY, xScale, corImg, apLength, mlLength,
newOriginX, newOriginY
;
var x = 0;


const SagittalFemurP5 = (p) => {

    const { formData, setState, step }= useContext(ActiveFormDataContext);
    //const [selected_polygon, setPolygon] = useState({selected_polygon: formData.images.tibia.cor.polygons[formData.selected_implants.tibia_size[0]]})
    const corfemSpacing = Math.abs(formData.images.femur.sag.spacing[0]);
    // const centerPhysicalX = Math.abs(formData.images.femur.sag.center_physical[0]);
    // const centerPhysicalY = Math.abs(formData.images.femur.sag.center_physical[1]);
    const centerPhysicalX = formData.images.femur.sag.center_physical[0] ;
    const centerPhysicalY = formData.images.femur.sag.center_physical[1] ;
    // const originX = Math.abs(formData.images.femur.sag.origin[0] * 2) * 1.15;
    // const originY = Math.abs(formData.images.femur.sag.origin[1] * 2) * 1.4;
    // const originX = Math.abs(formData.images.femur.sag.origin[0] ) * 1;
    // const originY = Math.abs(formData.images.femur.sag.origin[1] ) * 1;
    // const originX = formData.images.femur.sag.origin[0] * 1;
    // const originY = formData.images.femur.sag.origin[1] * 1;
    const originX = -(formData.images.femur.sag.origin[0]) + formData.images.femur.sag.center_physical[0]
    const originY = -(formData.images.femur.sag.center_physical[1]) + formData.images.femur.sag.origin[1]
    const centerCanvasX = Math.abs(formData.images.femur.sag.origin[0] - formData.images.femur.sag.center_physical[0]);
    const centerCanvasY = Math.abs(formData.images.femur.sag.origin[1] - formData.images.femur.sag.center_physical[1]);
    let [corTibAngle, setCorTibAngle] = useState(formData.corTibAngle || 0);
    //let [url, setUrl] = useState(formData.images.femur.cor.urls[0])
    let initial_polygon2 = formData.images.femur.sag.polygons[formData.selected_implants.femur_size[0]]
    let selected_polygon2 = []
    let urls = []
    let images = []
    let tibAngle = 20
    // var img, pg;
    // img = p.loadImage(formData.images.tibia.cor.urls[0])
    // console.log("originX :" + originX)
    // console.log("originY :" + originY)
    // console.log("CanvasX :" + centerCanvasX)
    // console.log("CanvasY :" + centerCanvasY)

    if(formData.sagFemModalDimensions){
        mlLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][0]
        apLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][1]
        // xScale = Math.abs(formData.sagFemModalDimensions.width / (medialLength * 1.8))
        console.log("ML: ", mlLength, "AP: ", apLength)
        xScale = Math.abs((1 / corfemSpacing) / 1.5 )
        console.log("ORIGIN X: ", originX)
        let bottomTibia = (15 * corfemSpacing)
    }

    const preload = (p) => {

        //img = p.loadImage(formData.images && formData.images.tibia.cor.urls[1])
        if(formData.images){
            urls.push(formData.images.femur.sag.urls[0])
            urls.push(formData.images.femur.sag.urls[1])
            urls.push(formData.images.femur.sag.urls[2])
          }
        img = p.loadImage(formData.images.femur.sag.urls[0])
        img1 = p.loadImage(formData.images.femur.sag.urls[1])

        img2 =p.loadImage(formData.images.femur.sag.urls[2])

    }


    const setup = (p, sagFemurModal) => {
        // setState({sagFemModalDimensions: {height: img.height * 2, width: img.width * 2}});
        setState({sagFemModalDimensions: {height: img.height, width: img.width}});

        //p.background('black')
        // p.createCanvas(img.width * corfemSpacing * 2, img.height * corfemSpacing * 2).parent(sagFemurModal);
        p.createCanvas(img.width/ 1.5, img.height/ 1.5).parent(sagFemurModal);
        // img.resize(400, 700)
        // p.image(img1, -(centerCanvasX * xScale) + originX,
        //     (-(centerCanvasY * xScale) + (originY)),
        //     img.width * corfemSpacing * xScale,
        //     img.height * corfemSpacing * xScale
        // )

        p.image(img1, 0,
            0,
            img.width * xScale  ,
            img.height * xScale
        )

        p.noLoop()
      }

    function drawPolygon(p){
        const size = 10
        tibAngle = 0;
        const radians = (Math.PI / 180) * tibAngle;
        const cos = Math.cos(radians);
        const sin = Math.sin(radians);
        let nx, ny;
        // p.background('black')

        newOriginX = originX- (originX - 0.75 * apLength);
        newOriginY = originY - (originY - 1.5 * apLength + 18)
        for(let i = 0; i < initial_polygon2.length; i++ ){
            if(initial_polygon2[i] == null) {
                selected_polygon2.push(null)
            }
            else if(initial_polygon2[i]){
                nx =  (cos * (initial_polygon2[i][0])) + (sin * (initial_polygon2[i][1]));
                ny =  (cos * (initial_polygon2[i][1])) - (sin * (initial_polygon2[i][0]));
                //copied_polygon1[i][0] = nx;
                //copied_polygon1[i][1] = ny;
                selected_polygon2.push([nx, ny])
            }

        }
        // p.line(originX - size, (originY), originX + size, (originY ));
        // p.line(originX, (originY ) - size, originX, (originY) + size);
        //
        // p.line(0 - size, 0, 0 + size);
        // p.line(0, 0 - size, 0, 0 + size);

        let prev = null;
        let first = null;
        selected_polygon2.forEach(point => {
            if (prev && point) {
                // p.line(
                //     (prev[0] * xScale) + originX + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(prev[1] * xScale) + (originY) + (formData.sagFemVert ? formData.sagFemVert : 0),
                //     (point[0] * xScale) + originX + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(point[1] * xScale) + (originY) + (formData.sagFemVert ? formData.sagFemVert : 0)
                // ) ;
                p.line(
                    (prev[0] * xScale) + newOriginX * xScale + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                    -(prev[1] * xScale) + (newOriginY * xScale ) + (formData.sagFemVert ? formData.sagFemVert : 0),
                    (point[0] * xScale) + newOriginX * xScale + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                    -(point[1] * xScale) + (newOriginY * xScale) + (formData.sagFemVert ? formData.sagFemVert : 0)
                ) ;
                // p.line(
                //     (prev[0] * xScale) + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(prev[1] * xScale) + (formData.sagFemVert ? formData.sagFemVert : 0),
                //     (point[0] * xScale) + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(point[1] * xScale) + (formData.sagFemVert ? formData.sagFemVert : 0)
                //
                // ) ;
            } else if (point) {
                first = point;
            } else if (prev) {
                // p.line(
                //     (prev[0] * xScale) + newOriginX+ (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(prev[1] * xScale) + (newOriginY) + (formData.sagFemVert ? formData.sagFemVert : 0),
                //     (first[0] * xScale) + newOriginX+ (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(first[1] * xScale) + (newOriginY) + (formData.sagFemVert ? formData.sagFemVert : 0)
                // );
                p.line(
                    (prev[0] * xScale) + newOriginX * xScale  + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                    -(prev[1] * xScale) + (newOriginY) * xScale + (formData.sagFemVert ? formData.sagFemVert : 0),
                    (first[0] * xScale) + newOriginX * xScale  + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                    -(first[1] * xScale) + (newOriginY) * xScale + (formData.sagFemVert ? formData.sagFemVert : 0)
                );
                // p.line(
                //     (prev[0] * xScale)   + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(prev[1] * xScale) + (formData.sagFemVert ? formData.sagFemVert : 0),
                //     (first[0] * xScale)  + (formData.sagFemHorz ? formData.sagFemHorz : 0),
                //     -(first[1] * xScale) + (formData.sagFemVert ? formData.sagFemVert : 0)
                // );
            }
            prev = point;
        });
        selected_polygon2 = []
        //p.rect(-26, -26, 52, 52);
    }

    const draw = (p, sagFemur) => {
        //console.log(formData.corTibSlide);
        if(formData.sagFemOpen){
            let corFemSpacing  = Math.abs(formData.images.femur.sag.spacing[0])
            p.stroke('#FFFF00');
            let c;
            let resized= false;
            if(formData.sagFemSlide == 0){
                // img.resize(400, 700)
                // p.image(img, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)

                // p.image(img, 0, 0, img.width, img.height)
                p.resizeCanvas(img.width/1.5, img.height/1.5)
                p.image(img, 0, 0, img.width/1.5, img.height/1.5)

                //let scaleAP = ((4/3) * apLength)/ corFemSpacing
                c = p.get(((originX - 0.75 * apLength)/ corFemSpacing) / 1.5,
                              ((originY + 18 - 1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5
                             )

                // p.scale(350/ scaleAP)

            }
            else if(formData.sagFemSlide == 1){
                // img1.resize(400, 700)
                // p.image(img1, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img1.width * corfemSpacing * xScale, img1.height * corfemSpacing * xScale)
                // p.image(img1, 0, 0, img1.width, img1.height)
                p.resizeCanvas(img.width/1.5, img.height/1.5)
                p.image(img1, 0, 0, img1.width/1.5, img1.height/1.5)

                //let scaleAP = ((4/3) * apLength)/ corFemSpacing
                c = p.get(((originX - 0.75 * apLength)/ corFemSpacing) / 1.5,
                              ((originY + 18 - 1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5
                             )


                // p.resizeCanvas(((4/3) * apLength)/ corFemSpacing,
                //                ((4/3) * apLength)/ corFemSpacing)
                // p.scale(1.3)
                // p.scale(350/ scaleAP)

            }
            else if (formData.sagFemSlide == 2){
                // img2.resize(400,700)
                // p.image(img2, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img2.width * corfemSpacing * xScale, img2.height * corfemSpacing * xScale)
                // p.image(img2, 0, 0, img.width, img.height)
                p.resizeCanvas(img.width/1.5, img.height/1.5)
                p.image(img2, 0, 0, img2.width/1.5, img2.height/1.5)

                //let scaleAP = ((4/3) * apLength)/ corFemSpacing
                c = p.get(((originX - 0.75 * apLength)/ corFemSpacing) / 1.5,
                              ((originY + 18 -1.5* apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5
                             )
                // p.scale(350/ scaleAP)

            }
            else {
                // img1.resize(400, 700)
                // p.image(img1, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)

                // p.image(img1, 0, 0, img1.width , img1.height)
                p.resizeCanvas(img.width/1.5, img.height/1.5)
                p.image(img1, 0, 0, img1.width/1.5, img1.height/1.5)

                //let scaleAP = ((4/3) * apLength)/ corFemSpacing
                c = p.get(((originX - 0.75 * apLength)/ corFemSpacing) / 1.5,
                              ((originY +18 - 1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5,
                              ((1.5 * apLength)/ corFemSpacing) /1.5
                             )




            }
            // p.scale(3)


            //p.background('black')
            p.resizeCanvas(300, 300)
            p.background('black')
            p.scale(300/(((1.5 * apLength)/ corFemSpacing) /1.5))

            p.image(c, 0 , 0)
            // p.background('black')

            drawPolygon(p, sagFemur)
            // p.background('black')

            p.noFill()
        }
    }
    //
    // const auto_change_polygon = (p) => {
    //   while (1){
    //   let i = 1;
    //   const angle = 15 * i;
    //   i++;
    //   const radians = (Math.PI / 180) * angle;
    //   const cos = Math.cos(radians);
    //   const sin = Math.sin(radians);
    //   let nx, ny;
    //   for(let i = 0; i < selected_polygon.length; i++ ){
    //     if(selected_polygon[i]){
    //       nx =  (cos * (selected_polygon[i][0])) + (sin * (selected_polygon[i][1]));
    //       ny = (cos * (selected_polygon[i][1])) - (sin * (selected_polygon[i][0]));
    //       selected_polygon[i][0] = nx;
    //       selected_polygon[i][1] = ny;}}
    //     }
    // }
    //
    const keyPressed = (p) => {
        if(formData.sagFemOpen){
          if(p._setupDone){
            p.background('black')
            p.redraw()
          }
        }
        //setUrl(formData.images.femur.cor.urls[2])
    }


    return(
        <Sketch className="sagFemurModal" preload={preload} setup={setup} draw={draw} mouseClicked={keyPressed} />
    )


}

export default SagittalFemurP5;
