import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import { useParams } from 'react-router-dom';
import {Col, Row, Button, Modal} from 'react-bootstrap';
import P5Wrapper from 'react-p5-wrapper';
import sketch from './sketches/CorTibP5';

import CoronalFemurImage from './CoronalFemurImage';
import CoronalTibiaImage from './CoronalTibiaImage';
import AxialFemurImage from './AxialFemurImage';
import AxialTibiaImage from './AxialTibiaImage';
import SagittalFemurImage from './SagittalFemurImage';
import SagittalTibiaImage from './SagittalTibiaImage';

import HipImage from './HipImage';
import FemurImage from './FemurImage';
import TibiaImage from './TibiaImage';
import AnkleImage from './AnkleImage';

import KneeAlignmentP5 from './KneeAlignmentP5';
import ResetDialog from './ResetDialog';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import logo from './../../img/lento_logo.jpg';

import CaseAccept from "./CaseAccept";
import CaseReject from './CaseReject';
import CaseWarning from './CaseWarning';
import CaseDanger from './CaseDanger';
import CaseError from './CaseError';
import CaseRotate from './CaseRotate';
import is from 'is_js';

const SurgeonApproval = () => {
  const { token, setAuthenticated, setAuthBody, authBody, authenticated } = useContext(AuthorizeContext);
  const context = useContext(ActiveFormDataContext);
  let { caseid } = useParams()
  const [data, setData] = useState([]);
  const [error, setError] = useState(false)
  const [profile, setProfile] = useState(authBody)
  const [ loading , setLoading] = useState(true)
  const [close, setClose] = useState(false);
  let { setState, formData, femStep, nextFemStep, prevFemStep, tibStep,
        setFemStep, setTibStep, nextTibStep, prevTibStep,
        customRotate, toggleRotate, resetFemTibStep
   } = context;
  const [color, setColor] = useState([Math.random()*255, Math.random()*255, Math.random()*255])
  const onImgLoad = ({target: img}) => {
    setState({
      corFemDimensions: {
        height: img.offsetHeight,
        width: img.offsetWidth
      }}
    )
  }

  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  let tibia_decision_angle, femur_decision_angle, patient_angle, var_or_val, correctionAngle, alignment, absFem,
      absTib, lateral_cut_depth, medial_cut_depth, flag, left, surgeonName, patientName, dateCreated;

  if(formData.decision_json){
    flag = formData.knee_overview_data.flag
    tibia_decision_angle = Number(formData.decision_json['Vv:Tibia:decision.angle.degree'])
    femur_decision_angle = Number(formData.decision_json['Vv:Femur:decision.angle.degree'])
    patient_angle = Math.abs(Number((tibia_decision_angle + femur_decision_angle).toFixed(1)))
    var_or_val = formData.knee_overview_data.var_or_val

    alignment = formData.knee_overview_data.default_alignment
    absFem = Math.abs(Math.round(Number((formData.knee_overview_data.femur_angles[femStep])) * 1000) / 1000);
    console.log("ABSFEM, ",absFem)
    absTib = Math.abs(Math.round(Number((formData.knee_overview_data.tibia_angles[tibStep])) * 1000) / 1000);
    console.log("ABSTIB, ", absTib)
    correctionAngle = ((absFem + absTib).toFixed(1))
    lateral_cut_depth = formData.knee_overview_data.cut_depth[femStep][0]
    medial_cut_depth = formData.knee_overview_data.cut_depth[femStep][1]
    if(formData.slug.includes("L")){
      left = true
    }
    if(data.Surgeon){
      console.log(data)
      surgeonName = `${data.Surgeon.Last_Name}, ${data.Surgeon.First_Name}`
      patientName = `${data.Patient.Last_Name}, ${data.Patient.First_Name}`

      dateCreated = data.Patient.Date_of_Surgery

    }



  }


  useEffect(() => {
    const fetchCase = async () => {
      const res = await axios({
        method: 'get',
        url: `https://${api_url}/api/case?case_id=${caseid}`,
        headers: {
          'x-access-token' : token
        }
      }).then(
        res =>{
          if(res.data.message){
            setError(true)
            alert(res.data.message)
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{

            setData(res.data)
            console.log(res.data)
          }
        }
      ).catch(
        err => {
          setError(true)
          alert(err)
          setData(err.data)
        }
      );
    };
      fetchCase();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        method: 'get',
        url: `https://${api_url}/api/surgeon_approval?case_id=${caseid}`,
        headers: {
          'x-access-token' : token
        }
      }).then( res => {
        if(res.data.message){
          setError(true)
          alert(res.data.message)
        }
        else{
          if(res.data.screen_shot){            
          console.log(res.data)
          setState(res.data)
          }
          setLoading(false)
          console.log(res.data)
          setState(res.data)
        }
      }
    )


    };
      fetchData();
  }, []);

  const handleStepNext = () => {
    if(formData.slug.includes("R") && var_or_val.includes("Varus")||
       formData.slug.includes("L") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Valgus") && alignment == "N"){
        if(femStep > 0){
          prevFemStep(femStep - 1);
          prevTibStep(tibStep-1);
          console.log(tibStep)
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }

      }
      else if(var_or_val.includes("Varus") && alignment == "N"){
        if(femStep > 0){
          prevFemStep(femStep - 1);
          prevTibStep(tibStep-1);
          console.log(femStep)
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
      else{
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          console.log(formData)
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


          console.log(femStep)
          nextFemStep();
          nextTibStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


          setState({correctionAngle: 0})
        }
      }

    }
    else if(formData.slug.includes("L") && var_or_val.includes("Varus") ||
            formData.slug.includes("R") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Varus") && alignment === "N" ||
        var_or_val.includes("Valgus") && alignment === "N"
      ){
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          console.log(formData)
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


          console.log(femStep)
          nextFemStep();
          nextTibStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


          setState({correctionAngle: 0})
        }
      }
      else {
        if(femStep > 0){
          prevFemStep(femStep - 1);
          prevTibStep(tibStep-1);
          console.log(femStep)
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
    }
  }
  const handleStepPrev = () => {
    if(formData.slug.includes("R") && var_or_val.includes("Varus")||
       formData.slug.includes("L") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Valgus") && alignment == "N"){
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})
          console.log(tibStep)
          nextFemStep();
          nextTibStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
      else if(var_or_val.includes("Varus") && alignment == "N"){
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

          nextFemStep();
          nextTibStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
      else{
        if(femStep > 0){
          prevFemStep(femStep - 1);
          prevTibStep(tibStep - 1);
          console.log(femStep)
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


        }
      }

    }
    else if(formData.slug.includes("L") && var_or_val.includes("Varus") ||
            formData.slug.includes("R") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Varus") && alignment === "N" ||
        var_or_val.includes("Valgus") && alignment === "N"
      ){
        if(femStep > 0){
          prevFemStep(femStep - 1);
          prevTibStep(tibStep-1);
          console.log(femStep)
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})
          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})


        }
      }
      else{
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

          nextFemStep();
          nextTibStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[tibStep]})

          setState({corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]})

        }
      }
    }

  }

  const handleReset = () => {
    resetFemTibStep()
    if(customRotate){
      toggleRotate()
    }

  }
  const handleClose = () => {
    setClose(true)
  }



  // console.log('correction angle: ' + correctionAngle)
  const handleColor = () => {
    setColor([Math.random()*255, Math.random()*255, Math.random()*255])
  }

  if(close || formData.errorConfirmed){
    return(
      <Route path="/surgeon-approval/:caseid" render={({ match }) => (
        <Redirect to={`/case/${match.params.caseid}`} />
      )} />
    )
  }

  else if(!authenticated){
    return(
      <Redirect to={'/'} />
    )
  }
  // if(!formData.warningConfirmed && flag === 7){
  //   return(
  //     <CaseWarning />
  //   )
  // }
  //
  // else if(error){
  //   return(
  //     <CaseError />
  //   )
  // }
  //
  // else if(!formData.dangerConfirmed && flag > 7){
  //   if(flag != 14){
  //     return(
  //       <CaseDanger />
  //
  //     )
  //   }
  // }

  else if(formData.screen_shot){
    let str = formData.screen_shot
    let part1 = str.split('.com')[1]
    if (part1.includes("(") && part1.includes(")")){
    let part2 = part1.split("(")[1].split(")")[1].replace("-",".png")
    let part3 = str.split(".com")[0] +'.com' +part1.slice(0,8)
    let part4 = part3 + part2
    formData.screen_shot = part4
  }
    return(
      <div>
        <a href={formData.screen_shot} download
          style={{position: "absolute",
          top:"5.5em",
          right: '30em',
          zIndex: '1000'}}
        >
          <Button bsStyle="primary">Download ScreenShot</Button>
        </a>
        <Button
          style={{position: "absolute",
                  top:"5.5em",
                  right: '20em',
                  zIndex: '1000'}}
          onClick={handleClose}>Back</Button>
        <img src={formData.screen_shot} alt="Surgeon Approval ScreenShot" />
      </div>
    )
  }

  else if(loading){
    return(
      <div style={{position:"fixed", width: '100%', zIndex: '3',
            height: "100%", left: 0, top: 0, backgroundColor: "white"
      }}>
      </div>
      )
  }

  return(
    <div style={{paddingLeft: '12.5em'}}>
    <div className="col-md-3 m-0 p-0">
      <div className="img-area ml-2 p-0">
        <div className="kneeAlign col-md-12 ml-0 p-0">
          {formData.knee_overview_data ? <KneeAlignmentP5 /> : null}
          {
            femStep != tibStep ?
            <ResetDialog />
            :
            <div>
              <Button className="float-left"
                style={{zIndex: 400}}
                bsStyle="primary"
                onClick={handleStepPrev}
                disabled={hasRole(profile, ['write_admin', 'read_admin'])}

              >
                <span style={{zIndex: 400}} className="glyphicon glyphicon-chevron-left"/>
              </Button>
              <Button
                className="float-right"
                style={{zIndex: 400}}
                bsStyle="primary"
                onClick={handleStepNext}
                disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span style={{zIndex: 400}} className="glyphicon glyphicon-chevron-right"/>
              </Button>
            </div>
          }
          </div>
      </div>
    </div>
{   /* <div className="col-sm-1">
      <div className="d-flex flex-column pt-4">
        <HipImage />
        <FemurImage />
      </div>
      <div className="d-flex flex-column pt-1">
        <TibiaImage />
        <AnkleImage />
      </div>
    </div>*/}
    <div className="background-ivory border-color-black col-md-8 m-0 p-0" >
      <div className="col-md-1 pr-0 pt-0">
        <div className="d-flex flex-column pt-0">
          <HipImage />
          <FemurImage />
          <TibiaImage />
          <AnkleImage />
        </div>
      </div>
      <div className="border-bottom col-md-11 pl-0 ml-0" >
      <div className="d-flex mb-3 ml-0 pl-0">
        <div className="col-md-12 pl-0 ml-0" style={{ border: "1px solid black", paddingRight:'0'}}>
          <div className="d-flex mb-1 p-1 border-bottom border-dark">
            <div className="col-6 text-center" style={{ color: "green" }}><h4>{data.case_id} Total Knee Arthroplasty (TKA)</h4></div>
              <div className="col-6 text-center" style={{ color: "blue" }}><h4>{data.Implant && data.Implant.replace("_", " ")}</h4></div>
          </div>
          <div className="rowInfo" style={{height: '7em', borderBottom: '1px solid black'}}>
            <div className="measureInfo col-md-4 justify-content-between" style={{ color: "black", marginBottom: '2.1em'}}>
                <div className="pl-3 text-center"><h5>Femur Cut Depth</h5></div>
                <div className="d-flex">
                <div className="flex-column col-6 d-flex">
                  <span>Lateral:</span>
                  <span>Medial:</span>
                </div>
              <div className="flex-column col-6 d-flex" style={{color:'#00508f'}}>
              <span className="font-weight-bold">{lateral_cut_depth ? lateral_cut_depth.toFixed(1) : null} mm</span>
              <span className="font-weight-bold">{medial_cut_depth ? medial_cut_depth.toFixed(1) : null} mm</span>
              </div>
              </div>
            </div>
            <div className="surgeonInfo col-md-8" style={{paddingLeft: '125px'}}>
              <h5 style={{display:"flex"}}><div style={{width: '40%', textAlign: "right", marginRight: "5px"}}>Ordering Surgeon:</div>
                <div style={{color:'#00508f', fontWeight: 'bold'}}>Dr. {surgeonName}</div>
              </h5>
              <h5 style={{display:"flex"}}><div style={{width: '40%', textAlign: "right", marginRight: "5px"}}>Patient Name:</div>
                <div style={{color:'#00508f', fontWeight: 'bold'}}>{patientName}</div>
              </h5>
              <h5 style={{display:"flex"}}><div style={{width: '40%', textAlign: "right", marginRight: "5px"}}>Date of Surgery:</div>
                <div style={{color:'#00508f', fontWeight: 'bold'}}>{dateCreated}</div>
              </h5>
            </div>
          </div>
          <div className="col-md-12 bg-white pb-0" style={{ color: "black", display: 'flex', justifyContent: 'space-between' }}>
            <div className="col-md-3 bg-dark p-0" style={{flexGrow: 1}}>
              <h3 className="text-center border-bottom" style={{color: "white"}}>Coronal</h3>
              <div className=" d-flex justify-content-center">
              <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright", color: "white"}}>
                {left ? "M E D I A L" : "L A T E R A L"}
              </span>
                <div className="d-flex flex-column w-100 labelbox text-center" >
                  <div className="labelbox text-center" style={{color: "white"}}>
                    <span >S U P E R I O R</span>
                      { formData.images && <CoronalFemurImage /> }
                  </div>
                  <div>
                    { formData.images && <CoronalTibiaImage />  }
                  </div>
                  <div style={{color: "white"}}>
                    <span>I N F E R I O R</span>
                  </div>
                </div>
                <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright", color: "white"}}>
                {left ? "L A T E R A L" : "M E D I A L"}
                </span>
              </div>

            </div>
            <div className="col-md-3 bg-dark p-0" style={{flexGrow: 1, position: "relative"}}>
              <div style={{position: 'absolute', width: '1px', marginRight: '40px', backgroundColor: 'black',
              height: '100%', left: '-25%'
              }}>
              </div>
              <h3 className="text-center border-bottom" style={{color: "white"}}>Axial</h3>
              <div className=" d-flex justify-content-center">
              <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright", color: "white"}}>
                {left ? "M E D I A L" : "L A T E R A L"}
              </span>
                <div className="d-flex flex-column w-100 labelbox text-center" >
                  <div className="labelbox text-center" style={{color: "white"}}>
                    <span >A N T E R I O R</span>
                      <AxialFemurImage />
                  </div>
                  <div>
                    <AxialTibiaImage />
                  </div>
                  <div style={{color: "white"}}>
                    <span>P O S T E R I O R</span>
                  </div>
                </div>
                <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright", color: "white"}}>
                {left ? "L A T E R A L" : "M E D I A L"}
                </span>
              </div>
            </div>
            <div className="col-md-3 bg-dark p-0 " style={{flexGrow: 1}}>
              <div style={{position: 'absolute', width: '1px', marginRight: '40px',
              backgroundColor: 'black', height: '100%', left: '-25%'}}>
              </div>
              <h3 className="text-center border-bottom" style={{color: "white"}}>Sagittal</h3>
              <div className=" d-flex justify-content-center">
              <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright", color: "white"}}>
                {left ? "M E D I A L" : "L A T E R A L"}
              </span>
                <div className="d-flex flex-column w-100 labelbox text-center" >
                  <div className="labelbox text-center" style={{color: "white"}}>
                    <span >A N T E R I O R</span>
                      { formData.images && <SagittalFemurImage /> }
                  </div>
                  <div>
                    { formData.images && <SagittalTibiaImage /> }
                  </div>
                  <div style={{color: "white"}}>
                    <span>P O S T E R I O R</span>
                  </div>
                </div>
                <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright", color: "white"}}>
                {left ? "L A T E R A L" : "M E D I A L"}
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
        <div className="d-flex border border-dark" style={{width:"100%"}}>
          <div className="col-md-4">
            <div><h5>Patient Angle: <span className="font-weight-bold"
              style={{color: '#004b8a'}} >{patient_angle} degrees {var_or_val}</span></h5></div>
            <div><h5>Correction Angle: <span className="font-weight-bold"
              style={{color: '#f08902'}}>{correctionAngle} degrees {var_or_val == "Varus" ? "Valgus" : "Varus"}</span></h5></div>
            <div><h5>Overall Angle: <span style={{color: '#2e8b57'}}
              className="font-weight-bold"
            >{
              (patient_angle - correctionAngle).toFixed(1)

            } degrees {var_or_val == "Varus" ? "Varus" : "Valgus"}</span></h5></div>
          </div>
          <div className="col-md-2 align-items-center">
            <div><h5>FEM IMP SIZE: <span
              className="font-weight-bold" style={{color: '#004b8a', textAlign: 'right'}}>{ formData.selected_implants && formData.selected_implants.femur_size[1]}
              </span></h5></div>
            <div><h5>TIB IMP SIZE: <span style={{color: '#004b8a', marginLeft: '8px'}}
              className="font-weight-bold"> { formData.selected_implants && formData.selected_implants.tibia_size[1]}
              </span></h5></div>
          </div>
          <div className="col-md-6 d-flex justify-content-between align-items-center">
            <Button className="ml-2 btn-lg" bsStyle="primary" onClick={handleClose}>
              <span className="glyphicon glyphicon-remove"/>
              &nbsp; close
            </Button>
            {
              !formData.dangerConfirmed && data.flag_status[0].title ==="no_flag" ?
              <>
              <Button
                className="ml-2 btn-lg"
                bsStyle="warning"
                onClick={handleReset}
                disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
              <span className="glyphicon glyphicon-refresh"/>
              &nbsp; reset
              </Button>
              <CaseReject />
              {console.log(data.flag_status)}
              <CaseAccept />
              </>
              :
              null
            }
            {
              formData.rotate ?

              <>
              <CaseRotate />
              </>

              :

              null
            }
            {
              !formData.warningConfirmed && flag === 7 ?
              <>
                <CaseWarning />
              </>

              :

              (!formData.dangerConfirmed && flag > 7 &&  flag != 14)?
              <>
                <CaseDanger />
              </>

              :

              error ?
              <>
               <CaseError />
              </>
              :

              null

            }
          </div>
        </div>
      </div>
    </div>
    </div>
  )

}


export default SurgeonApproval;
