import React, { memo, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { roles } from './../../mriFormData';
import styles from './../../styles';
import { ControlLabel } from 'react-bootstrap';
import { useParams } from "react-router-dom"
import logo from './../../img/lento_logo.jpg';

import { MRIContext } from './MRIFormContext';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";


import MRITitle from './MRITitle';
import MRIBody from './MRIBody';


const MRIForm = memo((props) => {
  const { token, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const context = useContext(MRIContext);
  const [options, setOptions] = useState({})
  const [loading, setLoading] = useState(true)
  const { formData, setState } = context
  let { caseId } = useParams()
  console.log(caseId)

  console.log(formData)
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        method: 'get',
        url: `https://${api_url}/api/case?case_id=${caseId}`,
        headers: {
          'x-access-token' : token
        }
      }).then(res=> {
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
          setLoading(false)
          setState(res.data)
        }
      });

    };
    fetchData();
  }, []);

  const onChange = (option) => {
    const { name, value } = option.target
    setOptions({...options,
      [name] : value
    })
  }

  /*const data = {
    "bigform": context.formData,
    "options": options
  }*/

  /*const handleOptions = () => {
    for(i=0; i<data.length; i++) {
        var item =
    }
  }*/
  // formData.Patient && formData.Patient.map((field, i) => {
  //   console.log(field)
  // });

  // bodyPrefs && bodyPrefs.map((bodyPref, i) => {
  //    if (bodyPref.body_key == formData.surgeryPref & !existPref){
  //      existPref = true
  //      formData.shipping_address = bodyPref.shipping_address
  //      formData.billing_address = bodyPref.billing_address
  //      formData.Pref_Alignment = bodyPref.alignment
  //    }
  //  });

if(loading){
  return(
    <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
            height: "100%", left: 0, top: 0, backgroundColor: "white"
          }}>
        <img src={logo} alt="logo"
        style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
        />
    </div>

  )
}
else if(formData.Surgeon && formData.Patient){
  return(
    <div>
    <form>
      {
        roles.map((role) => {
          const { roleName, roleId} = role
          if(roleName.includes("Surgeon")){
            return (
              <div key={roleId}>
                <div  className="col-lg-4 pb-4" style={{ borderStyle: "solid" }}>
                  <ControlLabel><MRITitle roleName={roleName} /></ControlLabel>
                  <MRIBody roleId={roleId} />
                </div>
                <div  className="col-lg-4 pb-4" style={{ borderStyle: "solid" }}>
                  <div style={styles.titleWrapper}>
                    <h2 style={styles.title}>Patient</h2>
                  </div>
                  <div>
                    <div>
                      <label>First Name</label>
                    </div>
                    <div>
                    <pre
                      name="Patient_First_Name"
                      type="text"
                      value={formData['Patient_First_Name'] = formData.Patient.First_Name}
                    >{formData.Patient_First_Name}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Middle Name</label>
                    </div>
                    <div>
                    <pre
                      name="Patient_Middle_Name"
                      type="text"
                      value={formData['Patient_Middle_Name'] = formData.Patient.Middle_Name}
                    >{formData.Patient_Middle_Name}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Last Name</label>
                    </div>
                    <div>
                    <pre
                      name="Patient_Last_Name"
                      type="text"
                      value={formData['Patient_Last_Name'] = formData.Patient.Last_Name}
                    >{formData.Patient_Last_Name}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Sex</label>
                    </div>
                    <div>
                      <pre
                        name="Patient_Sex"
                        type="text"
                        value={formData['Patient_Sex'] = formData.Patient.Sex}
                      >{formData.Patient_Sex}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Age</label>
                    </div>
                    <div>
                      <pre
                        name="Patient_Age"
                        type="date"
                        value={formData['Patient_Age'] = formData.Age}
                      >{formData.Age}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Date of Purchase Order</label>
                    </div>
                    <div>
                      <pre
                        name="Patient_Date_of_Surgery"
                        value={formData['Patient_Date_of_Surgery'] = formData.dateCreated}
                      >{formData.dateCreated}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Date of Surgery</label>
                    </div>
                    <div>
                      <pre
                        name="Patient_Date_of_Surgery"
                        value={formData['Patient_Date_of_Surgery'] = formData.Patient.Date_of_Surgery}
                      >{formData.Patient.Date_of_Surgery}</pre>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Surgery Type</label>
                    </div>
                    <div>
                      <pre
                        name="Patient_Surgery_Type"
                        value={formData['Patient_Surgery_Type'] = formData.Patient.Surgery_Type}
                      >{formData.Patient_Surgery_Type}</pre>
                    </div>
                  </div>
                </div>
            </div>
            )
          }
          return(
            <div key={roleId}>
              <div  className="col-lg-4 pb-4" style={{ borderStyle: "solid" }}>
                <ControlLabel><MRITitle roleName={roleName} /></ControlLabel>
                <MRIBody roleId={roleId} />
              </div>
            </div>
          )}
      )
    }
    </form>
    </div>
  )
}


})

export default MRIForm;
