export const roles = [
  { roleName: "Surgeon", roleId: "Surgeon"},
  { roleName: "Distributor", roleId: 'Distributor', caseId:"newCase" },
  { roleName: "Hospital", roleId: 'Hospital', caseId:"newCase" },
  { roleName: "MRI Center", roleId: 'MRI_Center', caseId:"newCase" },
]

export const fields = [
  { fieldName: "First Name*", fieldId: "First_Name", fieldType: "text", preview: "true", combine: "true"},
  { fieldName: "Middle Name", fieldId: "Middle_Name", fieldType: "text"},
  { fieldName: "Last Name*", fieldId: "Last_Name", fieldType: "text", combine:"true" },
  { fieldName: "Office Name*", fieldId: "Office_Name", fieldType: "text", preview:"true" },
  { fieldName: "Postal Code*", fieldId: "Postal_Code", fieldType: "text", preview:"true", addyCombine: "true" },
  { fieldName: "City*", fieldId: "City", fieldType: "text", preview:"true", addyCombine: "true"},
  { fieldName: "Street*", fieldId: "Street", fieldType: "text", preview:"true", addyCombine: "true" },
  { fieldName: "State/Province*", fieldId: "State_Province", fieldType: "text", preview:"true", addyCombine: "true"  },
  { fieldName: "Country", fieldId:"Country", fieldType:"dropdown", preview: "true", addyCombine: "true"},
  { fieldName: "Email Address*", fieldId: "Email_Address", fieldType: "text", preview: "true" },
  { fieldName: "Phone*", fieldId: "Phone", fieldType: "text", preview: "true" },

  { fieldName: "Phone*", fieldId: "Phone", fieldType: "phone" },
  { fieldName: "Mobile*", fieldId: "Mobile", fieldType: "phone" },
  { fieldName: "Fax", fieldId: "Fax", fieldType: "text", preview: "true" },
]

export const fieldsDis = [
  { fieldName: "Company Name", fieldId: "Office_Name", fieldType: "text", preview:"true" },
  { fieldName: "First Name", fieldId: "First_Name", fieldType: "text", preview: "true", nameCombine: "true"},
  { fieldName: "Middle Name", fieldId: "Middle_Name", fieldType: "text"},
  { fieldName: "Last Name", fieldId: "Last_Name", fieldType: "text", nameCombine:"true" },
  { fieldName: "Postal Code*", fieldId: "Postal_Code", fieldType: "textarea", preview:"true", addyCombine: "true" },
  { fieldName: "City*", fieldId: "City", fieldType: "textarea", addyCombine: "true"},
  { fieldName: "Street*", fieldId: "Street", fieldType: "textarea", addyCombine: "true" },
  { fieldName: "State/Province", fieldId: "State_Province", fieldType: "textarea", addyCombine: "true"  },
  { fieldName: "Country", fieldId:"Country", fieldType:"textarea", addyCombine: "true" },
  { fieldName: "Email Address", fieldId: "Email_Address", fieldType: "text", preview: "true" },
  { fieldName: "Company Number", fieldId: "Phone", fieldType: "text", preview: "true" },
  { fieldName: "Mobile Number", fieldId: "Phone", fieldType: "text", preview: "true" },

  { fieldName: "Fax", fieldId: "Fax", fieldType: "text", preview: "true" },
]

export const fieldsHos =
  [
    { fieldName: "Hospital Name", fieldId: "Office_Name", fieldType: "text", preview:"true" },
    { fieldName: "First Name", fieldId: "First_Name", fieldType: "text", preview: "true", nameCombine: "true"},
    { fieldName: "Middle Name", fieldId: "Middle_Name", fieldType: "text"},
    { fieldName: "Last Name", fieldId: "Last_Name", fieldType: "text", nameCombine:"true" },
    { fieldName: "Postal Code", fieldId: "Postal_Code", fieldType: "textarea", preview:"true", addyCombine: "true" },
    { fieldName: "City", fieldId: "City", fieldType: "textarea", addyCombine: "true"},
    { fieldName: "Street", fieldId: "Street", fieldType: "textarea", addyCombine: "true" },
    { fieldName: "State/Province", fieldId: "State_Province", fieldType: "textarea", addyCombine: "true"  },
    { fieldName: "Country", fieldId:"Country", fieldType:"textarea", addyCombine: "true" },
    { fieldName: "Email Address", fieldId: "Email_Address", fieldType: "text", preview: "true" },
    { fieldName: "Hospital Number", fieldId: "Phone", fieldType: "text", preview: "true" },
    { fieldName: "Mobile Number", fieldId: "Phone", fieldType: "text", preview: "true" },

    { fieldName: "Fax", fieldId: "Fax", fieldType: "text", preview: "true" },
  ]


export const fieldsSur =
  [
    { fieldName: "First Name", fieldId: "First_Name", fieldType: "text", preview: "true", nameCombine: "true"},
    { fieldName: "Middle Name", fieldId: "Middle_Name", fieldType: "text"},
    { fieldName: "Last Name", fieldId: "Last_Name", fieldType: "text", nameCombine:"true" },
    { fieldName: "Office Name", fieldId: "Office_Name", fieldType: "text", preview:"true" },
    { fieldName: "Postal Code", fieldId: "Postal_Code", fieldType: "textarea", preview:"true", addyCombine: "true" },
    { fieldName: "City", fieldId: "City", fieldType: "textarea", addyCombine: "true"},
    { fieldName: "Street", fieldId: "Street", fieldType: "textarea", addyCombine: "true" },
    { fieldName: "State/Province", fieldId: "State_Province", fieldType: "textarea", addyCombine: "true"  },
    { fieldName: "Country", fieldId:"Country", fieldType:"textarea", addyCombine: "true" },
    { fieldName: "Email Address", fieldId: "Email_Address", fieldType: "text", preview: "true" },
    { fieldName: "Office Number", fieldId: "Phone", fieldType: "text", preview: "true" },
    { fieldName: "Mobile Number", fieldId: "Phone", fieldType: "text", preview: "true" },

    { fieldName: "Fax", fieldId: "Fax", fieldType: "text", preview: "true" },
  ]

export const fieldsMRI =
  [
    { fieldName: "Center Name", fieldId: "Office_Name", fieldType: "text", preview:"true" },
    { fieldName: "First Name", fieldId: "First_Name", fieldType: "text", preview: "true", nameCombine: "true"},
    { fieldName: "Middle Name", fieldId: "Middle_Name", fieldType: "text"},
    { fieldName: "Last Name", fieldId: "Last_Name", fieldType: "text", nameCombine:"true" },
    { fieldName: "Postal Code", fieldId: "Postal_Code", fieldType: "textarea", preview:"true", addyCombine: "true" },
    { fieldName: "City", fieldId: "City", fieldType: "textarea", addyCombine: "true"},
    { fieldName: "Street", fieldId: "Street", fieldType: "textarea", addyCombine: "true" },
    { fieldName: "State/Province", fieldId: "State_Province", fieldType: "textarea", addyCombine: "true"  },
    { fieldName: "Country", fieldId:"Country", fieldType:"textarea", addyCombine: "true" },
    { fieldName: "Email Address", fieldId: "Email_Address", fieldType: "text", preview: "true" },
    { fieldName: "Center Number", fieldId: "Phone", fieldType: "text", preview: "true" },
    { fieldName: "Mobile Number", fieldId: "Phone", fieldType: "text", preview: "true" },
    
    { fieldName: "Fax", fieldId: "Fax", fieldType: "text", preview: "true" },
  ]



export const optionsSelect = [
  { value: 'distributor', label: "Distributor"},
  { value: 'hospital', label: "Hospital"},
  { value: 'mri_center', label: "MRI Center"}
]

export const alignmentSelect = [
  { value: 'mechanical_alignment', label: "Mechanical Algnment"},
  { value: 'neutral_boundary_alignment', label: "Neutral Boundary Alignment" }
]


/*export const dropdownfields = [
  {
    fieldName: "Shipping Address*",
    fieldId: "Shipping_Address",
    fieldType: "dropdown",
    fieldOptions:  [
      'Hospital Information',
      'TKR 2',
      'Surgeon Office'
    ]
  },
  {
    fieldName: "Billing Address*",
    fieldId: "Billing_Address",
    fieldOptions: [
      'Distributor Information',
      'Hospital',
      'Surgeon Office'
    ]
  },
  {
    fieldName: "Implant Manufacturer and Brand Name*",
    fieldId: "Implant_Manufacturer_and_Brand_Name",
    fieldOptions: [
      'Striker Triathlon(Knee)',
      'Smith & Nephew Genesis 2(Knee)',
      'DJO 3D Knee (Knee)',
      'Biomet Vanguard (Knee)'
    ]
  },
  {
    fieldName: "TKA Alignment*",
    fieldId: "TKA_Alignment",
    fieldOptions: [
      'Mechanical Alignment',
      'Neutral Boundary Alignment'
    ]
  }
]*/
