import React from 'react';
import ProcessorList from './Management/processorlist';
import AdminList from './Management/adminlist';
import ExpertList from './Management/expertlist';
import GCodeList from './Management/gcodelist';
import ManufacturerList from './Management/manufacturerlist';


const UserManagePage = () => {

  return(
    <>
    <ProcessorList />
    <ManufacturerList />
    <ExpertList />
    <GCodeList />
    <AdminList />
    </>
  )
};

export default UserManagePage;
