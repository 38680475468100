export default {

  form: {
    marginLeft: '30%',
    marginRight: '10%',
    maxWidth: '20%',
    backgroundColor: '#efefef',
    borderRadius: 10,
    padding: 10,
    paddingRight: 1,
    paddingBottom: 30,
    marginTop: 10,
  },
  formTitle: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 30,
  },
  albumWrapper: {
    backgroundColor: '#d4d4d4',
    marginBottom: 15,
    borderRadius: 5,
  },
  titleWrapper: {
    padding: 15,
  },
  title: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 0,
    color: '#141d3e'
  },
  body: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formRow: {
    marginBottom: 10,
  },
  rowLabel: {
    display: 'block',
    marginBottom: 2,
    width: '10%'
  },
  textInput: {
    width: '92%',
    wordBreak: 'normal',
    overflow:'auto',
    marginLeft: '10px',
    padding: 5,
    fontSize: 18,
  },
  textLabel: {
    width: '92%',
    marginLeft: '10px',
    padding: 5,
    fontSize: 18,
    color: '#141d3e'
  },
  textareaInput: {
    width: '100%',
    padding: 5,
    fontSize: 18,
    wordBreak: 'normal',
    maxHeight: 100,
    minHeight: 100,
    border: 'none',
  },
  radioOptionRow: {
    display: 'inline-block',
    marginRight: 5,
  }
}
