import React, { memo } from 'react';
import { fields } from './../../mriFormData'

import MRIInput from "./MRIInput";

const MRIBody = memo((props) => {
  const { roleId} = props
  console.log(roleId)
  return (
    <div>
      {fields.map((field) => {
        const { fieldId, fieldName } = field;
        return (
          <div key={fieldId}>
            <label htmlFor={fieldId}>
              {fieldName}
            </label>
            <MRIInput
              {...field}
              roleId={roleId}
              />
          </div>
        )
      })}
    </div>
  )
})

export default MRIBody;
