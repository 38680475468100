import React, { useCallback, useContext } from 'react';
import UploadZone from '../components/mriupload/UploadZone';
import { MRIProvider, MRIContext } from '../components/mriCaseForm/MRIFormContext';
import MRIForm from '../components/mriCaseForm/MRIForm';
import MRICaseId from '../components/mriCaseForm/MRICaseId';
import MRIStatus from '../components/mriCaseForm/MRIStatus';


const MRICasePage = () => {

  return(
    <MRIProvider>
      <div>
        <div className="row" style={{marginBottom: '1srem'}}>
          <div className="col-md-4">
            <MRICaseId />
          </div>
          <div className="col-md-8" style={{marginTop: '1em', fontSize: '2em'}}>
            <div className="label case-status label-primary pull-right">
              <MRIStatus />
            </div>
          </div>
        </div>
        <form method="POST" action="/api/up_mri" className="dropzone" encType="multipart/form-data">
            <UploadZone />
        	<input type='file' name='file' multiple />
        </form>
        <MRIForm />
      </div>
    </MRIProvider>

  )
};

export default MRICasePage;
