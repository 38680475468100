import React, { memo, useContext } from 'react';
import { MRIContext } from './MRIFormContext';



const MRICaseId = (props) => {
  const context = useContext(MRIContext)
  const { formData } = context
  if(formData.case_status){
    const button = formData.case_status.replace("_", " ")
    return(
      <div>
        <span>MRI Upload Ready</span>
      </div>
    )

  }
  return null

}

export default MRICaseId;
