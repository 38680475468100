import React, { useState,  forwardRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import logo from './../../img/lento_logo.jpg';

import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import MaterialTable from 'material-table';
import { Link } from "react-router-dom";

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


const theme = createMuiTheme({
  typography: { h6: {fontSize: '1.4em'}},
});

const MRISurgeonList = () => {
  const [data, setData] = useState({})
  const { token, user, setAuthenticated, setAuthBody } = useContext(AuthorizeContext);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        // url: `https://34.222.155.138/api/surgeon_list?mri=${user.Email_Address}`,
        url: `https://${api_url}/api/surgeon_list?mri=${user.Email_Address}&status=po&base=case`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setLoading(false)
            setData({data: res.data})
          }

        }
      );
    };

    fetchData();
  }, [setAuthBody, setAuthenticated, token, user.Email_Address]);
    console.log(data)

  const [state, setState] = useState({
    columns: [
      { title: 'Last Name', field:'Last_Name', render:rowData => {
          return (
            <div>
            <Link type="text" to={`/surgeoncases/${rowData.Email_Address}`}>Dr. {rowData.Last_Name}</Link>
            </div>
          )}, cellStyle:{ fontSize: '.7em'}},
      { title: 'First Name', field:'First_Name', cellStyle:{ fontSize: '.7em'}},
      { title: 'Middle Name', field:'Middle_Name', cellStyle:{ fontSize: '.7em'}},
      { title: 'Office Name', field:'Office_Name', cellStyle:{ fontSize: '.7em'}},
      { title: 'Office Address', field:'Office_Address', width: '20%', cellStyle:{ fontSize: '.7em'}, render: rowData =>{
        return(
          <div>
            {rowData.Street} [ {rowData.City}, {rowData.Country} ]
          </div>
        )
      }, editable: "never"},
      { title: 'Email Address', field:'Email_Address', cellStyle:{ fontSize: '.7em'}},
      { title: 'Phone', field:'Phone', cellStyle:{ fontSize: '.7em'}},
      { title: 'Fax', field:'Fax', cellStyle:{ fontSize: '.7em'}}
    ]

  });

if(loading){
  return(
    <div id="p5_loading" style={{position:"relative", width: '100%', zIndex: '3',
            height: "100%", left: 0, top: 0, backgroundColor: "white"
          }}>
        <img src={logo} alt="logo"
        style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
        />
    </div>

  )
}
else if(data){
    return(
      <ThemeProvider theme={theme}>
      <MaterialTable
        title="Surgeon List"
        columns={state.columns}
        data={data.data}
        icons={tableIcons}
        options={{
          sorting: true,
          headerStyle: {
            fontSize: '.8em'
          },
          searchFieldStyle: {
            fontSize: '2rem'
          },
          pageSize: 20
        }}
        style={{
          fontSize: '20px'
        }}
        />
      </ThemeProvider>
    );
}};

export default MRISurgeonList;
