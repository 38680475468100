import React, {useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import Sketch from 'react-p5';
import './styles.css';
var img, img1, img2, jmg, pg , y, w, h, originY, xScale, corImg, newOriginX, newOriginY,
mlLength, apLength;
var x = 0;


const CoronalTibiaP5 = (p) => {

  const { formData, setState, step }= useContext(ActiveFormDataContext);
  //const [selected_polygon, setPolygon] = useState({selected_polygon: formData.images.tibia.cor.polygons[formData.selected_implants.tibia_size[0]]})
  const corfemSpacing = Math.abs(formData.images.tibia.cor.spacing[0]);
  const centerPhysicalX = Math.abs(formData.images.tibia.cor.center_physical[0]);
  const centerPhysicalY = Math.abs(formData.images.tibia.cor.center_physical[1]);
  const originX = -(formData.images.tibia.cor.origin[0]) + formData.images.tibia.cor.center_physical[0]
  const originY = -(formData.images.tibia.cor.center_physical[1]) + formData.images.tibia.cor.origin[1]
  const centerCanvasX = Math.abs(formData.images.tibia.cor.origin[0] - formData.images.tibia.cor.center_physical[0]);
  const centerCanvasY = Math.abs(formData.images.tibia.cor.origin[1] - formData.images.tibia.cor.center_physical[1]);
  //let [url, setUrl] = useState(formData.images.femur.cor.urls[0])
  let [corTibAngle, setCorTibAngle] = useState(formData.knee_overview_data.tibia_angles[0]);

  let initial_polygon2 = formData.images.tibia.cor.polygons[formData.selected_implants.tibia_size[0]]
  let selected_polygon2 = []
  let urls = []
  let images = []
  let tibAngle = 0
  // var img, pg;
  // img = p.loadImage(formData.images.tibia.cor.urls[0])
  // console.log("originX :" + originX)
  // console.log("originY :" + originY)
  // console.log("CanvasX :" + centerCanvasX)
  // console.log("CanvasY :" + centerCanvasY)

  if(formData.corTibDimensions){
    mlLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][0]
    apLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][1]
    xScale = Math.abs((1 / corfemSpacing) / 1.5 )

    console.log(xScale)
    let bottomTibia = (15 * corfemSpacing)
  }

  const preload = (p) => {

   //img = p.loadImage(formData.images && formData.images.tibia.cor.urls[1])
    if(formData.images){
     urls.push(formData.images.tibia.cor.urls[0])
     urls.push(formData.images.tibia.cor.urls[1])
     urls.push(formData.images.tibia.cor.urls[2])
   }

   img = p.loadImage(formData.images.tibia.cor.urls[0])

   img1 =p.loadImage(formData.images.tibia.cor.urls[1])

   img2 =p.loadImage(formData.images.tibia.cor.urls[2])

  }


  const setup = (p, corTibia) => {
    setState({corTibDimensions: {height: img.height, width: img.width},
      corTibImgLoaded: true
    });
    p.createCanvas(img.width / 1.5, img.height / 1.5).parent(corTibia);

    p.image(img1, -(centerCanvasX * xScale) + originX,
    (-(centerCanvasY * xScale) + (originY)),
    img.width * corfemSpacing * xScale,
    img.height * corfemSpacing * xScale
  )


    p.noLoop()

  }

  function drawPolygon(p){
      const size = 10
      tibAngle = corTibAngle;
      const radians = (Math.PI / 180) * tibAngle;
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      let nx, ny;
      newOriginX = originX - (originX - 0.75 * mlLength);
      newOriginY = originY - (originY - 5 + formData.images.tibia.cor.center_physical[1] )
      for(let i = 0; i < initial_polygon2.length; i++ ){
        if(initial_polygon2[i] == null) {
             selected_polygon2.push(null)
          }
        else if(initial_polygon2[i]){
          nx =  (cos * (initial_polygon2[i][0])) + (sin * (initial_polygon2[i][1]));
          ny = (cos * (initial_polygon2[i][1])) - (sin * (initial_polygon2[i][0]));
          //copied_polygon1[i][0] = nx;
          //copied_polygon1[i][1] = ny;
          selected_polygon2.push([nx, ny])
        }

      }
      // p.line(originX - size, (originY), originX + size, (originY ));
      // p.line(originX, (originY ) - size, originX, (originY) + size);
      let prev = null;
      let first = null;
      selected_polygon2.forEach(point => {
        if (prev && point) {
          p.line(
              (prev[0] * xScale) + newOriginX * xScale + (formData.corTibHorz ? formData.corTibHorz : 0),
              -(prev[1] * xScale) + (newOriginY * xScale ) + (formData.corTibVert ? formData.corTibVert : 0),
              (point[0] * xScale) + newOriginX * xScale + (formData.corTibHorz ? formData.corTibHorz : 0),
              -(point[1] * xScale) + (newOriginY * xScale) + (formData.corTibVert ? formData.corTibVert : 0)
          ) ;
        } else if (point) {
            first = point;
        } else if (prev) {
          p.line(
              (prev[0] * xScale) + newOriginX * xScale  + (formData.corTibHorz ? formData.corTibHorz : 0),
              -(prev[1] * xScale) + (newOriginY) * xScale + (formData.corTibVert ? formData.corTibVert : 0),
              (first[0] * xScale) + newOriginX * xScale  + (formData.corTibHorz ? formData.corTibHorz : 0),
              -(first[1] * xScale) + (newOriginY) * xScale + (formData.corTibVert ? formData.corTibVert : 0)
          );
        }
        prev = point;
      });
      selected_polygon2 = []
      //p.rect(-26, -26, 52, 52);
  }

  const draw = (p, corTibia) => {
    //console.log(formData.corTibSlide);
    if(!formData.corTibOpen){
      p.pixelDensity(.5)
      p.strokeWeight(2.5)
      p.stroke('#FFFF00');
      let c;
      // if(formData.corTibSlide == 0){
      //   p.image(img, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)
      // }
      // else if(formData.corTibSlide == 1){
      // p.image(img1, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img1.width * corfemSpacing * xScale, img1.height * corfemSpacing * xScale)
      // }
      // else if (formData.corTibSlide == 2){
      //   p.image(img2, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img2.width * corfemSpacing * xScale, img2.height * corfemSpacing * xScale)
      // }
      // else {
      //   p.image(img, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)
      // }

      p.resizeCanvas(img.width/1.5, img.height/1.5)
      p.image(img1, 0, 0, img1.width/1.5, img1.height/1.5)

      //let scaleAP = ((4/3) * mlLength)/ corfemSpacing
      c = p.get(((originX - 0.75 * mlLength)/ corfemSpacing) / 1.5,
                    ((originY - 5 + formData.images.tibia.cor.center_physical[1] )/ corfemSpacing) /1.5,
                    ((1.5 * mlLength)/ corfemSpacing) /1.5,
                    ((1.5 * mlLength)/ corfemSpacing) /1.5
                   )

       p.background('black')
       p.resizeCanvas(300, 300)
       p.background('black')
       p.scale(300/(((1.5 * mlLength)/ corfemSpacing) /1.5))

       p.image(c, 0 , 0)
      drawPolygon(p, corTibia)
      p.noFill()
    }
    }
  //
  // const auto_change_polygon = (p) => {
  //   while (1){
  //   let i = 1;
  //   const angle = 15 * i;
  //   i++;
  //   const radians = (Math.PI / 180) * angle;
  //   const cos = Math.cos(radians);
  //   const sin = Math.sin(radians);
  //   let nx, ny;
  //   for(let i = 0; i < selected_polygon.length; i++ ){
  //     if(selected_polygon[i]){
  //       nx =  (cos * (selected_polygon[i][0])) + (sin * (selected_polygon[i][1]));
  //       ny = (cos * (selected_polygon[i][1])) - (sin * (selected_polygon[i][0]));
  //       selected_polygon[i][0] = nx;
  //       selected_polygon[i][1] = ny;}}
  //     }
  // }
  //
  const keyPressed = (p) => {
    if(!formData.corTibOpen){
      if(p._setupDone){
        p.background('black')
        setCorTibAngle(formData.corTibAngle || 0)

        p.redraw()
      }
    }
    //setUrl(formData.images.femur.cor.urls[2])

  }

  return(
    <>
      <div id="p5_loading" className="corTibia"
      style={{height:`180px`, width:`180px`, marginBottom: '2em',
      marginLeft: ".5em", marginRight: ".5em" , backgroundColor: 'black'}}
      >
      </div>
      <Sketch className="corTibia"
              preload={preload}
              setup={setup} draw={formData.images ? draw : null}
              mouseClicked={keyPressed}
              mouseMoved={keyPressed}
        />
    </>
  )



}

export default CoronalTibiaP5;
