import React, {useMemo, useCallback, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%'
  },
}));


const LoadingSpinner = () => {
  const [open, setOpen] = React.useState(true);

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
      >
        <DialogTitle className="text-center" id="success-title">{"Patient MRI Files Uploading"}</DialogTitle>
        <div className={classes.root}>
          <CircularProgress />
        </div>
        <DialogContent>
          <DialogContentText id="case-description">
            Patient MRI Files Are Processing. Please Wait.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LoadingSpinner;
