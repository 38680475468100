import React, { useContext, useState } from 'react';
import {Table, Button } from 'react-bootstrap';
import { Link, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import MRISurgeonList from './MRI/mriSurgeonList';
import SurgeonCaseList from './Surgeon/patientCasesList';
import SurgeonCreateCasePage from './surgeoncreatecase';

import MRIList from './MRI/mriList';
import MRICasePage from './mricenter';
import { AuthorizeContext } from "../RootContext";


const ReadOnlyHomePage = () => {
  const { user, authBody } = useContext(AuthorizeContext);

  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  let { path, url } = useRouteMatch();


  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  return(
    <>
    <div className="surgeon_main_layout col-lg-9">
      <div className="col-md-8">
      <div>
        <div className="col-md-7">
          <div className="col-md-6">
          <b>User:</b>
          </div>
          <div className="col-md-6">
          {user.Last_Name}, {user.First_Name}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-7">
          <div className="col-md-6">
          <b>Office Name:</b>
          </div>
          <div className="col-md-6">
          {user.Office_Name}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-7">
          <div className="col-md-6">
          <b>Office Address:</b>
          </div>
          <div className="col-md-6">
          {user.Street}
          <br />
          {user.City}, {user.State_Province}
          <br />
          {user.Postal_Code}, {user.Country}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-7">
          <div className="col-md-6">
          <b>Email Address:</b>
          </div>
          <div className="col-md-6">
          {user.Email_Address}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-7">
          <div className="col-md-6">
          <b>Phone Number:</b>
          </div>
          <div className="col-md-6">
          {user.Phone}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-7">
          <div className="col-md-6">
          <b>Fax Number:</b>
          </div>
          <div className="col-md-6">
          {user.Fax}
          </div>
        </div>
      </div>
      </div>
      <div className="col-md-4">
        <div className="row">
          <Link type="button" to={`/active-case-list`} className="btn btn-primary button-info">
          {
            hasRole(profile, ['hospital']) ?
            "SURGERY ORDERS"

            :

            "ACTIVE ORDERS"

          }
          </Link>
        </div>
        <div className="row">
          <Link type="button" to={`/completed-case-list`} className="btn btn-primary button-info">COMPLETED ORDERS</Link>
        </div>
        <div className="row">
          {
            hasRole(profile, ['distributor']) ?
            <Link type="button" to={`/abort-case-list`} className="btn btn-primary button-info">ABORTED ORDERS</Link>

            :

            null

          }
        </div>
      </div>
      <div className="col-md-12" style={{textAlign: 'center', top: '2em'}}>
        <sub
        style={{color: '#888888'}}
        >For any comment, question, or modification request, please contact info@lentomedical.com
        </sub>
      </div>
      <Switch>
      <Route path={`/case/create`} component={SurgeonCreateCasePage}/>
      <Route path={`/surgeonPref/`} component={SurgeonCreateCasePage}/>

      <Route path={`${url}/casesl`} component={SurgeonCaseList} />
      </Switch>
      </div>
      </>

  );
}

export default ReadOnlyHomePage;
