import React, {useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import Sketch from 'react-p5';
import './styles.css';
var img, jmg, pg , y, w, h, originY, xScale, corImg;
var x = 0;
var button;
var width, height;
const KneeAlignmentP5 = (p) => {

  let { formData, femStep, tibStep, setState, setStep }= useContext(ActiveFormDataContext);
  //const [selected_polygon, setPolygon] = useState({selected_polygon: formData.images.tibia.fem.polygons[formData.selected_implants.tibia_size[0]]})
  const spacing = .3125
  let femur_coord, femur_perp, tibia_coord, tibia_perp;

  let femur_head = formData.knee_overview_data.femur_head;
  let ankle_center = formData.knee_overview_data.ankle_center;
  let femur_left_bound = formData.knee_overview_data.femur_left_bound;
  let femur_right_bound = formData.knee_overview_data.femur_right_bound;
  let femur_side = formData.knee_overview_data.femur_side;
  let tibia_left_bound = formData.knee_overview_data.tibia_left_bound;
  let tibia_right_bound = formData.knee_overview_data.tibia_right_bound;
  let central_white_line = formData.knee_overview_data.central_white_line;
  let var_or_val = formData.knee_overview_data.var_or_val;
  let fem_divide_chosen = formData.knee_overview_data.divides[femStep]


  let tib_divide_chosen = formData.knee_overview_data.divides[tibStep]




  if(fem_divide_chosen){

    femur_coord = fem_divide_chosen[`femur_coord`]
    if(femur_coord){
      console.log("hey")
    }

    femur_perp = fem_divide_chosen[`femur_perp`]

    tibia_coord = tib_divide_chosen[`tibia_coord`]
    tibia_perp = tib_divide_chosen[`tibia_perp`]
  }




  const setup = (p, kneeAlign) => {
    width = kneeAlign.offsetWidth
    height = 1850 * spacing
    console.log(width/height)
    setState({correctionAngle: 0})
    setState({femStep: 0})
    setState({tibStep: 0})
    p.createCanvas((ankle_center[1] - femur_head[1]) * 1.2 * .605405 , (ankle_center[1] - femur_head[1]) * 1.2 ).parent(kneeAlign);

  }


  const draw = (p) => {
    p.background(1)
    p.noFill();
    p.stroke('#FFFFFF');
    p.strokeWeight(1.2);

    p.ellipse(femur_head[0], femur_head[1], 50 , 50); //femur_head
    // p.line(central_white_line[0], central_white_line[1], central_white_line[2], central_white_line[3]); //center_line
    p.line(femur_side[0], femur_side[1], femur_head[0], femur_head[1]); //femur_side

    if(formData.slug.includes("R")){
      if(var_or_val === "Valgus"){
        p.line(femur_side[0], femur_side[1], femur_right_bound[0], femur_left_bound[1]);//connect side to femur left_bound
      }
      else{
        p.line(femur_side[0], femur_side[1], femur_left_bound[0], femur_right_bound[1]);//connect side to femur left_bound
      }

    }
    else if(formData.slug.includes("L")){
      if(var_or_val == "Valgus"){
          p.line(femur_side[0], femur_side[1], femur_left_bound[0], femur_left_bound[1]);
      }
      else{
        p.line(femur_side[0], femur_side[1], femur_right_bound[0], femur_left_bound[1]);//connect side to femur right_bound
      }

    }
    p.strokeWeight(.9);
    p.stroke('#A0A0A0');

    p.line(femur_left_bound[0], femur_left_bound[1], femur_head[0], femur_head[1]); //femur_left_bound
    p.line(femur_right_bound[0], femur_right_bound[1], femur_head[0], femur_head[1]); //femur_right_bound

    p.line(tibia_left_bound[0], tibia_left_bound[1], ankle_center[0], ankle_center[1]);//tibia_left_bound
    p.line(tibia_right_bound[0], tibia_right_bound[1], ankle_center[0], ankle_center[1]);//tibia_right_bound
    p.stroke('#FFFFFF')
    p.ellipse(ankle_center[0], ankle_center[1], 5, 5); //ankle_center
    p.strokeWeight(2);
    p.stroke('#FF0000');

    p.line(femur_perp[0], femur_perp[1], femur_perp[2], femur_perp[3]); //decisionFemur
    p.line(femur_coord[0], femur_coord[1], femur_head[0], femur_head[1]);
    // p.ellipse(339, 540, 5, 5);//decisionTibia
    // p.ellipse(341, 540, 5, 5);
    // p.ellipse(343, 540, 5, 5);;



    p.stroke('#FF0000');
    p.line(tibia_coord[0], tibia_coord[1], ankle_center[0], ankle_center[1]); //decisionTibia
    p.line(tibia_perp[0], tibia_perp[1], tibia_perp[2], tibia_perp[3]);





  }
  //
  // const auto_change_polygon = (p) => {
  //   while (1){
  //   let i = 1;
  //   const angle = 15 * i;
  //   i++;
  //   const radians = (Math.PI / 180) * angle;
  //   const cos = Math.cos(radians);
  //   const sin = Math.sin(radians);
  //   let nx, ny;
  //   for(let i = 0; i < selected_polygon.length; i++ ){
  //     if(selected_polygon[i]){
  //       nx =  (cos * (selected_polygon[i][0])) + (sin * (selected_polygon[i][1]));
  //       ny = (cos * (selected_polygon[i][1])) - (sin * (selected_polygon[i][0]));
  //       selected_polygon[i][0] = nx;
  //       selected_polygon[i][1] = ny;}}
  //     }
  // }
  //
  const mouseClicked = (p) => {
  console.log(formData)
    setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep],
              corTibAngle: formData.knee_overview_data.tibia_angles[tibStep]
    })
    p.redraw()
  }


  return(formData.images ?
      <Sketch className="corTibia" setup={setup} draw={draw} mouseClicked={mouseClicked}
        mouseMoved={mouseClicked}
      />
      :
      null
  )

}

export default KneeAlignmentP5;
