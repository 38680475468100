import React, { useState,  forwardRef, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../RootContext";
import { Link, useParams } from 'react-router-dom';
import { api_url } from "../authorize";
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import logo from './../img/lento_logo.jpg';

import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FlagIcon from '@material-ui/icons/Flag';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Flag: forwardRef((props, ref) => <FlagIcon {...props} ref={ref} />),
  };

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      fontSize: '2em'
    }
  });

  const tableStyles = makeStyles({
    root: {justifyContent: 'space-evenly'},
    spacing: {justifyContent: 'space-evenly'}

  });

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

const FlagList = () => {
  const tableClass = tableStyles()
  const { token, role, user, authBody, setAuthenticated, setAuthBody } = useContext(AuthorizeContext);
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true);
  let { surgeonid } = useParams()
  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }
  console.log(data)
  useEffect(() => {
    const fetchData = async () => {
      const admin = await axios({
        url: `https://${api_url}/api/case_list?status=flag`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(res=> {
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
          setLoading(false)
          setState((prevState) => ({
            ...prevState, data: res.data
          }))
        }
      });



    }
    fetchData();
  }, [setState]);

  let lookup = hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ?
    {
      2: 'MRI',
      3: 'MRI QC',
      4: 'Planning 1',
      5: 'Planning 2',
      6: 'Surgeon Approval',
      7: 'Template Design',
      8: 'G Code',
      9: 'CNC',
      10: 'Final QC',
      11: 'Shipped'
    } :

    {
      1: hasRole(profile, ['mri_center']) ? 'Ready to Upload MRI' : 'New Case',
      2: 'In Progress',
      3: 'In Progress',
      4: 'In Progress',
      5: 'In Progress',
      6: 'Approval Ready',
      7: 'Surgeon Approved',
      8: 'Surgeon Approved',
      9: 'Surgeon Approved',
      10: 'Surgeon Approved',
      11: 'Shipped'
    }


  const handleClose = () => {
    setState({...state, dialogFlagOpen: false});
  };

  const handleSlotClose = () => {
    setState({...state, dialogMessageOpen: false});

  }

  const handleSubmit = () => {
    axios({
      url: `https://${api_url}/api/case?case_id=${state.rowData.case_id}&update=remove_flag`,
      method: 'put',
      headers: {
        'x-access-token' : token
      }
    }).then(
      res => {
        if(res.data.message){
          alert(res.data.message)
          setState({...state, dialogFlagOpen: false})
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
        setState({...state, dialogFlagOpen: false})
        window.location.reload()
        }

      }
    );
  }

  const handleAssign = () => {
    axios({
      url: `https://${api_url}/api/assign_command`,
      method: 'post',
      headers: {
        'x-access-token' : token
      }
    }).then(
      res => {
        if(res.data.message){
          alert(res.data.message)
          setState({...state, dialogFlagOpen: false})
        }
        else{
        setState({...state, dialogFlagOpen: false})
        window.location.reload()
        }

      }
    );
  }


  const [state, setState] = useState({
    adminColumns: [
      { title: 'Case #', field:'case_id',  editable: "never", filtering: false, render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )},
          cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", filterting: false, render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name} {rowData.Surgeon.Middle_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", filtering: false, render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'DOPO', field:'dateCreated', editable: "never", filtering: 'false', type:'date',
        cellStyle:{
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.dateCreated).format();
            b = moment(b.dateCreated).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never", filtering: 'false', type: 'date',
        cellStyle: {
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.Patient.Date_of_Surgery).format();
            b = moment(b.Patient.Date_of_Surgery).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Surgery Side', field:'Patient.Side_of_Body', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      {
        title: 'Expedite', field: 'expedite', type: 'boolean', defaultSort: 'asc'
      },
      { title: 'Status', field:'case_status', filtering: 'false',
        lookup: lookup,
        emptyValue: "New Order",
        cellStyle:
          rowData =>
          {
            console.log(rowData)
            return(rowData !== 13 ?
                      ({background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
                        backgroundColor: "#045de9",
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '.7em'})

                        :
                      ({
                        backgroundColor: "#990000",
                        backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)",
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '.7em'
                      }))
          }

      },
      {
        title: 'Flag', field: 'flag_status', filtering: 'false', editable: 'never', type:"text", cellStyle:
        rowData =>
          {
            return(rowData.includes("no_flag") ?
              null

              :
              ({
                  background: "linear-gradient(to right, #ed213a, #93291e)",
                  backgroundColor: "#f46b45",
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '.7em'
              }))
          }
      },
      {
        title: 'Expert Flag', field:'expert_flag', type: 'boolean', editable: 'never'
      },
      {
        title: 'User Assigned', field:'assigned', editable: 'never'
      },
      {
        title: 'Femur Slot', field: 'femur_slot', filtering: 'false', type: "boolean", emptyValue: <Check />,
        cellStyle: {
          textAlign: 'center'
        }
      },
      {
        title: 'Tibia Slot', field: 'tibia_slot', filtering: 'false', type: "boolean", emptyValue: <Check />,
        cellStyle: {
          textAlign: 'center'
        }
      }
    ],

    columns: [
      { title: 'Case #', field:'case_id',  editable: "never", filtering: false, render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )},
          cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", filterting: false, render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name} {rowData.Surgeon.Middle_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", filtering: false, render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'DOPO', field:'dateCreated', editable: "never", filtering: 'false', type:'date',
        cellStyle:{
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.dateCreated).format();
            b = moment(b.dateCreated).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never", filtering: 'false', type: 'date',
        cellStyle: {
          fontSize: '.7em'
        },
          customSort: (a , b) => {
            a = moment(a.Patient.Date_of_Surgery).format();
            b = moment(b.Patient.Date_of_Surgery).format();
            return b > a ? 1 : -1;
          }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Surgery Side', field:'Patient.Side_of_Body', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Status', field:'case_status', filtering: 'false',
        lookup: lookup,
        emptyValue: "New Order",
        cellStyle: {
          background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
          backgroundColor: "#045de9",
          color: 'white',
          textAlign: 'center',
          fontSize: '.7em'
        }
      }
    ]
  });

  const classes = useStyles();
  if(loading){
    return(
      <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
              height: "100%", left: 0, top: 0, backgroundColor: "white"
            }}>
          <img src={logo} alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
          />
      </div>

    )
  }

  return(
    <div>
    <ThemeProvider theme={theme}>
    {hasRole(profile, ['super_admin', 'write_admin']) ? <Button color="primary" variant="contained" onClick={handleAssign}
      style={{margin: '1rem'}}
      >Assign</Button> : null}
    <MaterialTable
      title="Flag List"
      columns={hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ? state.adminColumns : state.columns}
      data={state.data}
      icons={tableIcons}
      actions={
        hasRole(profile, ['super_admin', 'write_admin']) ?
        [{
          icon: tableIcons.Flag,
          tooltip: 'Reset Flag',
          onClick: (event, rowData) => {
            setState({...state, dialogFlagOpen: true, rowData})
        }
        }
        ]
        :
        null
      }
      editable={
        hasRole(profile, ['super_admin', 'write_admin']) ?
        {
        onRowUpdate: (newData, oldData) =>
           new Promise((resolve, reject) => {
             setTimeout(() => {
               {
                 {/*const data = [...data.data];
                 const index = data.indexOf(oldData);
                 data[index] = newData;*/}
                 resolve();
                 const data = [...state.data];
                 const index = data.indexOf(oldData);
                 data[index] = newData;

                console.log(newData)
                if(oldData.femur_slot != newData.femur_slot ){
                 if((newData.femur_slot == true) || (newData.femur_slot == false)){
                     axios({
                       url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=slotSkip&femur=${newData.femur_slot == true ? 1 : 0}`,
                       method: 'put',
                       headers: {
                         'x-access-token' : token
                       }
                     }).then(res=> {
                       if(res.data.message){
                         setState({...state, dialogMessageOpen: true, oldData, error: res.data.message})
                       }

                       setState({...state, data})

                     })
                   }
                 }
                 else if(oldData.tibia_slot != newData.tibia_slot){
                   axios({
                     url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=slotSkip&tibia=${newData.tibia_slot == true ? 1 : 0}`,
                     method: 'put',
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     if(res.data.message){
                       setState({...state, dialogMessageOpen: true, oldData, error: res.data.message})
                     }
                     setState({...state, data})

                   })
                 }
                 else if (oldData.expedite != newData.expedite){
                   console.log("hi")
                   axios({
                     url: `https://${api_url}/api/case?case_id=${oldData.case_id}&update=expedite&expedite=0`,
                     method: 'put',
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     if(res.data.message){
                       setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                       window.location.reload()
                     }
                      setState({...state, data})
                      window.location.reload()
                   })
                 }
                 else if (newData.case_status > oldData.case_status || newData.case_status == 1){
                   reject(new Error(newData))
                   alert("Error: Status will not change. Please choose statuses previous to the current status.")

                 }
                 else if (oldData.case_status == 11){
                   reject(new Error(newData))
                   alert("Error: Status will not change. Order has already been shipped.")
                 }
                 else if(oldData.case_status == newData.case_status){
                   if(oldData == newData){
                     reject(new Error(newData))
                     alert("Error: Status will not change. Status already in progress.")
                   }
                   else{
                    axios({
                      url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=edit_patient`,
                      method: 'put',
                      headers: {
                        'x-access-token' : token
                      },
                      data: newData

                    }).then(res=> {
                      if(res.data.message){
                        console.log(newData)
                        setState({...state, dialogMessageOpen: true, newData, error: res.data.message})
                      }
                      else if(res.data.notLoggedIn){
                        setAuthenticated(false)
                        setAuthBody({role: 'visitor'})
                        alert("You have been logged out due to inactivity. Please log in again.")
                      }
                      else{
                        window.location.reload()
                      }
                    })
                 }
               }
                 else {
                   setState({ ...state, data });
                   console.log(newData)
                   console.log(data)
                   console.log(state)
                   axios({
                     url: `https://${api_url}/api/case?case_id=${newData.case_id}&update=change_case_status&change_status=${newData.case_status}`,
                     method: 'put',
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     console.log("dog");
                     window.location.reload()
                     console.log("dog", res.data)
                   })
                   ;
                 }
               }
               resolve()
             }, 1000);
           })
      }
      :
      null

      }
      localization={{
          pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
          },
          toolbar: {
              nRowsSelected: '{0} row(s) selected'
          },
          header: {
              actions: 'Edit'
          },
          body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                  filterTooltip: 'Filter'
              }
          }
      }}
      options={{
        actionsColumnIndex: 9,
        sorting: true,
        pageSize: 20,
        headerStyle: {
          fontSize: '.8em'
        },
        searchFieldStyle: {
          fontSize: '2rem'
        }
      }}
      style={{
        fontSize: '20px'
      }}
      />
    </ThemeProvider>
    <Dialog open={state.dialogFlagOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span style={{ fontSize: '2em'}}>WARNING: RESET FLAG</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{fontSize: "1.5em"}}>
        Flag will reset for Case: {state.rowData ? state.rowData.case_id : null}.
        { state.rowData ?
          state.rowData.case_status == 2 ?
          " Are you sure you want to reset the flag or RESCAN? If RESCAN, please click case and click RESCAN button."
          :
          " Are you sure you want to reset the flag?"

          :
          null
        }
        </DialogContentText>
      </DialogContent>
      <DialogActions
        disableSpacing
        classes={{
          root: tableClass.root,
          spacing: tableClass.spacing
        }}
      >
        <button className="btn btn-success" onClick={handleSubmit} variant="contained" style={{fontSize: '2em'}}>
          Yes
        </button>
        <button className="btn btn-danger" onClick={handleClose} style={{fontSize: '2em'}}>
          No
        </button>
      </DialogActions>
    </Dialog>
    <Dialog open={state.dialogMessageOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <span style={{ fontSize: '2em'}}>WARNING</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{fontSize: "1.5em"}}>
        {state.oldData ? state.oldData.case_id : null} will not change slot. {state.error ? state.error.replace("_", " ") : null}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSlotClose} color="primary" style={{fontSize: "1em"}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};

export default FlagList;
