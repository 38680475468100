import React, {useState,  useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";

const CaseWarning = () => {
  const [open, setOpen] = useState(true);
  const { token } = useContext(AuthorizeContext);
  const context = useContext(ActiveFormDataContext);
  let { setState, formData } = context;


  const handleClose = () => {
    setState({warningConfirmed: true})
    setOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`WARNING CASE: ${formData.slug}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Your patient has an existing varus/valgus angle in the range of 3.2 - 4.0 degrees.
          Angles in this range may be difficult to address.
          The accuracy of the PST System guides is +/- 1.5 degrees.
          A cumulative error could then exceed 4.0 degrees which is beyond the performance limit of the PST System guides.
          Please call to discuss potential impact and options.
          Phone: +1 (510) 413-7014
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" onClick={handleClose} style={{fontSize: '2em'}}>
              <span className="glyphicon glyphicon-ok"/>
              &nbsp;OK
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CaseWarning;
