import React, { useState,  forwardRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import logo from './../../img/lento_logo.jpg';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import MaterialTable from 'material-table';
import {
  Link
} from "react-router-dom";

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Reset: forwardRef((props, ref) => <RotateLeftIcon {...props} ref={ref} />)
  };

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

const SurgeonList = () => {
  const [data, setData] = useState({});
  const { token, authBody, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState(authBody || {role: "visitor"})

  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }


  const handleClose = () => {
    setState({...state, dialogOpen: false});
  };

  const handleSubmit = () => {
    console.log(state)
    axios({
      url: `https://${api_url}/api/reset_password?login=${state.rowData.Email_Address}`,
      method: 'post',
      headers: {
        'x-access-token' : token
      }
    }).then(
      res => {
        if(res.data.message){
          console.log(res)
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
          setState({...state, dialogOpen: false})
        }
      }
    );
  }


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/surgeon_list?base=pref`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.validation.replace("_", " "))
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setLoading(false)
            setState((prevState) => ({ ...prevState, data: res.data }))
          }
        }
      );

    };

    fetchData();
  }, [setAuthBody, setAuthenticated, token]);


  const [state, setState] = useState({
    adminColumns: [
      { title: 'Last Name', field:'Last_Name', cellStyle:{ fontSize: '.7em'}, render:rowData => {
          return (
            <div>
              <Link type="text" to={`/surgeonPref/${rowData.Email_Address}`}>Dr. {rowData.Last_Name}</Link>
            </div>
          )
        }
      },
      { title: 'First Name', field:'First_Name', cellStyle:{ fontSize: '.7em'}},
      { title: 'Middle Name', field:'Middle_Name', cellStyle:{ fontSize: '.7em'}},
      { title: 'Office Name', field:'Office_Name', cellStyle:{ fontSize: '.7em'}},
      { title: 'Country', field:'Country', cellStyle:{ fontSize: '.7em'}},
      { title: 'City', field:'City', cellStyle:{ fontSize: '.7em'}},
      { title: 'Postal Code', field:'Postal_Code', cellStyle:{ fontSize: '.7em'}},
      { title: 'Street', field:'Street', cellStyle:{ fontSize: '.7em'}},
      { title: 'State/Province', field:'State_Province', cellStyle:{ fontSize: '.7em'}},
      { title: 'Email Address', field:'Email_Address', editable: "never", cellStyle:{ fontSize: '.7em'}},
      { title: 'Phone', field:'Phone', cellStyle:{ fontSize: '.7em'}},
      { title: 'Fax', field:'Fax', cellStyle:{ fontSize: '.7em'}},
      { title: 'Available', field: 'available', type: "boolean"},

    ],
    columns:[
      { title: 'Last Name', field:'Last_Name', cellStyle:{ fontSize: '.7em'}, editable: "never",
        render:rowData => {
          return (
            <div>
              <Link type="text" to={`/surgeoncases/${rowData.Email_Address}`}>Dr. {rowData.Last_Name}</Link>
            </div>
          )
        }
      },
      { title: 'First Name', field:'First_Name', cellStyle:{ fontSize: '.7em'}, editable: "never"},
      { title: 'Middle Name', field:'Middle_Name', cellStyle:{ fontSize: '.7em'}, editable: "never"},
      { title: 'Office Name', field:'Office_Name', render:rowData => {
        return (
          <div>
            <Link type="text" to={`/surgeoncases/${rowData.Email_Address}`}>{rowData.Office_Name}</Link>
          </div>
        )
      }, cellStyle:{ fontSize: '.7em'}, editable: "never"},
      { title: 'Office Address', field:'Office_Address', cellStyle:{ fontSize: '.7em'}, render: rowData =>{
        return(
          <div>
            {rowData.Street} [ {rowData.City}, {rowData.Country} ]
          </div>
        )
      }, editable: "never"},
      { title: 'Email Address', field:'Email_Address', cellStyle:{ fontSize: '.7em'}, editable: "never"},
      { title: 'Phone', field:'Phone', cellStyle:{ fontSize: '.7em'}, editable: "never"},
      { title: 'Fax', field:'Fax', cellStyle:{ fontSize: '.7em'}, editable: "never"},
      { title: 'Available', field: 'available', type: "boolean"},

    ],

  });
if (loading){
  return(
    <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
            height: "100%", left: 0, top: 0, backgroundColor: "white"
          }}>
        <img src={logo} alt="logo"
        style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
        />
    </div>

  )
}
else if(data){
    return(
    <>
    <ThemeProvider theme={theme}>

      <MaterialTable
        title="Surgeon List"
        columns={hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ? state.adminColumns : state.columns}
        data={state.data}
        actions={
        hasRole(profile, ['super_admin']) ? [{
          icon: tableIcons.Reset,
          tooltip: 'Reset Password',
          onClick: (event, rowData) => {
            setState({...state, dialogOpen: true, rowData})
          }
          }]

          :

          null
        }
        icons={tableIcons}
        editable={
          hasRole(profile, ['super_admin']) ?
          {
          onRowUpdate: (newData, oldData) =>
             new Promise((resolve, reject) => {
               setTimeout(() => {
                 {
                   const data = [...state.data];
                   const index = data.indexOf(oldData);
                   data[index] = newData;
                   axios({
                     url: `https://${api_url}/api/surgeon?email=${oldData.Email_Address}`,
                     method: 'put',
                     data: newData,
                     headers: {
                       'x-access-token' : token
                     }
                   }).then(res=> {
                     if(res.data.message){
                       alert(res.data.message)
                     }
                     else if(res.data.validation){
                       alert(res.data.validation.replace("_", " "))
                     }
                     else if(res.data.notLoggedIn){
                       setAuthenticated(false)
                       setAuthBody({role: 'visitor'})
                       alert("You have been logged out due to inactivity. Please log in again.")
                     }
                     else{
                      setState({...state, data})
                     }
                   })
                 }
                 resolve();
               }, 1000);
             })
           }
           :
           null
         }

        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: 'Edit'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        options={{
          sorting: true,
          headerStyle: {
            fontSize: '.8em'
          },
          searchFieldStyle: {
            fontSize: '2rem'
          },
          pageSize: 20
        }}
        style={{
          fontSize: '20px'
        }}
        />
    </ThemeProvider>
    <Dialog open={state.dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">WARNING: RESET PASSWORD</DialogTitle>
      <DialogContent>
        <DialogContentText>
        Reset password will change for Dr.{state.rowData ? state.rowData.Last_Name : null}.
        Are you sure you want to reset this surgeon's password?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
      </>
    );
}};

export default SurgeonList;
