import React, {useState,  useContext, useParams} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';


import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";


const theme = createMuiTheme({
  typography: { h6: {fontSize: '1.4em'},
                body1: {fontSize: '1em'},
  button: {fontSize: '3em'}
  },
});

const CaseDanger = () => {
  const [open, setOpen] = useState(true);
  const { token } = useContext(AuthorizeContext);
  const context = useContext(ActiveFormDataContext);
  let { setState, formData } = context;
  const handleClickOpen = () => {
    setOpen(true);
  };
  let femur_chosen, tibia_chosen, flag_code;

  if(formData.knee_overview_data){
    femur_chosen = formData.knee_overview_data.femur_angles[formData.femStep ? formData.femStep: 0]
    tibia_chosen = formData.knee_overview_data.tibia_angles[formData.tibStep ? formData.tibStep : 0]
    flag_code = formData.knee_overview_data.flag
  }


  const handleClose = () => {
    setState({dangerConfirmed: true})
    setOpen(false)
  }


  switch (flag_code){
    case 8:
      return (
        <>
        <ThemeProvider theme={theme}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`WARNING CASE:${formData.slug}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              Based on the MRI information supplied for Case: {formData.slug}, the MRI revealed significant, large damage to the knee.
              Lento Innovation may not be able to design surgical guides for this case.
              Cancelation or other options may be available.
              Please e-mail the clinical department regarding this issue at info@lentomedical.com and provide the best available time to call.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
        </>
      )
    case 10:
      return(
        <>
        <ThemeProvider theme={theme}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`WARNING CASE:${formData.slug}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Based on the MRI information supplied for case #{formData.slug}.
                It may be impossible to design Lento Innovation guides for this case.
                Cancelation or other options may be available.
                Please contact the clinical department at the following phone number:
                +1 (510) 413-7014 to discuss this case.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
        </>
      )
      case 11:
        return(
          <>
          <ThemeProvider theme={theme}>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{`WARNING CASE:${formData.slug}`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Based on the MRI information supplied for case #{formData.slug}.
                  It may be impossible to design Lento Innovation guides for this case.
                  Cancelation or other options may be available.
                  Please contact the clinical department at the following phone number:
                  +1 (510) 413-7014 to discuss this case.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <button className="btn btn-primary" onClick={handleClose} style={{fontSize: '2em'}}>
                    <span className="glyphicon glyphicon-ok"/>
                    &nbsp;OK
                </button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
          </>
        )
      default:
      return flag_code
  }

}

export default CaseDanger;
