import React, {useMemo, useCallback, useState, useContext, useEffect, useRef } from 'react';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import Sketch from 'react-p5';
import './styles.css';
import logo from './../../img/lento_logo.jpg';

var img, img1, img2, jmg, pg , y, w, h, originY, xScale, corImg, newOriginX, newOriginY,
apLength, mlLength;
var x = 0;


const SagittalTibiaP5 = (p) => {

  const { formData, setState, step }= useContext(ActiveFormDataContext);
  //const [selected_polygon, setPolygon] = useState({selected_polygon: formData.images.tibia.cor.polygons[formData.selected_implants.tibia_size[0]]})
  const corfemSpacing = Math.abs(formData.images.tibia.sag.spacing[0]);
  const centerPhysicalX = Math.abs(formData.images.tibia.sag.center_physical[0]);
  const centerPhysicalY = Math.abs(formData.images.tibia.sag.center_physical[1]);
  const originX = -(formData.images.tibia.sag.origin[0]) + formData.images.tibia.sag.center_physical[0]
  const originY = -(formData.images.tibia.sag.center_physical[1]) + formData.images.tibia.sag.origin[1]
  const centerCanvasX = Math.abs(formData.images.tibia.sag.origin[0] - formData.images.tibia.sag.center_physical[0]);
  const centerCanvasY = Math.abs(formData.images.tibia.sag.origin[1] - formData.images.tibia.sag.center_physical[1]);
  //let [url, setUrl] = useState(formData.images.femur.cor.urls[0])
  let initial_polygon2 = formData.images.tibia.sag.polygons[formData.selected_implants.tibia_size[0]]
  let selected_polygon2 = []
  let urls = []
  let images = []
  let tibAngle = 20
  // var img, pg;
  // img = p.loadImage(formData.images.tibia.cor.urls[0])
  // console.log("originX :" + originX)
  // console.log("originY :" + originY)
  // console.log("CanvasX :" + centerCanvasX)
  // console.log("CanvasY :" + centerCanvasY)

  if(formData.sagTibDimensions){
    mlLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][0]
    apLength = formData.images.femur.MLAP_length[formData.selected_implants.femur_size[0]][1]
    xScale = Math.abs((1 / corfemSpacing) / 1.5 )

    console.log(xScale)
    let bottomTibia = (15 * corfemSpacing)
  }

  const preload = (p) => {

   //img = p.loadImage(formData.images && formData.images.tibia.cor.urls[1])
    if(formData.images){
     urls.push(formData.images.tibia.sag.urls[0])
     urls.push(formData.images.tibia.sag.urls[1])
     urls.push(formData.images.tibia.sag.urls[2])
   }

   img = p.loadImage(formData.images.tibia.sag.urls[0])

   img1 =p.loadImage(formData.images.tibia.sag.urls[1])

   img2 =p.loadImage(formData.images.tibia.sag.urls[2])

  }


  const setup = (p, sagTibia) => {
    setState({sagTibDimensions: {height: img.height, width: img.width},
        sagTibImgLoaded: true
    });
    p.createCanvas(img.width / 1.5, img.height / 1.5).parent(sagTibia);

  //   p.image(img1, -(centerCanvasX * xScale) + originX,
  //   (-(centerCanvasY * xScale) + (originY)),
  //   img.width * corfemSpacing * xScale,
  //   img.height * corfemSpacing * xScale
  // )

  p.image(img1, -(centerCanvasX * xScale) + originX,
  (-(centerCanvasY * xScale) + (originY)),
  img.width * corfemSpacing * xScale,
  img.height * corfemSpacing * xScale
)
  // p.rotate(p.radians(25))

    p.noLoop()

  }

  function drawPolygon(p){
      const size = 10
      tibAngle = 0;
      const radians = (Math.PI / 180) * tibAngle;
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      let nx, ny;
      newOriginX = originX - (originX - 0.75 * apLength);
      console.log("OriginX: ", originX)
      console.log(xScale)
      newOriginY = originY - (originY - 5 + formData.images.tibia.sag.center_physical[1] )
      console.log("OriginY: ", originY)
      for(let i = 0; i < initial_polygon2.length; i++ ){
        if(initial_polygon2[i] == null) {
             selected_polygon2.push(null)
          }
        else if(initial_polygon2[i]){
          nx =  (cos * (initial_polygon2[i][0])) + (sin * (initial_polygon2[i][1]));
          ny = (cos * (initial_polygon2[i][1])) - (sin * (initial_polygon2[i][0]));
          //copied_polygon1[i][0] = nx;
          //copied_polygon1[i][1] = ny;
          selected_polygon2.push([nx, ny])
        }

      }
      // p.line(originX - size, (originY), originX + size, (originY ));
      // p.line(originX, (originY ) - size, originX, (originY) + size);
      let prev = null;
      let first = null;
      selected_polygon2.forEach(point => {
        if (prev && point) {
          p.line(
              (prev[0] * xScale) + newOriginX * xScale + (formData.sagTibHorz ? formData.sagTibHorz : 0),
              -(prev[1] * xScale) + (newOriginY * xScale ) + (formData.sagTibVert ? formData.sagTibVert : 0),
              (point[0] * xScale) + newOriginX * xScale + (formData.sagTibHorz ? formData.sagTibHorz : 0),
              -(point[1] * xScale) + (newOriginY * xScale) + (formData.sagTibVert ? formData.sagTibVert : 0)
          ) ;

        } else if (point) {
            first = point;
        } else if (prev) {
          p.line(
              (prev[0] * xScale) + newOriginX * xScale  + (formData.sagTibHorz ? formData.sagTibHorz : 0),
              -(prev[1] * xScale) + (newOriginY) * xScale + (formData.sagTibVert ? formData.sagTibVert : 0),
              (first[0] * xScale) + newOriginX * xScale  + (formData.sagTibHorz ? formData.sagTibHorz : 0),
              -(first[1] * xScale) + (newOriginY) * xScale + (formData.sagTibVert ? formData.sagTibVert : 0)
          );

        }
        prev = point;
      });
      selected_polygon2 = []
      //p.rect(-26, -26, 52, 52);
  }

  const draw = (p, sagTibia) => {
    //console.log(formData.corTibSlide);
    if(!formData.sagTibOpen){
      p.stroke('#FFFF00');
      p.strokeWeight(1)
      //p.strokeWeight(1)`

      let c;
      // if(formData.sagTibSlide == 0){
      //   p.image(img, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)
      // }
      // else if(formData.sagTibSlide == 1){
      // p.image(img1, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img1.width * corfemSpacing * xScale, img1.height * corfemSpacing * xScale)
      // }
      // else if (formData.sagTibSlide == 2){
      //   p.image(img2, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img2.width * corfemSpacing * xScale, img2.height * corfemSpacing * xScale)
      // }
      // else {
      //   p.image(img, -(centerCanvasX * xScale) + originX, (-(centerCanvasY * xScale) + (originY)), img.width * corfemSpacing * xScale, img.height * corfemSpacing * xScale)
      // }

      p.resizeCanvas(img.width/1.5, img.height/1.5)
      // p.resizeCanvas(img.width * 2, img.height *2)


      // p.translate((-(centerCanvasX * xScale) + originX)/1.5, ((-(centerCanvasY * xScale) + (originY)))/1.5)
    //   p.translate(-(originX - (originX - 0.75 * apLength)) * xScale,
    //   -(originY - (originY - 5 + formData.images.tibia.sag.center_physical[1] )) * xScale
    // )

      console.log(newOriginX)
      console.log(newOriginY)
      p.rotate(p.radians(0))
      // p.translate(-(-(centerCanvasX * xScale) + originX)/1.5, (-(-(centerCanvasY * xScale) + (originY)))/1.5)

      // p.translate(100, 10)
      // p.translate((originX - (originX - 0.75 * apLength)) * xScale,
      // (originY - (originY - 5 + formData.images.tibia.sag.center_physical[1] )) * xScale
    // )
      p.image(img1, 0, 0, img1.width/1.5, img1.height/1.5)
      // p.image(img1, 0, 0, img1.width * .5, img1.height * .5)

      //let scaleAP = ((4/3) * apLength)/ corfemSpacing

      c = p.get(((originX - 0.75 * apLength)/ corfemSpacing) / 1.5,
                    ((originY - 5 + formData.images.tibia.sag.center_physical[1] )/ corfemSpacing) /1.5,
                    ((1.5 * apLength)/ corfemSpacing) /1.5,
                    ((1.5 * apLength)/ corfemSpacing) /1.5
                   )
      // p.rotate(p.radians(6))
       p.background('black')
       // p.resizeCanvas(300, 300)
       p.resizeCanvas(300, 300)
       p.background('black')
       p.scale(300/(((1.5 * apLength)/ corfemSpacing) /1.5))

       p.image(c, 0 , 0)
       p.translate(0, 0)
       p.rotate(p.radians(0))

      drawPolygon(p, sagTibia)
      p.noFill()
    }
    }
  //
  // const auto_change_polygon = (p) => {
  //   while (1){
  //   let i = 1;
  //   const angle = 15 * i;
  //   i++;
  //   const radians = (Math.PI / 180) * angle;
  //   const cos = Math.cos(radians);
  //   const sin = Math.sin(radians);
  //   let nx, ny;
  //   for(let i = 0; i < selected_polygon.length; i++ ){
  //     if(selected_polygon[i]){
  //       nx =  (cos * (selected_polygon[i][0])) + (sin * (selected_polygon[i][1]));
  //       ny = (cos * (selected_polygon[i][1])) - (sin * (selected_polygon[i][0]));
  //       selected_polygon[i][0] = nx;
  //       selected_polygon[i][1] = ny;}}
  //     }
  // }
  //
  const keyPressed = (p) => {
    if(!formData.sagTibOpen){
      if(p._setupDone){
        p.background('black')
        p.redraw()
      }
    }
    //setUrl(formData.images.femur.cor.urls[2])

  }

  return(
    <>
      <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
        height: "100%", left: 0, top: 0, backgroundColor: "white"
      }}><img src={logo} alt="logo"
      style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
      /></div>
    <Sketch className="sagTibia" preload={preload} setup={setup} draw={draw} mouseClicked={keyPressed} />
    </>
  )



}

export default SagittalTibiaP5;
