import React, { memo } from 'react';
import { fields, fieldsSur, fieldsDis, fieldsHos, fieldsMRI } from './../../createFormData';

import CreateInput from "./CreateInput";

const CreateBody = memo((props) => {
  const prefieldsDis = fieldsDis
  const prefieldsSur = fieldsSur.filter(field => field.preview === "true")
  const prefieldsHos = fieldsHos.filter(field => field.preview === "true")
  const prefieldsMRI = fieldsMRI.filter(field => field.preview === "true")


  const { roleId, fieldId } = props
  switch(roleId){
    case "Surgeon":
      return (
        <div>
          {
            prefieldsSur.map((field) => {
            const { fieldId, fieldName } = field;

            return (
              <div key={fieldId}>
                {
                  fieldId.includes("First_Name") ?
                  <label>
                  Name
                  </label>
                  :
                  fieldId.includes("Postal_Code") ?
                  <label>
                  Address
                  </label>

                  :

                  <label htmlFor={fieldId}>

                  {fieldName}
                  </label>
                }
                <CreateInput
                  {...field}
                  roleId={roleId}
                  />
              </div>
            )

          })}
        </div>
      )
    case "Distributor":
      return (
        <div>
          {prefieldsDis.map((field) => {
            const { fieldId, fieldName, onlyShow } = field;
            return (
              <div key={fieldId}>
                {
                  fieldId.includes("First_Name") ?
                  <label>
                  Contact Person
                  </label>

                  :

                  fieldId.includes("Postal_Code") ?
                  <label>
                  Address
                  </label>

                  :

                  !onlyShow ?
                  null

                  :

                  <label htmlFor={fieldId}>

                  {fieldName}
                  </label>
                }

                <CreateInput
                {...field}
                roleId={roleId}
                />

              </div>
            )
          })}
        </div>
      )
    case "Hospital":
      return (
        <div>
          {prefieldsHos.map((field) => {
            const { fieldId, fieldName } = field;
            return (
              <div key={fieldId}>
                {
                  fieldId.includes("First_Name") ?
                  <label>
                  Contact Person
                  </label>
                  :
                  fieldId.includes("Postal_Code") ?
                  <label>
                  Address
                  </label>
                  :
                  <label htmlFor={fieldId}>

                  {fieldName}
                  </label>
                }
                <CreateInput
                  {...field}
                  roleId={roleId}
                  />
              </div>
            )
          })}
        </div>
      )
    case "MRI_Center":
      return(
        <div>
          {prefieldsMRI.map((field) => {
            const { fieldId, fieldName } = field;
            return (
              <div key={fieldId}>
                {
                  fieldId.includes("First_Name") ?
                  <label>
                  Contact Person
                  </label>
                  :
                  fieldId.includes("Postal_Code") ?
                  <label>
                  Address
                  </label>
                  :
                  <label htmlFor={fieldId}>

                  {fieldName}
                  </label>
                }
                <CreateInput
                  {...field}
                  roleId={roleId}
                  />
              </div>
            )
          })}
        </div>
      )
    default:
      return (
        null
      )
  }
})

export default CreateBody;
