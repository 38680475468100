import React, {useMemo, useState } from 'react';
import { roles, fields, bodyPrefs } from './../../createFormData';
export const CaseFormDataContext = React.createContext();

const computeInitialState = () => {
  const data = {}
  for (let i=0; i < roles.length; i++) {
    for(let j=0; j < fields.length; j++) {
        data[`${roles[i].roleId}_${fields[j].fieldId}`] = ''
      }
  }
  return data
}


export const CaseFormDataProvider = (props) => {
  const formData = useMemo(
    () => computeInitialState(), [roles, fields]
  )
  const [state, setState] = useState({ formData });
  const [step, setStep] = useState(1)
  console.log(state)


  const prevStep = () => {
    const { step } = state
    setStep(step => step - 1)
  }

  const nextStep = () => {
    const { step } = state
    setStep(step => step + 1)
  }
  const handleSetState = (object) => {
    const { formData, surgeryType, surgeryPref, step, prevStep } = state
    setState({ formData: {...formData, ...object, Billing_Address: "",
              Shipping_Address: "",
              Pref_Alignment: "", Manufacturer_Continent: ""
            }, setState })
  }


  const value = {...state, step, setState: handleSetState, prevStep, nextStep }
  console.log(value)
  return <CaseFormDataContext.Provider value={value}>{props.children}</CaseFormDataContext.Provider>
}
