import React, { useState, useContext, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import CoronalFemurP5 from './CoronalFemurP5';
import CoronalFemurP5Modal from './CoronalFemurP5Modal';
import corImg  from "./imgs/FEMUR_CORONAL.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%',
  },
  ripple: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 40,
    height: 40,
    marginBottom: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#158ac7",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 40,
      height: 40,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#232ab3"
    }
  }
}));



const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      color="primary"
      className="d-flex"
      style={{
              postion: "absolute",
              bottom: "0px",
              left: "195px",
              fontSize: '1em'
             }}
      onClick={onClick}

    >Next</Button>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  const context = useContext(ActiveFormDataContext);
  const { setState, formData } = context;

  return (
    <div style={{position:"relative"}}>
      <Button
        color="primary"
        style={{
          position: "absolute",
          top: "134px",
          left: "16px",
          fontSize: '1em'
        }}
        onClick={(e) => {
          onClick();

        }}
      >Prev</Button>
    </div>
  );
}


var initialFemStep;

const CoronalFemurImage = () => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const slider = useRef()
  const nextSlide = useRef()
  const context = useContext(ActiveFormDataContext);
  const { setState, formData, femStep, nextFemStep, prevFemStep, setFemStep,
          resetFemTibStep, tibStep, customRotate, toggleRotate, setCorFemVert,
          corFemTransUp, corFemTransDown, corFemVert, corFemHorz, corFemHorzLeft, corFemHorzRight
         } = context;
  const { authBody } = useContext(AuthorizeContext);

  const [color, setColor] = useState([Math.random()*255, Math.random()*255, Math.random()*255])
  let [corFemY, setCorFemY] = useState(0);
  let [corFemX, setCorFemX] = useState(0)
  const [profile, setProfile] = useState(authBody)
  const [customFemStep, setCustomFemStep] = useState(femStep)
  const classes = useStyles();
  let ratio, height, absFem, femur_decision_angle, tibia_decision_angle, patient_fem_angle, var_or_val, alignment;
  //
  // if(formData.knee_overview_data && !open){
  //   initialFemAngle = formData.knee_overview_data.femur_angles[femStep]
  //   console.log(initialFemAngle)
  // }

  if(!open){
    initialFemStep = femStep
  }


  useEffect(() => {
    setState(corFemVert => ({...corFemVert, vert: corFemY})
    )
  }
  , [corFemY]);

  useEffect(() => {
    setState(corFemHorz => ({...corFemHorz, horz: corFemX}))
  }, [corFemX])



  const handleClickOpen = () => {
    setState({corFemOpen: true, corFemSlide: 1})
    setOpen(true);
  };

  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }


  const handleClose = () => {
    setFemStep(initialFemStep)
    setState({correctionFemAngle: Number((formData.knee_overview_data.femur_angles[initialFemStep]).toFixed(1)),
        corFemSlide: 1
    })

    setState({femStep: femStep})
    toggleRotate()
    setState({corFemOpen: false})
    setOpen(false);
  };

  const handleAccept = () => {
    setState({
      corFemAngle: formData.knee_overview_data.femur_angles[femStep],
      corFemOpen: false,
      rotate: true,
      corRotate: true
    })
    setOpen(false)
  }



  if(formData.decision_json){
    tibia_decision_angle = Number(formData.decision_json['Vv:Tibia:decision.angle.degree'])
    femur_decision_angle = Number(formData.decision_json['Vv:Femur:decision.angle.degree'])
    patient_fem_angle = Math.abs(Number(femur_decision_angle).toFixed(1))
    alignment = formData.knee_overview_data.default_alignment
    var_or_val = formData.knee_overview_data.var_or_val

  }


  if (formData.corFemurDimensions) {
   ratio = formData.corFemurDimensions.height / formData.corFemurDimensions.width
   height = Math.trunc(124 * ratio)
  }

  console.log(`Ratio: ${ratio}, Height: ${height}`)
  const handleColor = () => {
    setColor([Math.random()*255, Math.random()*255, Math.random()*255])
  }

  const rotateRight = () => {
    if(formData.slug.includes("R") && var_or_val.includes("Varus")||
       formData.slug.includes("L") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Valgus") && alignment == "N"){
        if(femStep > 0){
          prevFemStep(femStep - 1);

          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }

      }
      else if(var_or_val.includes("Varus") && alignment == "N"){
        if(femStep > 0){
          prevFemStep(femStep - 1);
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
      }
      else{
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
          nextFemStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
          setState({correctionAngle: 0})
        }
      }

    }
    else if(formData.slug.includes("L") && var_or_val.includes("Varus") ||
            formData.slug.includes("R") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Varus") && alignment === "N" ||
        var_or_val.includes("Valgus") && alignment === "N"
      ){
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
          nextFemStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
          setState({correctionAngle: 0})
        }
      }
      else {
        if(femStep > 0){
          prevFemStep(femStep - 1);
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
      }
    }
  }

  const rotateLeft = () => {
    if(formData.slug.includes("R") && var_or_val.includes("Varus")||
       formData.slug.includes("L") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Valgus") && alignment == "N"){
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
          nextFemStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
      }
      else if(var_or_val.includes("Varus") && alignment == "N"){
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
          nextFemStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
      }
      else{
        if(femStep > 0){
          prevFemStep(femStep - 1);
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
        }
      }

    }
    else if(formData.slug.includes("L") && var_or_val.includes("Varus") ||
            formData.slug.includes("R") && var_or_val.includes("Valgus")
    ){
      if(var_or_val.includes("Varus") && alignment === "N" ||
        var_or_val.includes("Valgus") && alignment === "N"
      ){
        if(femStep > 0){
          prevFemStep(femStep - 1);
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})


        }
      }
      else{
        if(femStep < formData.knee_overview_data.divide_count + 1){
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

          nextFemStep();
        }
        else{
          setState({corFemAngle: formData.knee_overview_data.femur_angles[femStep]})

        }
      }
    }

  }



  const translateUp = () => {
    if(corFemY >= -2){
      setCorFemY(corFemY - 0.5)
      setState({vert: corFemY - 0.5})
      console.log("corFemY" + corFemY)
    }
  }

  const translateDown = () => {
    if(corFemY <= 2){
      setCorFemY(corFemY + 1)
      console.log(corFemY)
      setState({vert: corFemY + 0.5})
    }

  }

  const translateLeft = () =>{
    if(corFemX >= -2){
      setCorFemX(corFemX - 0.5)
      setState({horz: corFemX - 0.5})
    }
  }

  const translateRight = () => {
    if(corFemX <= 2){
      setCorFemX(corFemX + 0.5)
      setState({horz: corFemX + 0.5})
    }
  }

  const resetFem = () => {
    setFemStep(initialFemStep)
    setState({correctionFemAngle: Number((formData.knee_overview_data.femur_angles[initialFemStep]).toFixed(1))});
    setCorFemX(0)
    setCorFemY(0)
    setState({femStep: femStep, vert: 0, horz: 0})
    toggleRotate()
  }


  console.log("ok")
  const settings = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    fade: true,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    afterChange: (current) =>
      setState({ corFemSlide: current, prevSlide: formData.corFemSlide })
  };

  const next = () => {
    console.log(slider)
    slider && slider.current.slickNext()
  }

  const handleSlide = (e) => {
    console.log("hi")
  }

  return (
    <>
    <div
        style={{height:`180px !important`, width: `180px !important`, marginBottom: "2em",
        marginLeft: ".5em", marginRight: ".5em"}}
        className="corFemur"
        onClick={handleClickOpen}
        >
        <CoronalFemurP5 />
    </div>

    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
        fullWidth={true}
        maxWidth={'md'}
        style={{display: "block", paddingLeft:"17px"}}
      >
        <DialogTitle className="text-center" id="success-title">
          <span style={{ fontSize: '2.6em'}}>{"Coronal Femur"}</span>
        </DialogTitle>
        <div>
          <div className="col-md-7 bg-dark p-1" style={{flexGrow: 5, color: 'white'}}>
            <div className=" d-flex justify-content-center">
            <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>M E D I A L</span>
              <div className="d-flex flex-column w-100" >
                <div className="labelbox text-center">
                  <span >S U P E R I O R</span>
                </div>
                <div className="labelbox text-center">
                  <div className="mt-5 mb-5">
                  <div className="corFemurModal">
                  <CoronalFemurP5Modal />
                  </div>
                  </div>
                  <span>I N F E R I O R</span>
                </div>
              </div>
              <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>L A T E R A L</span>
            </div>
          </div>
          <div className="col-md-4 ml-2">
            <div className="ml-4">
              <img src={corImg} alt="sag-Left" />
              <RadioGroup>
                <div
                     style={{marginLeft: '5em'}}
                >
                  <Radio
                    value={"0"}
                    className={classes.ripple}
                    color="default"
                    disableRipple
                    checked={formData.corFemSlide === "0"}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => setState({corFemSlide: e.target.value})}
                  />
                  <Radio
                  value={"1"}
                  color="default"
                  disableRipple

                  className={classes.ripple}
                  checked={(formData.corFemSlide === 1) || formData.corFemSlide === undefined ||
                    (formData.corFemSlide === "1")
                  }
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({corFemSlide: e.target.value})}
                  />

                  <Radio
                  value={"2"}
                  color="default"
                  disableRipple
                  className={classes.ripple}
                  checked={formData.corFemSlide === "2"}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({corFemSlide: e.target.value})}
                  />
                </div>
              </RadioGroup>
              {/*<Slider {...settings}
              >
                {formData.images && formData.images.femur.sag.urls.map(img => {
                  return(
                    <div key={img}>
                      <img src={img} style={{height: "175px", width: "85%", maxWidth: "350px", marginLeft: "auto", marginRight:"auto"}}/>
                    </div>
                  )
                })}
              </Slider>*/}
            </div>
            <div className="text-nowrap pt-3"  style={{fontSize: '1.5em', marginTop: '.5em'}} >
              <div>Patient Angle:
                <span className="font-weight-bold"
                  style={{color: '#005a9b'}}>
                {` ${patient_fem_angle}`} degrees {var_or_val}
                </span>
              </div>
              <div>Correction Angle:
              <span className="font-weight-bold"
                style={{color: '#f08902'}}>
              {` ${Math.abs(Number((formData.knee_overview_data.femur_angles[femStep]).toFixed(1)))}`} degrees {var_or_val == "Varus" ? "Valgus" : "Varus"}
              </span>
              </div>
              <div>Femur Angle:
              <span className="font-weight-bold"
                style={{color: '#2e8b57'}}>
              {`
                ${Number(patient_fem_angle -
                  Math.abs(Number((formData.knee_overview_data.femur_angles[femStep]).toFixed(1)))
                ).toFixed(1)}
                `
              } degrees  {var_or_val}
              </span>
              </div>
            </div>
          </div>
        </div>
        <DialogContent>
        <div className="col-md-4">
          <div className="row justify-content-center">
            <Button bsStyle="primary"
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
                    onClick={translateUp}>
              <span className="glyphicon glyphicon-chevron-up"/>
            </Button>
          </div>
          <div className="row justify-content-center">
            <Button bsStyle="primary"
                    onClick={translateLeft}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span className="glyphicon glyphicon-chevron-left"/>
            </Button>
            <Button bsStyle="primary"
                    onClick={translateDown}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span className="glyphicon glyphicon-chevron-down"/>
            </Button>
            <Button bsStyle="primary"
                    onClick={translateRight}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span className="glyphicon glyphicon-chevron-right"/>
            </Button>
          </div>
        </div>
        {
          <div className="col-md-3">
            <div className="col-md-4">
            <Button className="float-left"
                    bsStyle="primary"
                    onClick={rotateLeft}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span>
                 <RotateRightIcon fontSize="large" />
              </span>
            </Button>
            </div>
            <p className="col-md-4">Rotate</p>
            <div className="col-md-4">
            <Button className="float-right"
                    bsStyle="primary"
                    onClick={rotateRight}
                    disabled={hasRole(profile, ['write_admin', 'read_admin'])}
            >
              <span>
                 <RotateLeftIcon fontSize="large" />
              </span>
            </Button>
            </div>
          </div>
        }
        <div className="col-md-5 d-flex justify-content-around">
          <Button onClick={handleAccept}
                  variant="contained"
                  color="primary"
                  disabled={hasRole(profile, ['write_admin', 'read_admin'])}
          >Accept</Button>
          <Button onClick={handleClose} variant="contained">Close</Button>
          <Button onClick={resetFem}
                  variant="contained"
                  color="secondary"
                  disabled={hasRole(profile, ['write_admin', 'read_admin'])}
          >Reset</Button>
        </div>
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
}

export default CoronalFemurImage;
