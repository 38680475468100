import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { CaseFormDataContext } from './CreateCaseContext';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const SurgeryType = (props) => {
  const context = useContext(CaseFormDataContext)
  const { surgeryType, setState, setStep, nextStep, formData } = context
  const bodyPrefs = context.formData.body_pref
  const hip = []
  const ankle = []
  const knee = []
  let existHip = false
  let existKnee = false


  console.log(hip.length)
  // bodyPrefs.map((bodyPref) => {
  //   console.log(bodyPref)}
     //  const { body_key} = bodyPref
     //  console.log(body_key)
     //  switch (body_key){
     //    case (bodyPref.includes("Hip")):
     //     return(hip.push(bodyPref))
     //    case (bodyPref.includes("Knee")):
     //      return(knee.push(bodyPref))
     //    case (bodyPref.includes("Ankle")):
     //      return(ankle.push(bodyPref))
     //    default:
     //      return null
     // }
// )

// roles.map((role) => {
//   const { roleName, roleId} = role
//   if(roleName.includes("MRI Center")){})


  const onChange = (e) => {
    e.preventDefault()
    setState({surgeryType: "TKR"})
    nextStep()
    console.log(e.target.value)
    console.log(setState)
  }

  // return (
  //   <div>
  //     <FormControl component="fieldset" required="true">
  //       <FormLabel component="legend">Gender</FormLabel>
  //       <RadioGroup aria-label="gender" name="surgeryType" value={surgeryType} onChange={onChange}>
  //         <FormControlLabel value="knee" control={<Radio />} label="Knee" />
  //         <FormControlLabel value="hip" control={<Radio />} label="Hip" />
  //         <FormControlLabel value="ankle" control={<Radio />} label="Ankle" />
  //         <FormControlLabel
  //           value="disabled"
  //           disabled
  //           control={<Radio />}
  //           label="(Disabled option)"
  //         />
  //
  //       </RadioGroup>
  //     </FormControl>
  //   </div>
  //
  // )
  return (
    <div className="row h-50 justify-content-center align-items-center">
    <FormControl component="fieldset">
      <FormLabel component="legend" style={{fontSize: '3em', color: 'black', textAlign: 'center'}}>Surgery Type</FormLabel>
        <RadioGroup aria-label="surgery type" name="surgeryType" value={surgeryType} onChange={onChange}>
          {
            bodyPrefs && bodyPrefs.map((bodyPref, i) => {
               if (bodyPref.body_key.includes("Hip") & !existHip){
                 existHip = true
                 return(
                  <div key={i} className="pb-3">
                    <input type="radio" id="pref" type="radio" name="surgeryType" value={surgeryType}
                      onChange={onChange} style={{display: 'none'}}
                    />
                    <label htmlFor="pref"
                    className="col-md-12"
                    style={{
                      display:'inline-block', cursor: 'pointer',
                      backgroundColor: '#3f51b5',
                      color: 'white',
                    }}>
                      <div style={{fontWeight: '600', fontSize: '77px', textAlign: 'center'}}>
                        THR
                      </div>
                    </label>
                  </div>
                 )
               }
             else if (bodyPref.body_key.includes("Knee") & !existKnee){
                existKnee = true
                 return(

                   <div key={i} className="pb-3">
                     <input type="radio" id="pref" type="radio" name="surgeryType" value={surgeryType}
                       onChange={onChange} style={{display: 'none'}}
                     />
                     <label htmlFor="pref"
                     className="col-md-12"
                     style={{
                       display:'inline-block', cursor: 'pointer',
                       backgroundColor: '#3f51b5',
                       color: 'white', borderRadius: '2%'
                     }}>
                       <div style={{fontWeight: '600', fontSize: '77px', textAlign: 'center'}}>
                         TKR
                       </div>
                     </label>
                   </div>

                 )
               }
            })
          }
        </RadioGroup>
      </FormControl>
    </div>
  )
};

export default SurgeryType;
