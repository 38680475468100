import React, { memo, useContext } from 'react';
import { MRIContext } from "./MRIFormContext";
import styles from './../../styles';

const MRIInput = memo((props) => {
  const context = useContext(MRIContext)
  const { fieldId, fieldType, roleId } = props
  const { formData } = context
  const stateKey = `${roleId}_${fieldId}`

  // value={formData[`Surgeon_${fieldId}`] = formData.Surgeon[`${field}`]
  if (formData.Surgeon) {

  switch(fieldType) {
    case "text":
      return (
        <pre
          type="text"
          id={fieldId}
          style={styles.textInput}
          value={formData[`Surgeon_${fieldId}`] = formData.Surgeon[`${fieldId}`]}
        >{formData[stateKey]}</pre>
      )
    case "textarea":
      return(
        <pre
          type="textarea"
          id={fieldId}
          style={styles.textareaInput}
          value={formData[`Surgeon_${fieldId}`] = formData.Surgeon[`${fieldId}`]}
        >{formData[stateKey]}</pre>
      )
      default:
        return null
  }
}})

export default MRIInput;
