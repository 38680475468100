import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import logo from './../../img/lento_logo.jpg';

import moment from 'moment';
import MaterialTable from 'material-table';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px'
    },
  });

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em', color: '#141d3e'}},
  });

const PatientCaseList = () => {
  const { token, role, user, setAuthenticated, setAuthBody } = useContext(AuthorizeContext);
  const [data, setData] = useState({});
  const classes = useStyles();
  let { emailId } = useParams()
  const [loading, setLoading] = useState(true)
  console.log(emailId)
  console.log(data)
  useEffect(() => {
    const fetchData = async () => {
      if (emailId) {
        const res = await axios({
          url: `https://${api_url}/api/case_list?surgeon=${user.Email_Address}`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setData({data: res.data})
      }
      else {
        const active = await axios({
          url: `https://${api_url}/api/case_list?surgeon=${user.Email_Address}&status=active`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setData({data: active.data})
      }

    }
    fetchData();
  }, []);

  function convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }

  const [state, setState] = useState({
    columns: [
      { title: 'Case #', field:'case_id', width: 200,  editable: "never", render:rowData => {
            if(role == "mri_center"){
              return(
                <Link type="text" to={`/patient/${rowData.case_id}`}>{rowData.case_id}</Link>
              )
            }
            return (
              <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            )

        }, cellStyle:{ fontSize: '.7em'}, defaultSort: 'asc'},
      { title: 'Patient Name', width: 195, field:'Patient.Last_Name', editable: "never", render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )
      }, cellStyle:{ fontSize: '.7em'}},
      { title: 'Order Date', width: 200, field:'dateCreated', editable: "never", type:'date',
        cellStyle:{ fontSize: '.7em'},
        render:rowData => {
              return(rowData.dateCreated)
          },
        customSort: (a , b) => {

          a = new Date(a.dateCreated);
          b = new Date(b.dateCreated);
          return b > a ? 1 : -1;
        }
      },
      { title: 'Surgery Date', width: 200, field:'Patient.Date_of_Surgery', editable: "never",  type: 'date',
        cellStyle:{ fontSize: '.7em'},
        customSort: (a , b) => {
          a = moment(a.Patient.Date_of_Surgery).format();
          b = moment(b.Patient.Date_of_Surgery).format();
          return b > a ? 1 : -1;
        }
      },
      { title: 'Surgery Type', width: 180, field:'Patient.Surgery_Type', editable: "never", cellStyle:
        { fontSize: '.7em', textAlign: 'center', paddingRight: '8rem'}
      },
      { title: 'Surgery Side', width: 180, field:'Patient.Side_of_Body', editable: "never",
        cellStyle:{ fontSize: '.7em', paddingLeft: '6.5rem'}
      },
      { title: 'Status', width: 180, field:'case_status',
        lookup: {
                  'Purchase_Order':'Waiting For MRI',
                  'MRI': 'In Progress',
                  'MRI_QC': 'In Progress',
                  'Planning_1': 'In Progress',
                  'Planning_2': 'Approval Ready',
                  'Surgeon_approval': 'Surgeon Approved',
                  'Template_design': 'Manufacturing',
                  'G_code': 'Manufacturing',
                  'CNC': 'Manufacturing',
                  'Final_QC': 'Manufacturing',
                  'Shipped': 'Shipped',
                  'Surgeon_Rejected': 'Surgeon Rejected'
            }
            ,
        cellStyle:
          rowData =>
          {
              console.log({rowData})
            if (rowData !== "Surgeon Rejected"){
            return(
              
                          {background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
                          backgroundColor: "#045de9",
                          color: 'white',
                          textAlign: 'center',
                          fontSize: '.7em'
                        })
              } else{
                return({
                      backgroundColor: "#990000",
                      background: 'linear-gradient(315deg, #FF0000 0%, #FFA500 94%)',
                      // backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)",
                      color: 'white',
                      textAlign: 'center',
                      fontSize: '.7em'
                    })
              }
                    

                    

                    
              
          }}
    ]
  });

  if(state.data){
    if(state.data.notLoggedIn){

      setAuthenticated(false)
      setAuthBody({role: 'visitor'})
      alert("You have been logged out due to inactivity. Please log in again.")
    }
  }



  else if(loading){
    return(
      <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
              height: "100%", left: 0, top: 0, backgroundColor: "white"
            }}>
          <img src={logo} alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
          />
      </div>

    )
  }
  console.log(state)
  return(
  <div className="col-md-10" style={{marginLeft: '10em'}}>
  <ThemeProvider theme={theme}>
    <MaterialTable
      title={emailId ? "History" : "Current Orders"}
      columns={state.columns}
      data={data.data}
      icons={tableIcons}
      localization={{
          pagination: {
              labelDisplayedRows: '{from}-{to} of {count}'
          },
          toolbar: {
              nRowsSelected: '{0} row(s) selected'
          },
          header: {
              actions: 'Edit'
          },
          body: {
              emptyDataSourceMessage: 'No records to display',
              filterRow: {
                  filterTooltip: 'Filter'
              }
          }
      }}
      options={{
        actionsColumnIndex: 8,
        sorting: true,
        headerStyle: {
          fontSize: '1em',
          color: '#141d3e'
        },
        searchFieldStyle: {
          fontSize: '2rem'
        },
        pageSize: 20
      }}
      style={{
        fontSize: '20px'
      }}
      />
    </ThemeProvider>
    </div>
  );
};

export default PatientCaseList;
