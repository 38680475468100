import React from 'react';
import { CaseFormDataProvider } from '../components/createCaseForm/CreateCaseContext';
import CreateForm from '../components/createCaseForm/CreateForm';

const SurgeonCreateCasePage = () => (
    <CaseFormDataProvider>
      <CreateForm />
    </CaseFormDataProvider>
);

export default SurgeonCreateCasePage;
