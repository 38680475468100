import React, { useState, useEffect, useContext } from 'react';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";

import { useParams, Redirect } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { Button, Carousel } from 'react-bootstrap';
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button className="slick-arrow" onClick={onClick} style={{zIndex: 400, position: 'absolute', height: '75px', width: '75px',
                                                      right: '-10px', top: '135px'}} bsStyle="primary">
    <span className="glyphicon glyphicon-chevron-right" style={{fontSize: '30px'}}/>
    </Button>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button className="slick-arrow" onClick={onClick} style={{zIndex: 400, position: 'absolute', height: '75px', width: '75px',
                                                      left: '-10px', top: '135px'}} bsStyle="primary">
    <span className="glyphicon glyphicon-chevron-left" style={{fontSize: '30px'}}/>
    </Button>
  );
}

const ActiveScreenShot = () => {
  let { caseid } = useParams()
  const { token, setAuthenticated, setAuthBody, authenticated } = useContext(AuthorizeContext)
  const [data, setData] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [option, setOption] = useState("MRI_QC")


  const onChange = (e) => {
    setOption(e.target.value)
  }


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        method: 'get',
        url: `https://${api_url}/api/front_screenshots?case_id=${caseid}`,
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setData(res.data)
          }
        }
      );
    };
    fetchData();
  }, []);
  
  // GRABBING SURGEON APPROVAL SCREENSHOT BY REMOVING THE DATE THAT IS ADDED ONCE THE CASE IS COMPLETED
  console.log(data)
  let new_data = data.Surgeon_approval;
  if (new_data && new_data.length) {
    console.log(new_data)
    let str = new_data[0]
     if (str.length >118){
    let part1 = str.split('.com')[1]
    if (part1.includes("(") && part1.includes(")")){
        let part2 = part1.split("(")[1].split(")")[1].replace("-",".png")
        let part3 = str.split(".com")[0] +'.com' +part1.slice(0,8)
        let part4 = part3 + part2
        let SA_str_url = part4
        data.Surgeon_approval = [SA_str_url]
      }
    }
  }
  console.log(data)
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  // if(data.MRI_QC){
  //   view_title = "Quality Control [Screen Shot]"
  //   let src_array = []
  //   const qc_slice = () => {
  //     let mri_qc = data.MRI_QC;
  //     mri_qc.map(img =>{
  //           let filename = img.split('/').pop()
  //           img.concat(src_array)
  //     })}
  //   qc_slice()
  //   console.log(src_array)
  // }

if(data.MRI_QC){
  return (
    <div className="case_detail_layout">
      <div className="col-md-3">
        <div className="row-margin-bottom">
          <h1>{caseid}</h1>
          <h3>ScreenShot Area</h3>
        </div>
        <div className="row-margin-bottom">
          <Radio
            name="selectedOption"
            value="MRI_QC"
            checked={option === "MRI_QC"}
            onChange={onChange}
          />MRI QC
        </div>
        <div className="row-margin-bottom">
        <Radio
          name="selectedOption"
          value="Planning_1"
          checked={option === "Planning_1"}
          onChange={onChange}
        />Planning 1
        </div>
        <div className="row-margin-bottom">
        <Radio
          name="selectedOption"
          value="Planning_2"
          checked={option === "Planning_2"}
          onChange={onChange}
        />Planning 2
        </div>
        <div className="row-margin-bottom">
        <Radio
          name="selectedOption"
          value="Surgeon_approval"
          checked={option === "Surgeon_approval"}
          onChange={onChange}
        />Surgeon Approval
        </div>
        <div className="row-margin-bottom">
        <Radio
          name="selectedOption"
          value="Template_design"
          checked={option === "Template_design"}
          onChange={onChange}
        />Template Design
        </div>
        <div className="row-margin-bottom">
        <Radio
          name="selectedOption"
          value="G_code"
          checked={option === "G_code"}
          onChange={onChange}
        />G Code
        </div>

      </div>
      <div className="col-md-9">
        <h2 className="text-center">{option.replace("_", " ").toUpperCase()}</h2>
        <Slider {...settings}>
          {data[option].map(img => {
            return(
              <div key={img}>{
                option === "G_code" ?
                  <img src={img} style={{height: '70vh', maxHeight: "80%", maxWidth:"100%", marginLeft:"50px"}}/>
                  :
                  <img src={img} style={{height: '70vh', maxHeight: "80%", maxWidth:"80%", marginLeft:"57.5px"}}/>
              } 
              <h4 style={{textAlign: "center"}}>{img.split('.com')[1].split('/')[3].replaceAll(/.png/ig,'').replaceAll(/.JPG/ig,'').replaceAll(/%2C/ig,'').replaceAll(/stage/ig,'').replaceAll("_"," ").replace(/find/ig,'').replace(/[0-9]/g, "").replace(/planning/ig,'')}</h4>
              </div>
            )
          })}
        </Slider>

      </div>
    </div>
  );
}
else if(!authenticated){
  return(
    <Redirect to={'/'} />
  )
}

else{
  return "hi"
}
}


export default ActiveScreenShot;
