import React, {useState,  useContext, useParams} from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';


import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";


const theme = createMuiTheme({
  typography: { h6: {fontSize: '1.4em'},
                body1: {fontSize: '1em'},
  button: {fontSize: '3em'}
  },
});

const CaseDanger = () => {
  const [open, setOpen] = useState(true);
  const { token } = useContext(AuthorizeContext);
  const context = useContext(ActiveFormDataContext);
  let { setState, formData } = context;
  const handleClickOpen = () => {
    setOpen(true);
  };
  let femur_chosen, tibia_chosen, flag_code;



  const handleClose = () => {
    setState({errorConfirmed: true})
    setOpen(false)
  }


    return(
      <>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{`SERVER ERROR`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            There is an internal server issue. Please contact Lento support.
            +1 (510) 413-7014
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-primary" onClick={handleClose} style={{fontSize: '2em'}}>
                <span className="glyphicon glyphicon-ok"/>
                &nbsp;OK
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      </>
    )

}

export default CaseDanger;
