import React from 'react';
import { ActiveFormDataProvider } from '../components/activeCaseForm/ActiveContext';
import SurgeonApproval from '../components/surgeonApproval/SurgeonApproval';


const SurgeonApprovalPage = () => {
  return(

    <>
    <div style={{zIndex: '150', position: "relative", marginLeft: "auto", marginRight: "auto"}}>
      <h3 className="text-center" ><b>PTOLEMEDIC SYSTEM</b></h3>
    </div>
    <div id="screenshot">
      <ActiveFormDataProvider>
        <SurgeonApproval />
      </ActiveFormDataProvider>
    </div>
    </>

  )
};

export default SurgeonApprovalPage;
