import React, { useState, useContext, Suspense, lazy } from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import Login from './components/Login';
import WareHouseLogin from './components/WareHouseLogin';
import PackagingLogin from './components/PackagingLogin';

import IdleTimerContainer from './components/IdleTimerContainer';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import {MuiThemeProvider} from '@material-ui/core/styles';


import MRISurgeonPage from './pages/mrisurgeons';

import ManagePage from './pages/managepage';

import ReadOnlyHomePage from './pages/readonlyhome';
import ActiveList from './pages/activelist';
import FlagList from './pages/flaglist';
import AbortList from './pages/abortlist';
import ManageList from './pages/Management/processorlist';
import ImplantList from './pages/Implant/implantlist';

import PatientCaseList from './pages/Surgeon/patientCasesList';
import HospitalCaseList from './pages/Hospital/hospitalCaseList';
import MriCenterCaseList from './pages/MRI/mriCaseList';
import DistributorCaseList from './pages/Distributor/distributorCaseList';

import DistributorList from './pages/Distributor/distributorList';
import HospitalList from './pages/Hospital/hospitalList';
import MRIList from './pages/MRI/mriList';
import SurgeonList from './pages/Surgeon/surgeonlist'
import SurgeonApproval from './components/surgeonApproval/SurgeonApproval';
import SurgeonApprovalPage from './pages/surgeonapproval';

import ActiveScreenShot from './components/activeCaseForm/ActiveScreenShot';


import { AuthorizeContext }  from './RootContext';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import logo from './img/lento_logo.jpg';

import { isAllowed } from "./authorize";

import { rules } from "./rbac-rules";
const NewUserForm = lazy(() => import('./components/NewUserForm'));
const NavBar = lazy(() => import('./components/NavBar')) ;
const HomePage = lazy(() => import('./pages/home'));
const SuperAdminPage = lazy(() => import('./pages/superadmin'));
const ReadAdminPage = lazy(() => import('./pages/readadmin'));
const ConfirmPassword = lazy(() => import('./components/ConfirmPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const ChangePassword = lazy(() => import('./components/ChangePassword'));
// import NewUserForm from './components/NewUserForm';
const WriteAdminPage = lazy(() => import('./pages/writeadmin'));
const SurgeonPage = lazy(() => import( './pages/surgeon'));
const HospitalPage = lazy(() => import('./pages/hospital'));
const MriCenterPage = lazy(() => import('./pages/mricenter'));
const DistributorPage = lazy(() => import('./pages/distributor'));
const CallbackPage = lazy(() => import('./pages/callback'));
const UserManagePage = lazy(() => import('./pages/managepage'));
const FileManagePage = lazy(() => import('./pages/filemanagepage'));

const AccountPage = lazy(() => import('./pages/Accounting/accounthome'));
const ARSetup = lazy(() => import('./pages/Accounting/arsetup'));
const APSetup = lazy(() => import('./pages/Accounting/apsetup'));

const Invoice = lazy(() => import('./pages/Accounting/invoice'));
const Details = lazy(() => import('./pages/Accounting/Details'));
const Receivable = lazy(() => import('./pages/Accounting/receivable'));
const ARPay = lazy(() => import('./pages/Accounting/arpayment'));
const ReceivedForm = lazy(() => import('./pages/Accounting/ReceivedForm'));

const EnterBill = lazy(() => import('./pages/Accounting/enterbill'));
const Payable = lazy(() => import('./pages/Accounting/payable'));

const ResearchPage = lazy(() => import('./pages/Research/researchhome'));
const Scatter = lazy(() => import('./pages/Research/scatter'));



const ActiveCasePage = lazy(() => import('./pages/activecases'));
const SurgeonCreateCasePage = lazy(() => import('./pages/surgeoncreatecase'));
const SurgeonViewPref = lazy(() => import('./pages/surgeonview'));
const MRICasePage = lazy(() => import('./pages/mricenter'));
const SelectSurgeonPage = lazy(() => import( './pages/Surgeon/selectSurgeonPage'));
const SurgeonHomePage = lazy(() => import('./pages/surgeonhome'));
const MRIHomePage = lazy(() => import('./pages/mrihome'));

const SurgeonCaseList = lazy(() => import('./pages/Surgeon/surgeonCaseList'));
const CompletedList = lazy(() => import('./pages/completedlist')) ;


const FinalQC = lazy(() => import('./components/processorForm/FinalQC'));
const ShippingCheck = lazy(() => import('./components/processing/ShippingCheck'));


const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '26em',
    width: '15%'
  },
}));


const App = (props) => {
  let {setAuthBody, authBody, role, authenticated, setAuthenticated} = useContext(AuthorizeContext);
  const classes = useStyles();

  const [profile, setProfile] = useState(authBody || {role: "visitor"})

  const hasRole = (profile, roles) =>{
    return roles.includes(authBody.role)
  }

  return (
    <>
    {authenticated ? <IdleTimerContainer /> : null }
    <Router>
    <Suspense fallback={<div className={classes.root}><CircularProgress /></div>}>
      { !authenticated ? null : <NavBar />}
      <Switch>
        { !authenticated || authBody.role == "visitor" ?
        <>
          <Route exact path="/" component={Login} />
          <Route exact path="/warehouse" component={WareHouseLogin} />
          <AuthenticatedRoute exact path="/accounting" component={AccountPage} />
          <Route exact path="/packaging" component={PackagingLogin} />
          <AuthenticatedRoute exact path="/shippingCheck" component={ShippingCheck} />

          <Route exact path="/new-user" component={NewUserForm} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/confirm-password/:tempToken" children={<ConfirmPassword />} />
          <Route path={'/change-password/:tempToken'} children={<ChangePassword />} />
          <Route path={'/change-password/:tempToken'} children={<ChangePassword />} />
          <AuthenticatedRoute exact path="/surgeons" component={Login} />
          <AuthenticatedRoute exact path="/case/create" component={SurgeonCreateCasePage} />
          <AuthenticatedRoute path="/surgeons/:surgeonid/select" component={SelectSurgeonPage} />
          <AuthenticatedRoute path="/surgeons/:surgeonid/active-cases" children={<ActiveList />} />
          <AuthenticatedRoute path="/complete-cases/:surgeonid" children={<CompletedList />} />
          <AuthenticatedRoute path="/surgeons/:surgeonid/complete-cases" children={<CompletedList />} />
          <AuthenticatedRoute path="/surgeons/:surgeonid/abort-cases" children={<AbortList />} />
          <AuthenticatedRoute path="/case/:caseid/screenshot" children={<ActiveScreenShot />} />
          <AuthenticatedRoute path="/superadmin" component={SuperAdminPage} />
          <AuthenticatedRoute path="/surgeon" component={SurgeonPage}/>
          <AuthenticatedRoute path="/hospital" component={HospitalPage}/>
          <AuthenticatedRoute path="/mricenter" component={MriCenterPage}/>
          <AuthenticatedRoute path="/distributor" component={DistributorPage}/>
          <AuthenticatedRoute path="/callback" component={CallbackPage}/>
          <AuthenticatedRoute path="/case/:caseid" children={<ActiveCasePage />}/>
          <AuthenticatedRoute path="/active-case-list" component={ActiveList}/>
          <AuthenticatedRoute path="/completed-case-list" component={CompletedList}/>
          <AuthenticatedRoute path="/flag-case-list" component={FlagList}/>
          <AuthenticatedRoute path="/abort-case-list" component={AbortList}/>
          <AuthenticatedRoute path="/flag-case-list" component={FlagList}/>
          <AuthenticatedRoute path={`/surgeoncases/:surgeonid`} component={SurgeonCaseList}/>
          <AuthenticatedRoute path={`/hospitalcases/:hospitalID`} component={HospitalCaseList}/>
          <AuthenticatedRoute path={`/mricentercases/:mricenterID`} component={MriCenterCaseList}/>
          <AuthenticatedRoute path={`/distributorcases/:distributorID`} component={DistributorCaseList}/>
          <Route path={`/patient/:caseId`} children={<MRICasePage />} />
          <AuthenticatedRoute path="/case/create" component={SurgeonCreateCasePage} />
          <AuthenticatedRoute path={"/cases"} component={PatientCaseList} />
          <AuthenticatedRoute path="/history/:emailId" component={PatientCaseList} />
          <AuthenticatedRoute path="/surgeon-approval/:caseid/"
          children={<SurgeonApprovalPage />} />
          <AuthenticatedRoute path="/hospitals" component={HospitalList} />
          <AuthenticatedRoute path='/mris' component={MRIList} />
          <AuthenticatedRoute path='/distributors'component={DistributorList} />
          <AuthenticatedRoute path="/surgeons" component={SurgeonList} />
          <AuthenticatedRoute path="/preferences/:emailId" component={SuperAdminPage} />
          <AuthenticatedRoute path="/surgeonPref/:emailId" component={SurgeonViewPref} />
          <AuthenticatedRoute exact path="/finalQC" component={FinalQC} />

          <AuthenticatedRoute path="/manage" component={ManagePage} />
          <AuthenticatedRoute path='/documents' component={FileManagePage} />
          <AuthenticatedRoute path="/implant" component={ImplantList} />
          <AuthenticatedRoute path='/active-case-list' component={ActiveList} />
          <AuthenticatedRoute path='/completed-case-list' component={CompletedList} />
          <AuthenticatedRoute path='/abort-case-list' component={AbortList} />
          <AuthenticatedRoute path='/reseach' component={ResearchPage} />
          <AuthenticatedRoute exact path="/research/scatter" component={Scatter} />


        </>
        : null
        }

        <Route exact path="/login" component={Login} />
        <Route exact path="/warehouse" component={WareHouseLogin} />
        <Route exact path="/packaging" component={PackagingLogin} />
        <Route exact path="/new-user" component={NewUserForm} />


        <Route exact path="/reset-password" component={ResetPassword} />
        <Route path="/confirm-password/:tempToken" children={<ConfirmPassword />} />
        <Route path={'/change-password/:tempToken'} children={<ChangePassword />} />
        <Route path={'/change-password/:tempToken'} children={<ChangePassword />} />

        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute exact path='/' component={ReadAdminPage} /> }

        {hasRole(profile, ['mri_center']) && <AuthenticatedRoute exact path="/" component={MRIHomePage} /> }
        {hasRole(profile, ['mri_center']) && <AuthenticatedRoute exact path="/surgeons" component={MRISurgeonPage} /> }

        {hasRole(profile,['surgeon']) && <AuthenticatedRoute exact path="/" component={SurgeonHomePage} />}
        {hasRole(profile,['surgeon', 'super_admin','write_admin', 'read_admin'])
        && <AuthenticatedRoute exact path="/case/create" component={SurgeonCreateCasePage} />}

        {hasRole(profile,['distributor', 'hospital']) && <AuthenticatedRoute exact path="/" component={ReadOnlyHomePage}  />}
        {hasRole(profile,['distributor', 'hospital']) && <AuthenticatedRoute path="/surgeons/:surgeonid/select" component={SelectSurgeonPage} />}
        {hasRole(profile,['distributor', 'hospital']) && <AuthenticatedRoute path="/surgeons/:surgeonid/active-cases" children={<ActiveList />} />}
        {hasRole(profile,['surgeon']) && <AuthenticatedRoute path="/complete-cases/:surgeonid" children={<CompletedList />} />}
        {hasRole(profile,['distributor', 'hospital']) && <AuthenticatedRoute path="/surgeons/:surgeonid/complete-cases" children={<CompletedList />} />}
        {hasRole(profile,['distributor', 'hospital']) && <AuthenticatedRoute path="/surgeons/:surgeonid/abort-cases" children={<AbortList />} />}
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/case/:caseid/screenshot" children={<ActiveScreenShot />} />}

        <AuthenticatedRoute path="/superadmin" component={SuperAdminPage} />
        <AuthenticatedRoute path="/surgeon" component={SurgeonPage}/>
        <AuthenticatedRoute path="/hospital" component={HospitalPage}/>
        <AuthenticatedRoute path="/mricenter" component={MriCenterPage}/>
        <AuthenticatedRoute path="/distributor" component={DistributorPage}/>
        <AuthenticatedRoute path="/callback" component={CallbackPage}/>
        <AuthenticatedRoute path="/case/:caseid" children={<ActiveCasePage />}/>
        <AuthenticatedRoute path="/active-case-list" component={ActiveList}/>
        <AuthenticatedRoute path="/completed-case-list" component={CompletedList}/>
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/flag-case-list" component={FlagList}/> }
        <AuthenticatedRoute path="/abort-case-list" component={AbortList}/>
  {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/flag-case-list" component={FlagList}/> }
        <AuthenticatedRoute path={`/surgeoncases/:surgeonid`} component={SurgeonCaseList}/>
        {/*hasRole(profile, ['super_admin']) && <AuthenticatedRoute path={`/cases`} component={SurgeonCaseList}/>*/}
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path={`/hospitalcases/:hospitalID`} component={HospitalCaseList}/> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path={`/mricentercases/:mricenterID`} component={MriCenterCaseList}/> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path={`/distributorcases/:distributorID`} component={DistributorCaseList}/> }

        <Route path={`/patient/:caseId`} children={<MRICasePage />} />

        {hasRole(profile, ['surgeon']) && <AuthenticatedRoute path="/case/create" component={SurgeonCreateCasePage} /> }
        {hasRole(profile, ['surgeon']) && <AuthenticatedRoute path={"/cases"} component={PatientCaseList} /> }
        {hasRole(profile, ['surgeon']) && <AuthenticatedRoute path="/history/:emailId" component={PatientCaseList} /> }

        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin', 'surgeon', 'distributor'])
        && <AuthenticatedRoute path="/surgeon-approval/:caseid/"
        children={<SurgeonApprovalPage />}
        />}
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/hospitals" component={HospitalList} /> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path='/mris' component={MRIList} /> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path='/distributors'component={DistributorList} />}
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/surgeons" component={SurgeonList} /> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin', 'surgeon'])
          && <AuthenticatedRoute path="/preferences/:emailId" component={SuperAdminPage} />
        }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin', 'surgeon'])
          && <AuthenticatedRoute path="/surgeonPref/:emailId" component={SurgeonViewPref} />
        }


        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/manage" component={ManagePage} /> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path='/documents' component={FileManagePage} /> }
        {hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) && <AuthenticatedRoute path="/implant" component={ImplantList} /> }
        <AuthenticatedRoute path='/active-case-list' component={ActiveList} />
        <AuthenticatedRoute path='/completed-case-list' component={CompletedList} />
        <AuthenticatedRoute path='/abort-case-list' component={AbortList} />

        <AuthenticatedRoute exact path="/accounting" component={AccountPage} />
        <AuthenticatedRoute exact path="/accounting/ARSetup" component={ARSetup} />
        <AuthenticatedRoute exact path="/accounting/APSetup" component={APSetup} />

        <AuthenticatedRoute exact path="/accounting/Invoice/" component={Invoice} />
        <AuthenticatedRoute exact path="/accounting/Details/" component={Details} />
        <AuthenticatedRoute exact path="/accounting/ARPayment" component={ARPay} />
        <AuthenticatedRoute exact path="/accounting/areceivable" component={Receivable} />
        <AuthenticatedRoute exact path="/accounting/ReceivedForm" component={ReceivedForm} />

        <AuthenticatedRoute exact path="/accounting/EnterBill" component={EnterBill} />
        <AuthenticatedRoute exact path="/accounting/Payable/" component={Payable} />
       
        <AuthenticatedRoute exact path={"/accounting/Invoice/:acct"} component={Invoice} />

        <AuthenticatedRoute exact path="/research" component={ResearchPage} />
        <AuthenticatedRoute exact path="/research/scatter" component={Scatter} />
        <AuthenticatedRoute exact path="/finalQC" component={FinalQC} />
        <AuthenticatedRoute exact path="/shippingCheck" component={ShippingCheck} />







        {/*<Route path='/flag-case-list' component={FlagCaseList} />*/}
      </Switch>
      </Suspense>
    </Router>
    </>
  )
}

export default App;
