import React, {useState,  useContext} from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";


const theme = createMuiTheme({
  typography: { h6: {fontSize: '1.4em'},
                body1: {fontSize: '1em'},
  button: {fontSize: '2em'}
  },
});

const useStyles = makeStyles({
  root: { justifyContent: 'space-evenly'},
  spacing: {justifyContent: 'space-evenly'},
  button:{
    fontSize: "1.5em",
    float: "right",
    margin: "10px 30px 10px 10px"
  }});

const CaseReject = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const [reject, setReject] = useState(false);
  const context = useContext(ActiveFormDataContext);
  let { setState, formData } = context;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { token, setAuthenticated, setAuthBody, authBody } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody)


  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }
  


  const handleClose = (e) => {
    //insert put request
    e.preventDefault()
    // console.log(options);
    if(!formData.otherRejectForm){
      console.log(formData.rejectForm)
      axios({
        method:'put',
        url: `https://${api_url}/api/case?case_id=${formData.slug}&update=reject`,
        data: {"reject_description": formData.rejectForm},
        headers: {
          'x-access-token' : token
        }
      }).then(res=> {
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.validation){
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
        
          setReject(true)

        }
      });
    }
    else{
      axios({
        method:'put',
        url: `https://${api_url}/api/case?case_id=${formData.slug}&update=reject`,
        data: {"reject_description": formData.otherRejectForm},
        headers: {
          'x-access-token' : token
        }
      }).then(res=> {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.validation){
            alert(res.data.message)
  
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setReject(true)
            window.location.replace(`/case/${formData.slug}`)

          }
      });
    }
    

  };

  const onChange = (e) => {
    setState({
      rejectForm: e.target.value
    })
  }

  const formChange = (e) => {
    setState({
      otherRejectForm: e.target.value
    })
  }

  if(reject){
    return(
      <Redirect to={`/cases`} />
    )
  }

  const handleCancel = () => {
    setState({
      rejectForm: false,
      otherRejectForm: ""
    })
    setOpen(false)
  }
  // onChange={(e) =>
  //   setState({
  //     [stateKey] : e.target.value
  //   })
  // }
  // value={formData[stateKey]}

  return (
    <>
    <ThemeProvider theme={theme}>
      <button
        className="ml-2 btn btn-lg btn-danger"
        onClick={handleClickOpen}
        disabled={hasRole(profile, ['write_admin', 'read_admin'])}

      >
        <span className="glyphicon glyphicon-thumbs-down"/>
        &nbsp; reject
      </button>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CAUTION!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This is to confirm that you are rejecting the pre-planned adjusted measurements made on the surgeon approval page.
          Please fill out rejection form below.

          </DialogContentText>
          <RadioGroup aria-label="gender" name="rejectForm" value={formData.rejectForm || ""} onChange={onChange}>
            <FormControlLabel
              control={
                <Radio

                />
                }

                value="Cancelled Surgery"
                label="Cancelled Surgery"
              />
              {/*<FormControlLabel value={formData.rejectForm} control={<Radio />} label="Other" onClick={openForm} />*/}
              <FormControlLabel
                control={
                  <Radio

                  />
                  }

                  value="Others"
                  label="Others"
                />

          </RadioGroup>
          {
            formData.rejectForm === "Others" ?
            <TextField
              variant="outlined"
              margin="dense"
              id="name"
              label="Please Specify Reason"
              onChange={formChange}
              value={formData.otherRejectForm || ""}
              multiline={true}
              rows={4}
              type="text"
              fullWidth
              inputProps={{
                maxLength: 300
              }}
              required
            />
            :
            <TextField
              value = ""
              variant="outlined"
              margin="dense"
              label="Rejection Form"
              fullWidth
              disabled
            />

          }
        </DialogContent>
        <DialogActions
          disableSpacing
          classes={{
            root: classes.root,
            spacing: classes.spacing
          }}
        >
          {
            formData.rejectForm == "Cancelled Surgery" || formData.otherRejectForm ?
            <button className="btn btn-primary" onClick={handleClose} variant="contained" style={{fontSize: '2em'}}>
              OK
            </button>
            :
            <button className="btn btn-primary" variant="contained" style={{fontSize: '2em'}} disabled>
              OK
            </button>
          }
          <button className="btn btn-danger" onClick={handleCancel} variant="contained" style={{fontSize: '2em'}}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      </ThemeProvider >
    </>
  );
}

export default CaseReject;
