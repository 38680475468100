import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import html2canvas from 'html2canvas';
import CircularProgress from '@material-ui/core/CircularProgress';
import PatientInfo from "./PatientInfo";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import jsPDF from 'jspdf';
import Checkbox from '@material-ui/core/Checkbox';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";
import { CaseFormDataContext } from './CreateCaseContext';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";

import logo from '../../img/lento_logo.jpg'

import { makeStyles } from '@material-ui/core/styles';

var pdf, pdfFile, pdfData;

const useStyles = makeStyles(theme => ({
  button:{
    fontSize: "1.5em",
    float: "right",
    margin: "10px 30px 10px 10px"
  },
  textField: {
    fontSize: 14,
    marginTop: '1em',
    margin: 'auto',
    // paddingBottom: '-10px',
    paddingTop: '1em',
  },
  resize:{
    // padding: '1em',
    // paddingBottom: '-1em',
    fontSize:14
  },
  initial: {
    fontSize: 14,
    paddingLeft: "auto",
    paddingRight: "auto",
    textTransform: "uppercase"
  },
  signature: {
    fontSize: 14,
    // paddingBottom: '1em',
    // padding: '1em',
    fontStyle: 'italic'
  }
}));

const ConsentForm = () => {
  const classes= useStyles();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [screenshotted, setScreenshotted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);
  const { token, setAuthenticated, setAuthBody, user } = useContext(AuthorizeContext);
  const context = useContext(CaseFormDataContext);
  const { step, setState, prevStep, nextStep, formData, editPref } = context;
  let alignment, screenshot;
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let caseData =
  {
    "Surgeon": {
      "First_Name": context.formData.First_Name,
      "Middle_Name": context.formData.Middle_Name,
      "Last_Name": context.formData.Last_Name,
      "Office_Name": context.formData.Office_Name,
      "Country": context.formData.Country,
      "City": context.formData.City,
      "Postal_Code": context.formData.Postal_Code,
      "Street": context.formData.Street,
      "State_Province": context.formData.State_Province,
      "Email_Address": context.formData.Email_Address,
      "Phone": context.formData.Phone,
      "Fax": context.formData.Fax,
    },
    "Distributor": {
      "First_Name": context.formData.Distributor_First_Name,
      "Middle_Name": context.formData.Distributor_Middle_Name,
      "Last_Name": context.formData.Distributor_Last_Name,
      "Office_Name": context.formData.Distributor_Office_Name,
      "Country": context.formData.Distributor_Country,
      "City": context.formData.Distributor_City,
      "Postal_Code": context.formData.Distributor_Postal_Code,
      "Street": context.formData.Distributor_Street,
      "State_Province": context.formData.Distributor_State_Province,
      "Email_Address": context.formData.Distributor_Email_Address,
      "Phone": context.formData.Distributor_Phone,
      "Fax": context.formData.Distributor_Fax,
    },
    "Hospital": {
      "First_Name": context.formData.Hospital_First_Name,
      "Middle_Name": context.formData.Hospital_Middle_Name,
      "Last_Name": context.formData.Hospital_Last_Name,
      "Office_Name": context.formData.Hospital_Office_Name,
      "Country": context.formData.Hospital_Country,
      "City": context.formData.Hospital_City,
      "Postal_Code": context.formData.Hospital_Postal_Code,
      "Street": context.formData.Hospital_Street,
      "State_Province": context.formData.Hospital_State_Province,
      "Email_Address": context.formData.Hospital_Email_Address,
      "Phone": context.formData.Hospital_Phone,
      "Fax": context.formData.Hospital_Fax
    },
    "MRI_Center": {
      "First_Name": context.formData.MRI_Center_First_Name,
      "Middle_Name": context.formData.MRI_Center_Middle_Name,
      "Last_Name": context.formData.MRI_Center_Last_Name,
      "Office_Name": context.formData.MRI_Center_Office_Name,
      "Country": context.formData.MRI_Center_Country,
      "City": context.formData.MRI_Center_City,
      "Postal_Code": context.formData.MRI_Center_Postal_Code,
      "Street": context.formData.MRI_Center_Street,
      "State_Province": context.formData.MRI_Center_State_Province,
      "Email_Address": context.formData.MRI_Center_Email_Address,
      "Phone": context.formData.MRI_Center_Phone,
      "Fax": context.formData.MRI_Center_Fax
    },
    "Patient": {
      "First_Name": context.formData.Patient_First_Name,
      "Last_Name": context.formData.Patient_Last_Name,
      "Middle_Name": context.formData.Patient_Middle_Name ?
      context.formData.Patient_Middle_Name : ""
      ,
      "Email": context.formData.Patient_Email,
      "Sex": context.formData.Patient_Sex,
      "Date_of_Birth": context.formData.Patient_DOB ? context.formData.Patient_DOB.replaceAll("/", "-") : "",
      "Date_of_Surgery": context.formData.Patient_DOS ? context.formData.Patient_DOS.replaceAll("/", "-") : "",
      "Surgery_Type": context.formData.surgeryType,
      "Side_of_Body": context.formData.Patient_Side
    },
    "Billing_Address": context.formData.billing_address,
    "Shipping_Address": context.formData.shipping_address,
    "Implant": context.formData.Implant,
    "Alignment": context.formData.Patient_Alignment ? context.formData.Patient_Alignment :
     context.formData.Pref_Alignment,
     "Manufacturer_Continent" : context.formData.Manufacturer_Continent
  }

  const handleClose = () => {
    setChecked(false)
    setLoading(false)
    setState({Patient_Email: false})
    setScreenshotted(false)
    setOpen(false);
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const onChange = (e) => {
    const { name, value } = e.target

      setState({...formData,
          [name] : value
      })

  }
  const handleClickOpen = () => {
      setOpen(true);
  };

  const formArray =
  [
    formData.initial_1,
    formData.initial_2,
    formData.initial_3,
    formData.initial_4,
    formData.initial_5,
    formData.signature

  ]

  const validateEmail = (email) =>
  {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }


  const handleScreenShot = (e) => {
    setTimeout(screenshot,2000)
    screenshot = document.querySelector("#capture")
    // overwrite owner doc inner height with your div clientHeight
    const imgWidth = 180;
    const pageHeight = 350;
    setChecked(e.target.checked);
    if(!checked){
      html2canvas(screenshot, {useCORS: true, scrollY: 0, backgroundColor: null}).then(
        canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgHeight = canvas.height * 165 / canvas.width;
          console.log(imgHeight)
          console.log(canvas.height)
          console.log(canvas.width)
          console.log("hibbb")
          let heightLeft = imgHeight
          pdf = new jsPDF("p", "mm");
          let position = 0
          pdf.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight);
          heightLeft -= pageHeight
          // pdf.output('dataurlnewwindow');
          while (heightLeft >= 0){
            console.log(position)
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight);
            heightLeft -= pageHeight
          }

          pdfFile = pdf.output("blob");
          pdfData = new FormData();
          pdfData.append('file', pdfFile, "ok.pdf")
          pdfData.append('data', JSON.stringify(caseData))
          console.log(...pdfData)
        }).then(setScreenshotted(true));
    }

  }
  // if(context.formData.Patient_Alignment){
  //   alignment = context.formData.Patient_Alignment
  // }
  // else{
  //   alignment = context.formData.Pref_Alignment
  // }
  console.log(formArray)
  console.log(formArray.includes("" || undefined))
  const handleSubmit = (e) => {
    e.preventDefault()


    console.log(...pdfData)

    setLoading(true)
    setScreenshotted(false)

    // console.log(options);
    axios({
      method:'post',
      url: `https://${api_url}/api/case`,
        headers: {
          'x-access-token' : token,
          'Content-Type': 'multipart/form-data'
        },
        data: pdfData, caseData

      }).then(res=> {
        if(res.data.message){
          setScreenshotted(false)
          setChecked(false)
        }
        else if(res.data.validation){
          setLoading(false)
          handleClose()
          alert(res.data.validation.replace("_", " "))
        }
        else if(res.data.notLoggedIn){
          setScreenshotted(false)
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{

          setState({case_id: res.data.case_id})
          setCreated(true)

        }
        console.log("me", res.data)
      }).catch(err => {alert(err)
      setScreenshotted(false)
      setChecked(false)
    }
    );
  }
  console.log(context.formData.surgeryType)

  if(created){
    return(
      <Redirect to={`/case/${formData.case_id}`} />
    )
    // console.log(context.formData.case_id)
  }
  return (
    <div>
    <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.button} disabled={!context.formData.Patient_Last_Name||!context.formData.Patient_First_Name||
    !context.formData.Patient_Sex||!context.formData.Patient_DOB||!context.formData.Patient_DOS||!context.formData.Patient_Side}>
      Accept
    </Button>
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Consent Form Date of Issue: September 02, 2020 - Consent Form version: Rev.: A"}</DialogTitle>
      <div id="capture">
      <DialogContent>
        <img src={logo} width="40%" height="40%" className="center-block" style={{marginTop: '-6em', marginBottom: '-5em'}}/>
        <h4 style={{textAlign:'center', fontWeight: 'bold'}}>PtoleMedic System</h4>
        <h3 style={{textAlign: 'center', fontWeight: 'bold'}}>Data Use Consent Form</h3>
        <DialogContentText id="alert-dialog-description" style={{fontSize: '.83em', color: 'black', fontWeight: 'bold', fontFamily: 'arial'}}>
        <div>
        Your surgeon has told you that you need joint replacement of one or both of your knees.
        Lento Medical Innovation, Inc. (Lento) manufactures custom surgical guides to help your surgeon do your procedure.
        The custom guides only fit your unique anatomy.
        Your surgeon designs the guides with special software called the PtoleMedic System.
        Because Lento uses your Magnetic Resonance Images (MRI)l images,
        your consent to use this health information is needed.
        While the information that Lento uses is limited,
        the mandates of the current Medical Device Requirements and the General Data Protection Regulation (GDPR) of the European Union applies.
        Your information cannot be used or accessed without your express consent.
        "Personal Data" means data that can identify you as an individual.
        </div>
        <br />
        <strong>The personal information Lento needs are:</strong>
         <li>
           MRI Images of your leg,
           including your hip, knee, and ankle, contain your name,
           date of birth (DOB), gender, surgeons' name, hospital,
           and case number ID.  We also request your agreement for Lento to receive feedback about your surgery.
           The information may be transferred electronically to Lento's Engineers in the United States of America (USA) for planning.
         </li>
         <strong>The personal information that Lento collects is limited to:</strong>
 
          <li>
          MRI Digital images of your operative leg (knee, hip, and ankle),
          Diagnosis, Name, Age, Gender, Surgeon's Name, Hospital, and Surgery Date,
          including demographic data, Health data,
          MRI images with patient identifier information
          </li>
 
        <strong>The personal data collected is used for the following purpose: </strong>
 
          <li>
          Surgery planning for knee implant placement,
          Implant sizing, Limb (leg) alignment,
          and PtoleKnee Surgical Guide production
          </li>
 
        <strong>The legal basis for collecting and processing personal data is: </strong>
 
          <li>Performance of a contract,
          compliance with a legal obligation,
          Protection of vital interest,
          and a legitimate purpose of furthering your care and treatment
          </li>
 
        <strong>Legitimate interests pursued by us,
        including any third-party uses, are:
        </strong>
      
          <li>
          Surgery planning and manufacture of custom-made cutting guides for your surgery
          </li>
      
        <br />
        <div>
        <strong>Consent: </strong>
        By consenting to this privacy notice,
        you permit Lento Medical Innovation, Inc. to use the specific personal and health information for the purpose(s) identified above.
        Your consent is required for Lento Medical Innovation, Inc.,
        to process both types of personal information,
        and your explicit consent must be given.
        Where we ask you for sensitive data,
        Lento tells you why and how your data is used.
        You may withdraw consent for using your data at any time by filling out and sending a signed copy of the form "withdrawal of consent" available to you by your surgeon or by direct request from Lento Medical Innovation.

        </div>
        <br />
        <div>
        <strong>Disclosure: </strong>
        Lento Medical Innovation, Inc.
        does not pass your data to third parties without first obtaining your consent as we have here.
        Currently, the following third-party entities may see your data for the following purpose as a part of assisting your doctor in providing you with your surgical care,
        medical treatment, and document recording.
        The information also supports analytical and regulatory purposes.
        Our data is stored in the US.
        </div>
        <br />
        <table style={{border: '1px solid black', borderCollapse: 'collapse'}}>
          <tr>
            <th>Germany - EU – MDR Requirement</th>
            <th>Safeguards in place to protect your data</th>
            <th>Review the safeguards in place here:</th>
          </tr>
          <tr>
            <td>Amazon WEB Services (AWS)
            <br/>
            79539 Rippee Road
            Boardman, OR 97818
            <br />
            +11 206-266-4064
            </td>
            <td>AWS Shield - Advanced</td>
            <td>AWS Web site</td>
          </tr>
        </table>
        </DialogContentText>
        <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="col-md-12 mb-5" style={{align: 'center'}}>
          <div className="col-md-4">
          <TextField 
            className={classes.textField}
            placeholder="Patient Name"
            name="patient_name"
            type="text"
            defaultValue={`${context.formData.Patient_Last_Name}, ${context.formData.Patient_First_Name}`}
            size="medium"
            fullWidth
            InputProps={{
              readOnly: true,
              classes: {
                input: classes.initial
              }
            }}
            fullWidth
            helperText="Patient Name"
            required
          />
          </div>
          <div className="col-md-4">
            <TextField
              className={classes.textField}
              placeholder="Patient Date of Birth"
              name="patient_name"
              type="text"
              size="medium"
              defaultValue={
                user.Country === "US" ?
                `${formData.Consent_DOB}`
                :
                `${context.formData.Patient_DOB}`}
              fullWidth
              InputProps={{
                readOnly: true,
                classes: {
                  input: classes.initial
                }
              }}
              fullWidth
              helperText="Patient Date of Birth"
              required
            />
          </div>
          <div className="col-md-4">
            <TextField
              className={classes.textField}
              placeholder="Surgeon Name"
              name="patient_name"
              type="text"
              size="medium"
              defaultValue= {`${user.Last_Name}, ${user.First_Name}` }
              fullWidth
              InputProps={{
                readOnly: true,
                classes: {
                  input: classes.initial
                }
              }}
              fullWidth
              helperText="Surgeon Name"
              required
            />
          </div>
        </div>
        <br />
        <p>Title of Consent: <strong>Limited Permission to Use Medical Information</strong></p>
          <ol style={{fontSize: '.85em', color: 'black', fontWeight: 'bold', fontFamily: 'arial'}}>
          <li className="col-md-12 mb-3">
          <div className="col-md-9">
          I confirm that I have read and understood the information brochure "Lento PtoleMedic System,
          Rel. 1.3." and PtoleKnee Surgical Guide.
          I have had the opportunity to consider the information,
          ask questions of my surgeon,
          and have had my questions answered satisfactorily.
          </div>
          <div className="col-md-3">
            <TextField
            placeholder="Initial"
            name="initial_1"
            type="text"
            size="medium"
            required
            inputProps={{
              maxLength: "2"
            }}
            InputProps={{
              classes: {
                input: classes.initial
              },
              onChange: onChange
            }}
            variant="outlined"
            />
          </div>
          </li>
          <br />
          <li className="col-md-12 mb-3">
            <div className="col-md-9">
          <strong>I understand that my consent is voluntary and that I am free to withdraw my consent without giving any reason,
           without my medical care or legal rights being affected.</strong>
            </div>
            <div className="col-md-3">
             <TextField
             placeholder="Initial"
             name="initial_2"
             type="text"
             size="medium"
             required
             inputProps={{
               maxLength: "2"
             }}
             InputProps={{
               classes: {
                 input: classes.initial
               },
               onChange: onChange
             }}
             variant="outlined"
             />
           </div>
          </li>
          <br />
          <li className="col-md-12">
            <div className="col-md-9">
            <strong>I understand that relevant sections of my medical notes and data collected during my treatment may be looked at by individuals from Lento Medical Innovation,
            Inc., from local regulatory authorities, or from the EU/MDR authorities, where it is relevant to my exposure to the use of the custom PtoleKnee Surgical Guides.
            I permit Lento to access to my records as described.</strong>
            </div>
            <div className="col-md-3">
              <br />
            <TextField
            placeholder="Initial"
            name="initial_3"
            type="text"
            size="medium"
            required
            inputProps={{
              maxLength: "2"
            }}
            InputProps={{
              classes: {
                input: classes.initial
              },
              onChange: onChange
            }}
            variant="outlined"
            />
           </div>
          </li>
          <br />
          <li className="col-md-12">
            <div className="col-md-9">
            <strong>My surgeon explained the potential risks and benefits of using the PtoleMedic System to make PtolerKnee Surgical Guides for my knee surgery,
            and I agree with their use.</strong>
            </div>
            <div className="col-md-3">
              <br />
              <TextField
              placeholder="Initial"
              name="initial_4"
              type="text"
              size="medium"
              required
              inputProps={{
                maxLength: "2"
              }}
              InputProps={{
                classes: {
                  input: classes.initial
                },
                onChange: onChange
              }}
              variant="outlined"
              />
            </div>
          </li>
          <li className="col-md-12">
            <div className="col-md-9">
          <strong>I understand and agree that my surgeon may decide not to use the PtoleKnee Surgical Guide during my surgery if it became necessary.
            If this occurs, my surgery is done in the usual way per my surgeon's discretion.</strong>
            </div>
            <div className="col-md-3">
            <br />
            <TextField
            placeholder="Initial"
            name="initial_5"
            type="text"
            size="medium"
            required
            inputProps={{
              maxLength: "2"
            }}
            InputProps={{
              classes: {
                input: classes.initial
              },
              onChange: onChange
            }}
            variant="outlined"
            />
            </div>
          </li>
         </ol>
        </div>
        <div className="col-md-12">
          <div className="row">
            <TextField 
              className={classes.textField}
              placeholder="Patient Name"
              helperText="Patient Name"
              name="patient_name"
              defaultValue= {`${formData.Patient_Last_Name}, ${formData.Patient_First_Name}` }
              type="text"
              size="large"
              InputProps={{
              classes: {
                input: classes.resize
              }
            }}
              required
            />
            <Input
            className={classes.textField}
            placeholder="Date"
            name="email"
            helperText="Date"
            defaultValue={
                user.Country === "US" ?
                `${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`}/${year}`

                :
                `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`}`
            }
            size="large"
            InputProps={{
              classes: {
                input: classes.resize
              }
            }}
            required
            />
            <TextField
              className={classes.textField}
              placeholder="Patient Signature"
              name="signature"
              type="text"
              size="medium"
              helperText="Patient Signature"
              InputProps={{
                classes: {input: classes.signature},
                onChange: onChange
              }}
              required
            />
          </div>
          <div className="row">
            <TextField
              className={classes.textField}
              placeholder="Witness Name"
              helperText="Witness Name"
              name="witness_name"
              type="text"
              size="large"
              defaultValue= {`${user.Last_Name}, ${user.First_Name}` }
              InputProps={{
                readOnly: true,
                classes: {
                input: classes.resize
              }
              }}
              required
            />
            <Input
            className={classes.textField}
            placeholder="Date"
            name="email"
            defaultValue={
              user.Country === "US" ?
              `${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`}/${year}`

              :
              `${year}/${month<10?`0${month}`:`${month}`}/${date<10?`0${date}`:`${date}`}`

            }
            size="large"
            InputProps={{
              classes: {
                input: classes.resize
              },
              required: true
            }}
            required
            />
            <TextField
              className={classes.textField}
              placeholder="Witness Signature"
              name="witness_name"
              type="text"
              size="large"
              defaultValue= {`${user.Last_Name}, ${user.First_Name}` }
              InputProps={{
                classes: {input: classes.signature}
              }}
              helperText="Witness Signature"
              required
            />
          </div>
          <div className="col-md-10">
            <Input
            className={classes.textField}
            placeholder="Patient Email"
            helperText="Patient Email"
            onChange={onChange}
            name="Patient_Email"
            type="email"
            size="large"
            fullWidth
            InputProps={{
              classes: {
                input: classes.resize,
                fontSize: '2em'
              }
            }}
            required
            />
          </div>
           
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" 
                disabled={
                  formArray.some(x => x === "" || x === undefined)

                  ||

                  !validateEmail(formData.Patient_Email)
                }
                />
              }
              label="I agree and have read the consent form."
              labelPlacement="end"
            />
            <div>
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" onChange={handleScreenShot}
                disabled={
                  formArray.some(x => x === "" || x === undefined)

                  ||

                  !validateEmail(formData.Patient_Email)
                }
                />
              }
              label="I'm not a robot"
              labelPlacement="end"
            />
            </div>
            
        </div>
      </DialogContent>
        <div style={{fontSize: '.7em', textAlign: 'center'}}>
        Lento Medical Innovation, Inc.: 15110 Northwest Fwy., Suite 150, Houston, TX, USA
        Phone: +1 510.413.3230 Fax: +1 510.413.7020 email: support@lentomedical.com
        </div>
        <div style={{fontSize: '.6em', textAlign: 'center'}}>
          Consent form date of issue: September 02, 2020 - Consent form version: Rev.: A
        </div>
        <div className="col-md-12">
        </div>
      </div>
      <DialogActions>
        <Button onClick={handleSubmit} disabled={!screenshotted || !checked || loading
          ||

          formArray.some(x => x === "" || x === undefined)

          ||

          !validateEmail(formData.Patient_Email)
        } color="primary" style={{fontSize: '1em'}}>
        {loading ?
          <div>
          Submitting
          <CircularProgress  size={15}/>
          </div>
        : "Submit"}
        </Button>
        <Button onClick={handleClose} color="primary" style={{fontSize: '1em'}}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  </div>
  );
}

export default ConsentForm;
