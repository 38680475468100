import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { Link, useParams } from 'react-router-dom';
import { api_url } from "../../authorize";

import MaterialTable from 'material-table';
import Input from '@material-ui/core/Input';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import Modal from '@material-ui/core/Modal';

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    Permissions: forwardRef((props, ref) => <InfoIcon {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48
    },
  });

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

const ImplantList = () => {
  const { token, authBody, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const [file, setFile] = useState({selectedFile: null})
  let fileName;
  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }
  const [data, setData] = useState({})
  let { surgeonid } = useParams()
  const [open, setOpen] = useState(false)
  const handleOpen =() => {
    setOpen(true)
  }

  const onFileChange = (e) => {
    e.preventDefault()
    setFile({selectedFile: e.target.files[0]})
    console.log(file)
  }

  const onFileUpload = () => {
    const txtFile = new FormData();

    txtFile.append(
      "Implant",
      file.selectedFile,
      file.selectedFile.name
    )

    axios({
      url: `https://${api_url}/api/implant_info`,
      method: 'post',
      data: txtFile,
      headers: {
        'x-access-token' : token
      }
    }).then(
      res => {
        if(res.data.message){
          alert(res.data.message)
        }
        else if(res.data.notLoggedIn){
          setAuthenticated(false)
          setAuthBody({role: 'visitor'})
          alert("You have been logged out due to inactivity. Please log in again.")
        }
        else{
          setFile({selectedFile: null})
          window.location.reload()
        }

      }
    ).catch(err => alert(err));

    console.log(file.selectedFile)
  }

  if(file.selectedFile){
    fileName = file.selectedFile.name
  }
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/implant_info`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setState((prevState) => ({
              ...prevState, data: res.data
            }))
          }

        }
      );
    };

    fetchData();
  }, []);
  console.log(state)

  const [state, setState] = useState({
    columns: [
      { title: 'Company Name', field:'company_name', filtering: false,
          cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'Product', field:'product_name', filtering: false,
          cellStyle: {
              fontSize: '.7em'
          }
      },
      { title: 'Body Part', field:'body_key', filtering: false,
        cellStyle:{
          fontSize: '.7em'
        }
      }

    ]
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = e => {
    setState({...state, dialogOpen: false})
  }

  const { gilad, jason, antoine } = state;
  console.log(file)
  const classes = useStyles();
  console.log(state)
    return(
      <div className="w-50 mx-auto mt-5" >
          <div>
           <label for="file"
             style={{display:'inline-block', cursor: 'pointer',
             backgroundColor: '#3f51b5',
             color: 'white',
             fontSize: '2em'
              }}
           >Choose file to upload: </label>
           {fileName ? ` ${fileName}` : " No File Chosen"}
           <input style={{fontWeight: 'bold'}} type="file" id="file" name="file" onChange={onFileChange} />
          </div>
          <div className="mb-2">
           <Button disabled={!fileName} color="primary" variant="contained" onClick={onFileUpload}>Upload</Button>
          </div>
      {
      hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ?
      <div>
      <MaterialTable
        title="Implant"
        columns={state.columns}
        data={state.data}
        icons={tableIcons}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        options={{
          actionsColumnIndex: 9,
          sorting: true,
          headerStyle: {
            fontSize: '1em'
          }
        }}
        style={{
          fontSize: '20px'
        }}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    const dataDelete = [...state.data];
                    const index = oldData.tableData.id;
                    axios({
                      url: `https://${api_url}/api/implant_info?id=${oldData.id}`,
                      method: 'delete',
                      headers: {
                        'x-access-token' : token
                      }
                    }).then(res=> {
                      if(res.data.message){
                        alert(res.data.message)
                        window.location.reload()
                      }
                      else if(res.data.notLoggedIn){
                        setAuthenticated(false)
                        setAuthBody({role: 'visitor'})
                        alert("You have been logged out due to inactivity. Please log in again.")
                      }
                      else{
                        dataDelete.splice(index, 1);
                        setState({...state, data: dataDelete})
                      }
                    })

                    resolve();
                }, 1000);
              })
        }}
        />
        </div>
        :

        hasRole(profile, ['write_admin']) ?

        <MaterialTable
          title="Implant"
          columns={state.columns}
          data={state.data}
          icons={tableIcons}
          localization={{
              pagination: {
                  labelDisplayedRows: '{from}-{to} of {count}'
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected'
              },
              header: {
                  actions: 'Actions'
              },
              body: {
                  emptyDataSourceMessage: 'No records to display',
                  filterRow: {
                      filterTooltip: 'Filter'
                  }
              }
          }}
          options={{
            actionsColumnIndex: 9,
            sorting: true,
            headerStyle: {
              fontSize: '1em'
            }
          }}
          style={{
            fontSize: '20px'
          }}
          />

        :

      <MaterialTable
        title="G Code"
        columns={state.columns}
        data={state.data}
        icons={tableIcons}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: 'Actions'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        options={{
          actionsColumnIndex: 9,
          sorting: true,
          headerStyle: {
            fontSize: '1em'
          }
        }}
        style={{
          fontSize: '20px'
        }}
        />
      }
        </div>

    );
};

export default ImplantList;
