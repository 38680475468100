export const roles = [
  { roleName: "Surgeon", roleId: "Surgeon"},
]

export const fields = [
  { fieldName: "First Name", fieldId: "First_Name", fieldType: "text" },
  { fieldName: "Middle Name", fieldId: "Middle_Name", fieldType: "text" },
  { fieldName: "Last Name", fieldId: "Last_Name", fieldType: "text" },
  { fieldName: "Office Name*", fieldId: "Office_Name", fieldType: "text" },
  { fieldName: "Email Address*", fieldId: "Email_Address", fieldType: "text" },
  { fieldName: "Phone*", fieldId: "Phone", fieldType: "text" },
]

/*export const dropdownfields = [
  {
    fieldName: "Shipping Address*",
    fieldId: "Shipping_Address",
    fieldType: "dropdown",
    fieldOptions:  [
      'Hospital Information',
      'TKR 2',
      'Surgeon Office'
    ]
  },
  {
    fieldName: "Billing Address*",
    fieldId: "Billing_Address",
    fieldOptions: [
      'Distributor Information',
      'Hospital',
      'Surgeon Office'
    ]
  },
  {
    fieldName: "Implant Manufacturer and Brand Name*",
    fieldId: "Implant_Manufacturer_and_Brand_Name",
    fieldOptions: [
      'Striker Triathlon(Knee)',
      'Smith & Nephew Genesis 2(Knee)',
      'DJO 3D Knee (Knee)',
      'Biomet Vanguard (Knee)'
    ]
  },
  {
    fieldName: "TKA Alignment*",
    fieldId: "TKA_Alignment",
    fieldOptions: [
      'Mechanical Alignment',
      'Neutral Boundary Alignment'
    ]
  }
]*/
