import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { CaseFormDataContext } from './CreateCaseContext';



const SurgeryPref = (props) => {
  const context = useContext(CaseFormDataContext)
  const { surgeryPref, setState, setStep, nextStep, formData } = context
  const bodyPrefs = context.formData.body_pref

  let existHip = false
  let existKnee = false

  console.log(formData)
  const onChange = (e) => {
    e.preventDefault()
    setState({surgeryPref: e.target.value})
    nextStep()
    console.log(e.target.value)
    console.log(setState)
  }

  if(formData.body_pref.length == 1){
    setState({surgeryPref: "Knee_1"})
    nextStep()
  }

  switch(formData.surgeryType) {
    case ("TKR"):
      return (
        <div className="row h-50 justify-content-center align-items-center">
      {
          <FormControl component="fieldset">
            <FormLabel component="legend" style={{fontSize: '3em', textAlign: 'center', color: 'black'}}>Choose Preference</FormLabel>
            <RadioGroup aria-label="Preference" name="surgeryPref" value={surgeryPref} onChange={onChange}>
            {
                bodyPrefs && bodyPrefs.map((bodyPref, i) => {
                  if (bodyPref.body_key.includes("Knee") ){

                  return(
                      <div key={i} className="pb-3">
                        <input type="radio" id={bodyPref.body_key} type="radio" name="surgeryPref" value={bodyPref.body_key}
                          onChange={onChange} style={{display:'none'}}
                        />
                        <label htmlFor={bodyPref.body_key}
                        className="col-md-12"
                        style={{
                          display:'inline-block', cursor: 'pointer',
                          backgroundColor: '#3f51b5',
                          color: 'white', padding: '20px', borderRadius: '2%'
                        }}>
                          <div style={{fontSize: '25px', textAlign: 'center'}}>
                            {bodyPref.body_key.replace("_"," ")}
                          </div>
                          <div style={{paddingRight:".7rem", fontWeight: '100',}}>
                            <div className="row">
                              <p style={{color:'white', fontWeight: 'bold',
                              fontSize: '1.3em', marginLeft: '2rem'}}>Distributor Name:</p>
                              <span style={{fontSize: '1em', marginLeft: '1rem', lineHeight: '2',
                                fontStyle: 'italic', letterSpacing:'1.6px'}}>
                              <p>{bodyPref.Distributor.Office_Name}</p>
                              </span>
                            </div>
                            <div className="row">
                            <p style={{color:'white', fontWeight: 'bold',
                            fontSize: '1.3em', marginLeft: '2rem'}}>Hospital Name: </p>
                              <span style={{fontSize: '1em', marginLeft: '1rem', lineHeight: '2',
                                fontStyle: 'italic', letterSpacing:'1.6px'}}>
                              <p>{bodyPref.Hospital.Office_Name}</p>
                              </span>
                            </div>
                            <div className="row">
                            <p style={{color:'white', fontWeight: 'bold',
                            fontSize: '1.3em', marginLeft: '2rem'}}>MRI Center Name: </p>
                              <span style={{fontSize: '1em', marginLeft: '1rem', lineHeight: '2',
                                fontStyle: 'italic', letterSpacing:'1.6px'}}>
                              <p>{bodyPref.MRI_Center.Office_Name}</p>
                              </span>
                            </div>
                            <div className="row">
                            <p style={{color:'white', fontWeight: 'bold',
                            fontSize: '1.3em', marginLeft: '2rem'}}>Implant Name: </p>
                              <span style={{fontSize: '1em', marginLeft: '1rem', lineHeight: '2',
                                fontStyle: 'italic', letterSpacing:'1.6px'}}>
                              <p>{bodyPref.Implant_Manufacturer.implant_name.replace("_", " ")}</p>
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                  )
                }
                })

            }
            </RadioGroup>
          </FormControl>

      }
      </div>
      )
    case ("THR"):
      return(
        <div>
          <FormControl component="fieldset" required="true">
            <FormLabel component="legend">Preference</FormLabel>
            <RadioGroup aria-label="gender" name="surgeryPref" value={surgeryPref} onChange={onChange}>
              {
                  bodyPrefs && bodyPrefs.map((bodyPref, i) => {
                    if (bodyPref.body_key.includes("Hip") ){

                    return(
                      <div key={i}>
                        <FormControlLabel
                        value={bodyPref.body_key}
                        control={<Radio />}
                        label={bodyPref.body_key.replace("_"," ")} />
                      </div>
                    )
                  }
                  })

              }
            </RadioGroup>
          </FormControl>
        </div>
      )
    case ("TAR"):
      return(
        <div>
          <FormControl component="fieldset" required="true">
            <FormLabel component="legend">Preference</FormLabel>
            <RadioGroup aria-label="Preference" name="surgeryPref" value={surgeryPref} onChange={onChange}>
              <FormControlLabel value="Ankle_1" control={<Radio />} label="A1" />
              <FormControlLabel value="Ankle_2" control={<Radio />} label="A2" />
              <FormControlLabel value="Ankle_3" control={<Radio />} label="A3" />
              <FormControlLabel
                value="disabled"
                disabled
                control={<Radio />}
                label="(Disabled option)"
              />
            </RadioGroup>
          </FormControl>
        </div>
      )

    default:
      return null
  }
};

export default SurgeryPref;
