import React, { memo, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { roles } from './../../activeFormData';
import styles from './../../styles';
import {FormGroup, ControlLabel, FormControl, Modal} from 'react-bootstrap';
import { Link, Redirect } from "react-router-dom"

import { CaseFormDataContext } from './CreateCaseContext';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";

import CreateCaseTitle from './CreateCaseTitle';
import CreateCaseBody from './CreateBody';
import CreateCaseInput from './CreateInput';
import Button from '@material-ui/core/Button';
import SurgeryType from './SurgeryType';
import PatientInfo from './PatientInfo';
import SurgeryPref from './SurgeryPref';
import Confirm from './Confirm';

const CaseForm = memo((props) => {
  const { token, user, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const context = useContext(CaseFormDataContext);
  const [options, setOptions] = useState({
  })
  const { step, nextStep, prevStep, surgeryType, surgeryPref, setState, formData } = context
  const values = { surgeryType, surgeryPref }
  const [data, setData] = useState([]);
  const bodyPrefs =  context.formData.body_pref

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        method: 'get',
        url: `https://${api_url}/api/surgeon?email=${user.Email_Address}`,
        headers: {
          'x-access-token' : token
        }
      }).then(
        res => {
          if(res.data.message){
            alert(res.data.message)
          }
          else if(res.data.notLoggedIn){
            setAuthenticated(false)
            setAuthBody({role: 'visitor'})
            alert("You have been logged out due to inactivity. Please log in again.")
          }
          else{
            setState(res.data)
          }
        }
      );
    };
    fetchData();
  }, []);

  const onChange = (option) => {
    const { name, value } = option.target
    setOptions({...options,
      [name] : value
    })
  }

  /*const data = {
    "bigform": context.formData,
    "options": options
  }*/

  //console.log(data.map(bodyPref => bodyPref))


  /*const handleOptions = () => {
    for(i=0; i<data.length; i++) {
        var item =
    }
  }*/
  switch(step){
    case 1:
      return(
        <SurgeryType />
      )
    case 2:
      return(
        <SurgeryPref />
      )
    case 3:
     return(
       <PatientInfo />
     )
    case 4:
      return(
        <Redirect to={`/case/${formData.case_id}`} />
      )
    default:
     return null
  }
})

export default CaseForm;
