import React, { memo } from 'react';

import styles from './../../styles';

const MRITitle = memo((props) => {
  const { roleName } = props
  return(
    <div style={styles.titleWrapper}>
      <h2 style={styles.title}>{roleName}</h2>
    </div>
  )
})

export default MRITitle
