import React, { memo, useContext, useState } from 'react';
import { CaseFormDataContext } from './CreateCaseContext';
import styles from './../../styles';

const CreateCaseInput = memo((props) => {
  const context = useContext(CaseFormDataContext)
  const { fieldId, fieldType, roleId, nameCombine, addyCombine, preview, onlyShow } = props
  let { formData, editing, setState } = context
  let existPref = false;
  const stateKey = `${roleId}_${fieldId}`
  const bodyPrefs = formData.body_pref
  const onChange = (e) => {
    setState({[stateKey] : e.target.value})
  }



  // if (formData[stateKey] == formData[`Distributor_${fieldId}`]){
  //   return 1
  // }

  return(
    <div>
    {
      bodyPrefs && bodyPrefs.map((bodyPref, i) => {
         if (bodyPref.body_key == formData.surgeryPref & !existPref){

           existPref = true
           formData[`Distributor_${fieldId}`] = bodyPref.Distributor[`${fieldId}`]
           formData[`Hospital_${fieldId}`] = bodyPref.Hospital[`${fieldId}`]
           formData[`MRI_Center_${fieldId}`] = bodyPref.MRI_Center[`${fieldId}`]
           switch(roleId) {
             case "Surgeon":
               switch(fieldType) {
                 case "text":
                   if(nameCombine === "true"){
                     return(
                       <pre
                       type="text"
                       id={fieldId}
                       style={styles.textInput}
                       value={formData[stateKey] = formData['Surgeon'][`${fieldId}`]}
                       >{bodyPref["Surgeon"]["Last_Name"]}, {bodyPref["Surgeon"]["First_Name"]}</pre>
                     )
                   }
                   else {
                     return(
                       <pre
                         type="text"
                         id={fieldId}
                         style={styles.textInput}

                       >{bodyPref['Surgeon'][`${fieldId}`]}</pre>
                     )
                   }


                 case "textarea":
                   return (
                     <pre
                       type="textarea"
                       id={fieldId}
                       style={styles.textareaInput}
                       value={formData[stateKey] = formData['Surgeon'][`${fieldId}`]}

                     >{bodyPref["Surgeon"]["Street"]}
                       <br />
                     {bodyPref["Surgeon"]["City"]},
                     {" " + bodyPref["Surgeon"]["State_Province"]} {bodyPref["Surgeon"]["Postal_Code"]},
                     {" " + bodyPref["Surgeon"]["Country"]}
                   </pre>
                 )
                 default:
                   return (
                     null
                   )
                 }
             case "Distributor":
               switch(fieldType) {
                 case "text":
                   if(nameCombine){
                       return(
                         <pre
                         type="text"
                         id={fieldId}
                         style={onlyShow ? styles.textInput : {display: "none"}}

                         >{bodyPref["Distributor"]["Last_Name"]}, {bodyPref["Distributor"]["First_Name"]}</pre>
                       )
                   }
                   else if(fieldId.includes("Middle_Name")){
                     return null
                   }


                 case "textarea":
                   if(addyCombine === "true"){
                     return (
                       <pre
                       type="textarea"
                       id={fieldId}
                       style={onlyShow ? styles.textareaInput : {display: "none"}}
                       value={formData[`Distributor_${fieldId}`] = bodyPref['Distributor'][`${fieldId}`]}
                       >{bodyPref["Distributor"]["Street"]}
                         <br />
                       {bodyPref["Distributor"]["City"]},
                       {" " + bodyPref["Distributor"]["State_Province"]} {bodyPref["Distributor"]["Postal_Code"]},
                       {" " + bodyPref["Distributor"]["Country"]}
                     </pre>
                     )
                   }
                 default:
                 if(onlyShow){
                   return (
                     <pre
                     type="textarea"
                     id={fieldId}
                     style={styles.textInput}
                     value={formData[`Distributor_${fieldId}`] = bodyPref['Distributor'][`${fieldId}`]}

                     >{bodyPref['Distributor'][`${fieldId}`] ? bodyPref['Distributor'][`${fieldId}`] : <br/>}</pre>
                   )
                 }
                 }
             case "Hospital":
               switch(fieldType) {
                 case "text":
                   if(nameCombine === "true"){
                     return(
                       <pre
                       type="text"
                       id={fieldId}
                       style={styles.textInput}

                       >{bodyPref["Hospital"]["Last_Name"]}, {bodyPref["Hospital"]["First_Name"]}</pre>
                     )
                   }

                 case "textarea":
                 if(addyCombine === "true"){
                   return (
                     <pre
                     type="textarea"
                     id={fieldId}
                     style={styles.textareaInput}

                     >{bodyPref["Hospital"]["Street"]}
                     <br />
                     {bodyPref["Hospital"]["City"]},
                     {" " + bodyPref["Hospital"]["State_Province"]} {bodyPref["Hospital"]["Postal_Code"]},
                     {" " + bodyPref["Hospital"]["Country"]}
                     </pre>
                   )
                 }
                 default:
                   return (
                     <pre
                       type="text"
                       id={fieldId}
                       style={styles.textInput}

                     >{bodyPref['Hospital'][`${fieldId}`] ? bodyPref['Hospital'][`${fieldId}`]  : <br/>}</pre>
                   )
               }
             case "MRI_Center":
               switch(fieldType) {
                 case "text":
                 if(nameCombine === "true")
                   return(
                     <pre
                       type="text"
                       id={fieldId}
                       style={styles.textInput}

                     >{bodyPref["MRI_Center"]["Last_Name"]}, {bodyPref["MRI_Center"]["First_Name"]}</pre>
                   )

                 case "textarea":
                   if(addyCombine === "true"){
                     return (
                       <pre
                       type="textarea"
                       id={fieldId}
                       style={styles.textareaInput}

                       >{bodyPref["MRI_Center"]["Street"]}
                       <br />
                       {bodyPref["MRI_Center"]["City"]},
                       {" " + bodyPref["MRI_Center"]["State_Province"]} {bodyPref["MRI_Center"]["Postal_Code"]},
                       {" " + bodyPref["MRI_Center"]["Country"]}
                       </pre>
                     )
                   }
                 default:
                 if(preview){
                   return (
                     <pre
                     type="textarea"
                     id={fieldId}
                     style={styles.textInput}

                     >{bodyPref['MRI_Center'][`${fieldId}`] ? bodyPref['MRI_Center'][`${fieldId}`]  : <br/> }</pre>
                 )
               }
             }
           default:
             return null
         }

       }}
         )

       }
     </div>
   )
  // switch(fieldType) {
  //   case "text":
  //     return (
  //         <pre
  //         type="text"
  //         id={fieldId}
  //         style={styles.textInput}
  //         value={formData[stateKey] = formData.bodyPref}
  //         onChange={onChange}
  //       >{formData.bodyPref}</pre>
  //
  //   )
  //   case "textarea":
  //     return (
  //         <pre
  //         type="textarea"
  //         id={fieldId}
  //         style={styles.textInput}
  //         value={formData[stateKey]}
  //       />
  //   )
  //   default:
  //     return null
// }
})

export default CreateCaseInput
