import React, {useState,  useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';


import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';

const theme = createMuiTheme({
  typography: { h6: {fontSize: '1.4em'},
                body1: {fontSize: '1em'},
  button: {fontSize: '3em'}
  },
});

const ResetDialog = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(ActiveFormDataContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <ThemeProvider theme={theme}>
    <div>
      <button className="float-left btn btn-primary" style={{zIndex: 400}} bsStyle="primary" onClick={handleClickOpen}>
        <span style={{zIndex: 400}} className="glyphicon glyphicon-chevron-left"/>
      </button>
      <button className="float-right btn btn-primary" style={{zIndex: 400}} bsStyle="primary" onClick={handleClickOpen}>
        <span style={{zIndex: 400}} className="glyphicon glyphicon-chevron-right"/>
      </button>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"CAUTION!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Due to the Femur and Tibia being adjusted separately, this function has been disabled.
          In order to use this function you must click the RESET button located on the bottom right of the screen.

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </ThemeProvider>
    </>
  );
}

export default ResetDialog
