import React, { createContext, useState, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import jwt_decode from "jwt-decode";
export const AuthorizeContext = createContext();

export const AuthorizeProvider = (props) => {

  const [authenticated, setAuthenticated] = useState(() => JSON.parse(sessionStorage.getItem('authenticated')));
  const [authBody, setAuthBody] = useState(() => JSON.stringify(sessionStorage.getItem('token')));
  // const [authBody, setAuthBody] = useState({
  //   user: "",
  //   role: "visitor",
  //   token: ""
  // });

  let token = authBody ? sessionStorage.getItem('token') : ""
  let user = token ? jwt_decode(token).user : ""
  let role = token ? jwt_decode(token).role : "visitor"

  let decoded = ""


    useEffect(() => {
      const data = sessionStorage.getItem('token')
      const auth = sessionStorage.getItem('authenticated')
      if(data){
        decoded = jwt_decode(authBody)
        setAuthBody( decoded || "visitor");
        console.log(decoded)
        console.log(user)
        console.log(role)
        console.log("Please Work", authBody)
      }
      if(auth){
        setAuthenticated(JSON.parse(auth))
        console.log("Testing 2", authenticated)
      }
    }, []);

    // const handleAuthBody = (object) => {
    //   setAuthBody({ object })
    // }

    //
    // const handleSetState = (object) => {
    //   const { surgeonData, step } = state
    //   setState({ surgeonData: {...surgeonData, ...object}, setState})
    // }



  // const value = {...state, step, setState: handleSetState, nextStep }


  const defaultContext = {
    authenticated,
    setAuthenticated,
    authBody,
    setAuthBody,
    token,
    user,
    role
  }
    return (<AuthorizeContext.Provider value={defaultContext}>{props.children}</AuthorizeContext.Provider>)

}
