import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../RootContext";
import { Link, useParams } from 'react-router-dom';
import logo from './../img/lento_logo.jpg';
import moment from 'moment';

import { api_url } from "../authorize";

import MaterialTable from 'material-table';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      color: 'white',
      height: 48
    },
  });

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });


const AbortList = () => {
  const { token, role, authBody, setAuthBody, setAuthenticated } = useContext(AuthorizeContext);
  const [ loading, setLoading] = useState(true)
  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const [data, setData] = useState({})
  let { surgeonid } = useParams()
  const classes = useStyles();

  const hasRole = (profile, roles) =>{
    return roles.includes(role)
  }


  useEffect(() => {
    const fetchData = async () => {
      if(role.includes("admin")){
        const admin = await axios({
          url: `https://${api_url}/api/case_list?status=abort`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setData((prevState) => ({
          ...prevState, data: admin.data
        }))
      }
      else if(role.includes("hospital")){
        const hos = await axios({
          url: `https://${api_url}/api/hospital_list?for_hospital=true&status=abort`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setData((prevState) => ({
          ...prevState, data: hos.data
        }))
      }
      else if(role.includes("distributor")){
        const dis = await axios({
          url: `https://${api_url}/api/distributor_list?for_distributor=true&status=abort`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setData((prevState) => ({
          ...prevState, data: dis.data
        }))
      }
      else{
        const res = await axios({
          url: `https://${api_url}/api/case_list?surgeon=${surgeonid}&status=abort`,
          method: 'get',
          headers: {
            'x-access-token' : token
          }
        })
        setLoading(false)
        setData({data: res.data})
      }


    }
    fetchData();
  }, []);

  const [state, setState] = useState({
    columns: [
      { title: 'Case #', field:'case_id',  editable: "never", render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )}, cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name}
          </div>
        )
      }, cellStyle: { fontSize: '.7em'}
    },
      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )
      }, cellStyle:{ fontSize: '.7em'}
    },
      { title: 'DOPO', field:'dateCreated', editable: "never", type:'date', cellStyle: {fontSize: '.7em'},
        customSort: (a , b) => {
          a = moment(a.dateCreated).format();
          b = moment(b.dateCreated).format();
          return b > a ? 1 : -1;
        }
      },
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never",  type: 'date', cellStyle: {fontSize: '.7em'}
      , defaultSort: hasRole(profile, ['surgeon']) ? 'asc' : null,
        customSort: (a , b) => {
          a = moment(a.Patient.Date_of_Surgery).format();
          b = moment(b.Patient.Date_of_Surgery).format();
          return b > a ? 1 : -1;
        }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", cellStyle: {fontSize: '.7em'}},
      { title: 'Surgery Side', field:'Patient.Side_of_Body', editable: "never", cellStyle: {fontSize: '.7em'}},
      { title: 'Status', field:'case_status',
        lookup: {
                    13 : 'Aborted',
                    'Abort': 'Aborted',
                    'aborted': 'Aborted'

            }
            ,
        cellStyle: {
            backgroundColor: "#990000",
            backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)",
            color: 'white',
            textAlign: 'center',
            fontSize: '.7em'

        }
      }
    ]
  });

  if(state.data){
    if(state.data.notLoggedIn){

      setAuthenticated(false)
      setAuthBody({role: 'visitor'})
      alert("You have been logged out due to inactivity. Please log in again.")
    }
  }

  else if(loading){
    return(
      <div id="p5_loading" style={{position:"fixed", width: '100%', zIndex: '3',
              height: "100%", left: 0, top: 0, backgroundColor: "white"
            }}>
          <img src={logo} alt="logo"
          style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"50%"}}
          />
      </div>

    )
  }
  console.log(data)
  return(
    <ThemeProvider theme={theme}>
      <MaterialTable
        title="Aborted List"
        columns={state.columns}
        data={data.data}
        icons={tableIcons}
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        options={{
          actionsColumnIndex: 9,
          sorting: true,
          pageSize: 20,
          headerStyle: {
            fontSize: '.8em'
          },
          searchFieldStyle: {
            fontSize: '2rem'
          }
        }}
        style={{
          fontSize: '20px'
        }}
        />
      </ThemeProvider>

  );
};

export default AbortList;
