import React, { useState,  forwardRef, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";

import MaterialTable from 'material-table';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Link,
  useParams
} from "react-router-dom";

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} fontSize="large"/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // const useStyles = makeStyles({
  //   root: {
  //     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  //     border: 0,
  //     borderRadius: 3,
  //     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  //     color: 'white',
  //     height: 48,
  //     padding: '0 30px',
  //   },
  // });

  const theme = createMuiTheme({
    typography: { h6: {fontSize: '1.4em'}},
  });

const DistributorCaseList = () => {
  const { token, authBody } = useContext(AuthorizeContext);
  const [data, setData] = useState({});
  let { distributorID } = useParams();

  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  console.log(distributorID)
  console.log(data)
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios({
        url: `https://${api_url}/api/case_list?distributor=${distributorID}`,
        method: 'get',
        headers: {
          'x-access-token' : token
        }
      });
      setData({data: res.data})
    }
    fetchData();
  }, [distributorID, token]);

  let lookup = hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ?
    {
      1: 'New Order',
      2: 'MRI',
      3: 'MRI QC',
      4: 'Planning 1',
      5: 'Planning 2',
      6: 'Surgeon Approval',
      7: 'Template Design',
      8: 'G Code',
      9: 'CNC',
      10: 'Final QC',
      11: 'Shipped'
    } :

    {
      1: hasRole(profile, ['mri_center']) ? 'Ready to Upload MRI' : 'Waiting for MRI',
      2: 'In Progress',
      3: 'In Progress',
      4: 'In Progress',
      5: 'In Progress',
      6: 'Approval Ready',
      7: 'Surgeon Approved',
      8: 'Surgeon Approved',
      9: 'Surgeon Approved',
      10: 'Surgeon Approved',
      11: 'Shipped'
    }


  const [state, setState] = useState({
    adminColumns: [
      { title: 'Case #', field:'case_id',  editable: "never", filtering: false, render:rowData => {
          return (
            <div>
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>
            </div>
          )},
          cellStyle: {
            fontSize: '.7em'
          }
      },
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", filterting: false, render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name} {rowData.Surgeon.Middle_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", filtering: false, render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )},
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'DOPO', field:'dateCreated', editable: "never", filtering: 'false', type:'date',
        cellStyle:{
          fontSize: '.7em'
        }
      },
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never", filtering: 'false', type: 'date',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", filtering: 'false',
        cellStyle: {
          fontSize: '.7em'
        }
      },
      {
        title: 'Expedite', field: 'expedite', type: 'boolean', defaultSort: 'asc'
      },
      { title: 'Status', field:'case_status', filtering: 'false',
        lookup: lookup,
        emptyValue: "New Order",
        cellStyle:
          rowData =>
          {
            console.log(rowData)
            return(rowData !== 13 ?
                      ({background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
                        backgroundColor: "#045de9",
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '.7em'})

                        :
                      ({
                        backgroundColor: "#990000",
                        backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)",
                        color: 'white',
                        textAlign: 'center',
                        fontSize: '.7em'
                      }))
          }

      },
      {
        title: 'Flag', field: 'flag_status', filtering: 'false', editable: 'never', type:"text", cellStyle:
        rowData =>
          {
            return(rowData.includes("no_flag") ?
              null

              :
              ({
                  background: "linear-gradient(to right, #ed213a, #93291e)",
                  backgroundColor: "#f46b45",
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '.7em'
              }))
          }
      },
      {
        title: 'Expert Flag', field:'expert_flag', type: 'boolean', editable: 'never'
      },
      {
        title: 'User Assigned', field:'assigned', editable: 'never', cellStyle: {
          fontSize: '.7em'
        }
      },
      {
        title: 'Femur Slot', field: 'femur_slot', filtering: 'false', type: "boolean", emptyValue: <Check />,
        cellStyle: {
          textAlign: 'center'
        }
      },
      {
        title: 'Tibia Slot', field: 'tibia_slot', filtering: 'false', type: "boolean", emptyValue: <Check />,
        cellStyle: {
          textAlign: 'center'
        }
      }
    ],
    columns: [
      { title: 'Case #', field:'case_id',  editable: "never", render:rowData => {
          return (
            <Link type="text" to={`/case/${rowData.case_id}`}>{rowData.case_id}</Link>

          )}, cellStyle:{ fontSize: '.7em'}},
      { title: 'Surgeon Name', field:'Surgeon.Last_Name', editable: "never", render: rowData => {
        return(
          <div>
            {rowData.Surgeon.Last_Name}, {rowData.Surgeon.First_Name}
          </div>
        )
      }, cellStyle:{ fontSize: '.7em'}},
      { title: 'Patient Name', field:'Patient.Last_Name', editable: "never", render: rowData => {
        return(
          <div>
            {rowData.Patient.Last_Name}, {rowData.Patient.First_Name}
          </div>
        )
      }, cellStyle:{ fontSize: '.7em'}},
      { title: 'DOPO', field:'date_of_purchase_order', editable: "never", type:'date', cellStyle:{ fontSize: '.7em'}},
      { title: 'DOS', field:'Patient.Date_of_Surgery', editable: "never",  type: 'data', cellStyle:{ fontSize: '.7em'}},
      { title: 'Surgery Type', field:'Patient.Surgery_Type', editable: "never", cellStyle:{ fontSize: '.7em'}},
      { title: 'Surgery Side', field:'Patient.Side_of_Body', editable: "never", cellStyle:{ fontSize: '.7em'}},
      { title: 'Status', field:'case_status',
        lookup: {
                  1:'Waiting for mri',
                  2: 'MRI',
                  3: 'MRI QC',
                  4: 'Planning 1',
                  5: 'Aproval Ready',
                  6: 'Surgeon Approval',
                  7: 'Manufacturing',
                  8: 'Manufacturing',
                  9: 'Manufacturing',
                  10: 'Manufacturing',
                  11: 'Shipped',
                  13: 'Aborted',
                  'Shipped':'Shipped',
                  'shipped':'Shipeed'
            },
        cellStyle: {
          background: 'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
          backgroundColor: "#045de9",
          color: 'white',
          textAlign: 'center',
          fontSize: '.7em'
        }
      },
      {
        title: 'Flag 1', field: 'flag1', lookup: {
                  1: 'Flag 1',
                  2: 'Flag 2',
                  3: 'Flag 3'
            }, editable:"never", cellStyle:{ fontSize: '.7em'}
      }
    ]
  });


  console.log(state)
  return(
    <ThemeProvider theme={theme}>
      <MaterialTable
        title="Case List"
        columns={hasRole(profile, ['super_admin', 'write_admin', 'read_admin']) ? state.adminColumns : state.columns}

        data={data.data}
        icons={tableIcons}
        editable={
          hasRole(profile, ['super_admin']) ? {
          onRowUpdate: (newData, oldData) =>
             new Promise((resolve, reject) => {
               setTimeout(() => {
                 {
                   const data = [...state.data];
                   const index = data.indexOf(oldData);
                   data[index] = newData;
                   if (newData.status > oldData.status || newData.status === 1 || newData.status === 2){
                     reject(new Error(newData))
                     alert("Error: Status will not change. Please choose statuses previous to the current status.")
                   } else {
                     setState({ ...state, data });
                   }
                 }
                 resolve();
               }, 1000);
             })
        }
        :
        null
        }
        localization={{
            pagination: {
                labelDisplayedRows: '{from}-{to} of {count}'
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected'
            },
            header: {
                actions: 'Edit'
            },
            body: {
                emptyDataSourceMessage: 'No records to display',
                filterRow: {
                    filterTooltip: 'Filter'
                }
            }
        }}
        options={{
          actionsColumnIndex: 8,
          sorting: true,
          headerStyle: {
            fontSize: '.8em'
          },
          searchFieldStyle: {
            fontSize: '2rem'
          },
          pageSize: 20
        }}
        style={{
          fontSize: '20px'
        }}
        />
      </ThemeProvider>
  );
};

export default DistributorCaseList;
