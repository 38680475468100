import React, {useMemo, useState, useEffect, useRef } from 'react';
import { roles, fields, bodyPrefs } from './../../activeFormData';
import reducer from './reducer';

export const ActiveFormDataContext = React.createContext();
export const ActiveDispatchContext = React.createContext(() => { })

const computeInitialState = () => {
  const data = {}
  for (let i=0; i < roles.length; i++) {
    for(let j=0; j < fields.length; j++) {
        data[`${roles[i].roleId}_${fields[j].fieldId}`] = ''
      }
  }
  return data
}

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}


export const ActiveFormDataProvider = (props) => {
  const formData = useMemo(
    () => computeInitialState(), [roles, fields]
  )
  const [state, setState] = useState({ formData });
  const [editing, setEditing] = useState(false);
  const [femStep, setFemStep] = useState(0);
  const [tibStep, setTibStep] = useState(0);
  const [corFemVert, setCorFemVert] = useState({vert: 0, horz: 0});
  const [corFemHorz, setCorFemHorz] = useState(0);
  const [customRotate, setCustomRotate] = useState(false);

  const prevFemDecision = usePrevious(femStep);
  const prevTibDecision = usePrevious(tibStep);

  const handleSetState = (object) => {
    const { formData } = state
    setState({ formData: {...formData, ...object, slideIndex: 0 }, corFemHorz, corFemVert, setState})
  }

  const toggleEdit = () => {
    setEditing(editing => !editing)
    console.log("Change")
  }

  const toggleRotate = () => {
    setCustomRotate(customRotate => !customRotate)
  }

  const nextFemStep = () =>{
    setFemStep(femStep => femStep + 1)
  }

  const prevFemStep = () =>{
    setFemStep(femStep => femStep - 1)
  }

  const resetFemTibStep = () => {
    setFemStep(0)
    setTibStep(0)
  }
  const nextTibStep = () =>{
    setTibStep(tibStep => tibStep + 1)
  }

  const prevTibStep = () =>{
    setTibStep(tibStep => tibStep - 1)
  }

  const value = {...state, editing,
                femStep, tibStep,
                customRotate, corFemVert, prevFemDecision, prevTibDecision,
     setState: handleSetState, toggleEdit,
                toggleRotate, setFemStep,
                nextFemStep, prevFemStep,
                nextTibStep, prevTibStep,
                setTibStep,
                resetFemTibStep, setCorFemVert
              }
  console.log(value)
  return (

    <ActiveFormDataContext.Provider value={value}>{props.children}</ActiveFormDataContext.Provider>
  )
}
