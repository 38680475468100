import React, {useMemo, useState } from 'react';
import { roles, fields, bodyPrefs } from './../../mriFormData';
export const MRIContext = React.createContext();

const computeInitialState = () => {
  const data = {}
  for (let i=0; i < roles.length; i++) {
    for(let j=0; j < fields.length; j++) {
        data[`${roles[i].roleId}_${fields[j].fieldId}`] = ''
      }
  }
  return data
}

export const MRIProvider = (props) => {
  const formData = useMemo(
    () => computeInitialState(), [roles, fields]
  )
  const [state, setState] = useState({ formData })
  console.log(state)

  const handleSetState = (object) => {
    const { formData } = state
    setState({ formData: {...formData, ...object}, setState})
  }
  const value = {...state, setState: handleSetState }
  console.log(value)
  return <MRIContext.Provider value={value}>{props.children}</MRIContext.Provider>
}
