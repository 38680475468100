import React, {useState,  useContext, useParams} from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {BrowserRouter as Redirect} from 'react-router-dom';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

import html2canvas from 'html2canvas';



import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import { api_url } from "../../authorize";

var form;

const theme = createMuiTheme({
  typography: { h6: {fontSize: '1.4em'},
                body1: {fontSize: '1em'},
  button: {fontSize: '3em'}
  },
});

const useStyles = makeStyles({
  root: { justifyContent: 'space-evenly'},
  spacing: {justifyContent: 'space-evenly'},
  button:{
    fontSize: "1.5em",
    float: "right",
    margin: "10px 30px 10px 10px"
  }});

const CaseAccept = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);
  const { token, setAuthBody, setAuthenticated, authBody } = useContext(AuthorizeContext);
  const context = useContext(ActiveFormDataContext);
  const [profile, setProfile] = useState(authBody)

  let { setState, formData } = context;
  const [accepted, setAccepted] = useState(false)
  let femur_chosen, tibia_chosen, var_or_val, absTib, absFem;

  let { femStep, tibStep } = context;

  if(formData.knee_overview_data){
    absFem = Math.abs(Math.round(Number((formData.knee_overview_data.femur_angles[femStep])) * 1000) / 1000);
    absTib = Math.abs(Math.round(Number((formData.knee_overview_data.tibia_angles[tibStep])) * 1000) / 1000);

    var_or_val = formData.knee_overview_data.var_or_val
  }

  const handleClickOpen = () => {
    let screenshot = document.querySelector("#screenshot")

    html2canvas(screenshot, {useCORS: true, width: 1980, height: 1000}).then(
      canvas => {
        canvas.toBlob((blob) => {
            console.log(blob)
            form = new FormData()
            form.append('file', blob, `surgeon_approve_${formData.slug}.png`);
            console.log(form)
        })
      });
      setOpen(true);
  };


  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  const handleAccept = (e) => {
    console.log(form)
    // let img = document.querySelector(".container-fluid");
    //
    // html2canvas(img).then(
    //     canvas => {
    // //         document.body.appendChild(canvas);
    //
    //         let a = document.createElement('a');
    //         a.href = canvas.toDataURL("image/png").replace("image/png","image/octet-stream");
    //         a.download = 'surgeon Approve.png';
    //         a.click();
    //     }).catch(
    //         error=>{
    //             console.log(error);
    //         }
    // );
    e.preventDefault()
    // console.log(options);

    axios({
      method:'put',
      url: `https://${api_url}/api/case?case_id=${formData.slug}&update=accept&femur_correction=${absFem}&tibia_correction=${absTib}&vov=${var_or_val}`,
      data: form,
      headers: {
        'x-access-token' : token
      }
    }).then(res=> {
      if(res.data.notLoggedIn){
        setAuthenticated(false)
        setOpen(false)
        setAuthBody({role: 'visitor'})
        alert("You have been logged out due to inactivity. Please log in again.")
      }
      else if(res.data.message){
        alert(res.data.message)
      }else{
        // return <Redirect to="/" />
        window.location.replace(`/case/${formData.slug}`)

        // window.location.reload()
      }
    }).catch(
      err => {
        console.log(err)
      }
    );


  }

 //  nextOnClick(){
 //     const {id} = this.props;
 //     let choices = _.get(this.state,"choices");
 //
 //     api.patchCase(id,{
 //         state: choices[0],
 //         accept: true,
 //         femur_rotate_angle: this.get("configKneeOverview.images.femur.angleCorrectionDelta"),
 //         femur_rotate_degree: toDegree(this.get("configKneeOverview.images.femur.angleCorrectionDelta")),
 //         tibia_rotate_angle: this.get("configKneeOverview.images.tibia.angleCorrectionDelta"),
 //         tibia_rotate_degree: toDegree(this.get("configKneeOverview.images.tibia.angleCorrectionDelta"))
 //     }).then(()=>{
 //         html2canvas(document.querySelector(".container-fluid")).then(
 //             canvas => {
 //                 // document.body.appendChild(canvas)
 //                 canvas.toBlob(function (blob) {
 //
 //                     let form = new FormData();
 //                     form.append('file',blob,'surgeon_approve.png');
 //
 //                     api.postScreenShot(id,
 //                         form
 //                     ).catch(error=>{
 //                         console.log(error);
 //                     }).then(
 //                         response=> {
 //                             return window.location.href = "/cases/" + id;
 //                         });
 //                 });
 //             });
 //         }
 //     );
 // }

  const handleClose = () => {
    form = false
    setOpen(false)
  }


  if(accepted){
    return(
        <Redirect to={`/`} />
    )
  }



  return (
    <>
    <ThemeProvider theme={theme}>
      <button
        className="ml-2 btn btn-lg btn-success"
        onClick={handleClickOpen}
        disabled={hasRole(profile, ['write_admin', 'read_admin'])}
        >
        <span className="glyphicon glyphicon-thumbs-up"/>
        &nbsp; accept
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${formData.slug}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This is to confirm that you are approving the adjusted measurements made on the surgeon approval page.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          disableSpacing
          classes={{
            root: classes.root,
            spacing: classes.spacing
          }}
        >
          <button
            className="btn btn-success"
            onClick={handleAccept}
            disabled={!form}
            variant="contained"
            style={{fontSize: '2em'}}
          >
            <span className="glyphicon glyphicon-thumbs-up"/>
            &nbsp;{form ? "Accept" : "Loading"}
          </button>
          <button className="btn btn-danger" onClick={handleClose} style={{fontSize: '2em'}}>
            <span className="glyphicon glyphicon-remove"/>
            &nbsp;Cancel
          </button>
        </DialogActions>
      </Dialog>
      </ThemeProvider>
    </>
  );
}

export default CaseAccept;
