import React, {useMemo, useCallback, useState, useContext } from 'react';
import clsx from "clsx";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import { AuthorizeContext } from "../../RootContext";
import Slider from 'react-slick';
import SagittalFemurP5 from './SagittalFemurP5'
import SagittalFemurP5Modal from './SagittalFemurP5Modal';
import sagLeft  from "./imgs/FEMUR_SAGITAL_LEFT.jpg";
import sagRight  from "./imgs/FEMUR_SAGITAL_RIGHT.jpg";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%',
  },
  ripple: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 40,
    height: 40,
    marginBottom: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    backgroundColor: "#158ac7",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 40,
      height: 40,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""'
    },
    "input:hover ~ &": {
      backgroundColor: "#232ab3"
    }
  }
}));

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Button
      className="d-flex"
      color="primary"
      style={{
              postion: "absolute",
              bottom: "0px",
              left: "195px",
              fontSize: '1em'
             }}
      onClick={onClick}
    >Next</Button>
  );
}
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div style={{position:"relative"}}>
      <Button
        color="primary"
        style={{
          position: "absolute",
          top: "180px",
          left: "16px",
          fontSize: '1em'
        }}
        onClick={onClick}
      >Prev</Button>
    </div>
  );
}

const SagittalFemurImage = () => {
  const [open, setOpen] = React.useState(false);
  const context = useContext(ActiveFormDataContext);
  const { authBody } = useContext(AuthorizeContext);
  const [profile, setProfile] = useState(authBody)

  const { setState, formData } = context;
  const classes = useStyles();
  const [count, setCount] = useState({
    activeSlide: 0
  });
  const [sagFemY, setSagFemY] = useState(0);
  const [sagFemX, setSagFemX] = useState(0);
  let width, ratio;
  const handleClickOpen = () => {
    setState({sagFemOpen: true})
    setOpen(true);
  };

  const handleClose = () => {
    setState({sagFemOpen: false, sagFemSlide: 1})
    setOpen(false);
  };


  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }


  const onClicker = (e) => {
    e.preventDefault()
    const {name, value} = e.target
    setState( prevState => ({
        ...prevState,
        [name] : value
    }))
  }
  const settings = {
    initialSlide: 1,
    dots: true,
    infinite: true,
    fade: true,
    arrows: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: current => setState({ sagFemSlide: current })
  };

  if (formData.sagFemDimensions) {
   ratio = formData.sagFemDimensions.width/ formData.sagFemDimensions.height
   width = Math.trunc(124 * ratio)
  }

  const translateUp = () => {
    if(sagFemY >= -2){
      setSagFemY(sagFemY - 0.5)
      setState({sagFemVert: sagFemY - 0.5})
    }
  }

  const translateDown = () => {
    if(sagFemY <= 2){
      setSagFemY(sagFemY + 1)
      console.log(sagFemY)
      setState({sagFemVert: sagFemY + 0.5})
    }

  }

  const translateLeft = () =>{
    if(sagFemX >= -2){
      setSagFemX(sagFemX - 0.5)
      setState({sagFemHorz: sagFemX - 0.5})
    }
  }

  const translateRight = () => {
    if(sagFemX <= 2){
      setSagFemX(sagFemX + 0.5)
      setState({sagFemHorz: sagFemX + 0.5})
    }
  }

  const resetSag = () => {
    setSagFemX(0)
    setSagFemY(0)
    setState({sagFemHorz: 0, sagFemVert: 0})

  }


  return (
    <>

    <div
        style={{height:`180px !important`, width:`180px !important`, marginBottom: '2em',
        marginLeft: ".5em", marginRight: '.5em'}}
        className="sagFemur bg-dark"
        onClick={handleClickOpen}>
      <SagittalFemurP5 />
    </div>
    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
        fullWidth={true}
        maxWidth={'md'}
        style={{display: "block", paddingLeft:"17px"}}
      >
        <DialogTitle className="text-center" id="success-title">
          <span style={{ fontSize: '2.6em'}}>{"Sagittal Femur"}</span>
        </DialogTitle>
        <div>
          <div className="col-md-7 bg-dark p-1 mx-auto" style={{flexGrow: 1, color:"white"}}>
            <div className=" d-flex justify-content-center">
            <span className="d-flex justify-content-center pl-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>M E D I A L</span>
              <div className="d-flex flex-column w-100" >
                <div className="labelbox text-center">
                  <span >S U P E R I O R</span>
                </div>
                <div className="labelbox text-center">
                  <div className="mx-auto mt-4 mb-5">
                    <div className="sagFemurModal">
                      <SagittalFemurP5Modal />
                    </div>
                  </div>
                  <span>I N F E R I O R</span>
                </div>
              </div>
              <span className="d-flex justify-content-center pr-3" style={{writingMode: "vertical-rl", textOrientation: "upright"}}>L A T E R A L</span>
            </div>
          </div>
          <div className="col-md-4 ml-2">
            <div className="ml-4">
              {
                formData.slug.includes("L") ?

                <img src={sagLeft} alt="sag-Left" />

                :

                <img src={sagRight} alt="sag-Right" />

              }
              <RadioGroup>
                <div className="row-margin-bottom"
                     style={
                       formData.slug.includes("R") ?
                        {marginLeft: '2em'}
                       :

                       {marginLeft: '1.5em'}
                    }>
                  <Radio
                    value={"0"}
                    style={formData.slug.includes("R") ? {marginRight: '2em'}
                    :

                    null
                    }
                    className={classes.ripple}
                    color="default"
                    disableRipple
                    checked={formData.sagFemSlide === "0"}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                    onChange={(e) => setState({sagFemSlide: e.target.value})}
                  />
                  <Radio
                  value={"1"}
                  color="default"
                  disableRipple

                  className={classes.ripple}
                  checked={(formData.sagFemSlide === 1) || formData.sagFemSlide === undefined ||
                    (formData.sagFemSlide === "1")
                  }
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({sagFemSlide: e.target.value})}
                  />

                  <Radio
                  value={"2"}
                  color="default"
                  disableRipple
                  style={formData.slug.includes("R") ? null
                  :

                  {marginLeft: '.1em'}
                  }
                  className={classes.ripple}
                  checked={formData.sagFemSlide === "2"}
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  onChange={(e) => setState({sagFemSlide: e.target.value})}
                  />
                </div>
              </RadioGroup>
              {/*<Slider {...settings}
              >
                {formData.images && formData.images.femur.sag.urls.map(img => {
                  return(
                    <div key={img}>
                      <img src={img} style={{height: "175px", width: "85%", maxWidth: "350px", marginLeft: "auto", marginRight:"auto"}}/>
                    </div>
                  )
                })}
              </Slider>*/}
            </div>
            <div className="text-nowrap pt-3">
            </div>
          </div>
        </div>
        <DialogContent>
          <div className="col-md-6">
            <div className="row justify-content-center">
              <Button bsstyle="primary"
                      onClick={translateUp}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}

              >
                <span className="glyphicon glyphicon-chevron-up"/>
              </Button>
            </div>
            <div className="row justify-content-center">
              <Button bsstyle="primary"
                      onClick={translateLeft}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-left"/>
              </Button>
              <Button bsstyle="primary"
                      onClick={translateDown}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}

              >
                <span className="glyphicon glyphicon-chevron-down"/>
              </Button>
              <Button bsstyle="primary"
                      onClick={translateRight}
                      disabled={hasRole(profile, ['write_admin', 'read_admin'])}
              >
                <span className="glyphicon glyphicon-chevron-right"/>
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-around" style={{paddingLeft: '9em'}}>
            <Button onClick={handleClose} variant="contained" color="primary">Accept</Button>
            <Button onClick={handleClose} variant="contained" >Close</Button>
            <Button onClick={resetSag} variant="contained" color="secondary">Reset</Button>
          </div>
          </DialogContent>
      </Dialog>
    </div>
    </>
  );
}

export default SagittalFemurImage;
