import React, {useMemo, useCallback, useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { ActiveFormDataContext } from '../activeCaseForm/ActiveContext';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    margin: 'auto',
    width: '15%'
  },
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});


const TibiaImage = () => {
  const [open, setOpen] = React.useState(false);
  const context = useContext(ActiveFormDataContext);
  const { setState, formData } = context;
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <div style={{marginRight: '0', paddingRight: '0'}}>
    <Button className="mr-3" variant="contained" color="primary" onClick={handleClickOpen}
      style={{height: '7em', width: '.2em', fontSize: '1.6em'}}
      ><span style={{writingMode: "vertical-rl", textOrientation: "upright"}}>Tibia</span></Button>
    </div>
    <div>
      <Dialog
        open={open}
        aria-labelledby="success-title"
        aria-describedby="success-description"
        fullWidth={true}
        maxWidth={'md'}
        style={{display: "block", paddingLeft:"17px"}}
        onClose={handleClose}
      >
        <DialogTitle className="text-center border-bottom" id="success-title" onClose={handleClose}>
        <span style={{ fontSize: '2.8em'}}>{"Proximal Tibia Center"}</span>
        <IconButton aria-label="close" onClick={handleClose} style={{float: "right"}}>
          <CloseIcon />
        </IconButton>
        </DialogTitle>
        <DialogContent>
          {formData.screen_shots && <img src={formData.screen_shots.tibia} width="50%" style={{maxHeight: "85vh"}} className="center-block"/> }
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
}

export default TibiaImage;
