import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthorizeProvider } from './RootContext';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';


//staging/prod
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}



ReactDOM.render(
  <CookiesProvider>
    <AuthorizeProvider>
      <App />
    </AuthorizeProvider>
  </CookiesProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
