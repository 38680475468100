import React, { useState, useRef, useContext } from 'react';
import IdleTimer from 'react-idle-timer';
import { AuthorizeContext } from "../RootContext";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';





const useStyles = makeStyles({
  root: { justifyContent: 'space-evenly'},
  spacing: {justifyContent: 'space-evenly'},
  button:{
    fontSize: "1.5em",
    float: "right",
    margin: "10px 30px 10px 10px"
  }});

const IdleTimerContainer = () => {
  const classes = useStyles()

  let {setAuthBody, setAuthenticated} = useContext(AuthorizeContext);
  const [open, setOpen] = useState(false);
  const idleTimerRef = useRef(null)
  const sessionTimeOutRef = useRef(null)

  const onIdle = () => {
    console.log('user is idle')
    setOpen(true)
    sessionTimeOutRef.current = setTimeout(logout, 5000 * 2)
  }

  const stayActive = () => {
    setOpen(false)
    clearTimeout(sessionTimeOutRef.current)
  }
  const logout = () => {
    window.sessionStorage.clear()
    setOpen(false)
    setAuthBody({role: "visitor"})
    clearTimeout(sessionTimeOutRef.current)
    setAuthenticated(false)
    window.location.reload()
  }

  // const internalLogout = () => {
  //   window.localStorage.clear()
  //   window.location.reload()
  //   setAuthenticated(false)
  // }

  return(
    <>
    <Dialog
      open={open}
      aria-labelledby="success-title"
      aria-describedby="success-description"
      fullWidth={true}
      maxWidth={'md'}
      style={{display: "block", paddingLeft:"17px"}}
    >
      <DialogTitle className="text-center border-bottom" id="success-title">
      <span style={{ fontSize: '2.8em'}}>{"Are you still there?"}</span>
      </DialogTitle>
      <DialogContent>
        <h4 style={{textAlign: 'center'}}>You have been idle for a while!</h4>
        <p style={{textAlign: 'center'}}>You will be logged out soon.</p>
      </DialogContent>
      <DialogActions
        disableSpacing
        classes={{
          root: classes.root,
          spacing: classes.spacing
        }}
      >
        <button
          className="btn btn-success"
          onClick={stayActive}
          variant="contained"
          style={{fontSize: '2em'}}
        >
          Keep me signed in
        </button>
        <button className="btn btn-danger" onClick={logout} style={{fontSize: '2em'}}>
          Log me out
        </button>
      </DialogActions>
    </Dialog>
    <IdleTimer
      ref={idleTimerRef}
      timeout={1000 * 15 * 60}
      onIdle={onIdle}
    />
    </>
  )
}

export default IdleTimerContainer;
